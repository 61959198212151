import React, {Component} from 'react';
import * as StringUtil from "../../common/util/string-util";
import AppLoader from "../../common/component/AppLoader";
import {Helmet} from 'react-helmet';
import * as Constants from "../../common/constants";

export default class Blog extends Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      blog: {}
    }
  }

  componentDidMount() {
    const pageLink = StringUtil.getLastPathParam("/");
    const blog = this.getBlog(pageLink);
    if (!blog) {
      document.location.href = '/page-not-found';
    }
    this.setState({loading: false, blog: blog});
  }

  getBlog(pageLink) {
    const blog = this.props.blogs.filter(blog => blog.link === pageLink);
    if (!blog || blog.length < 1) {
      return null;
    } else {
      return blog[0];
    }
  }

  render() {
    if (this.state.loading) {
      return <AppLoader position={"fixed"}/>
    }

    const {blog} = this.state;
    return (
      <>
        <Helmet>
          <style type="text/css">{`
          .main_header {
            background-color: #000;
            position: static;
          }
          `}</style>
        </Helmet>
        <div className="container">
          <main className="static-content-wrapper">
            <img src={Constants.serverUrl + "file/get/" + blog.thumbnailId} width="100%" alt=""/>
            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
          </main>
        </div>
      </>
    );
  }
}