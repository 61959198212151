import React,  {Component} from 'react';
import * as StringUtil from "../../common/util/string-util";
import AppLoader from "../../common/component/AppLoader";
import { Helmet } from 'react-helmet';

export default class StaticPage extends Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      staticPage: {}
    }
  }

  componentDidMount() {
    const pageLink = StringUtil.getLastPathParam("/");
    const staticPage = this.getStaticPage(pageLink);
    if (!staticPage) {
      document.location.href = '/page-not-found';
    }
    this.setState({loading: false, staticPage: staticPage});
  }

  getStaticPage(pageLink) {
    const staticPage = this.props.staticPages.filter(staticPage => staticPage.link === pageLink);
    if (!staticPage || staticPage.length < 1) {
      return null;
    } else {
      return staticPage[0];
    }
  }

  render() {
    if (this.state.loading) {
      return <AppLoader position={"fixed"}/>
    }

    const {staticPage} = this.state;
    return (
      <>
        <Helmet>
          <style type="text/css">{`
          .main_header {
            background-color: #000;
            position: static;
          }
          `}</style>
        </Helmet>
        <div className="container">
          <main className="static-content-wrapper">
            <div dangerouslySetInnerHTML={{ __html: staticPage.html }} />
          </main>
        </div>
      </>
    );
  }
}