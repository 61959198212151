export function getLastPathParam(path) {
    return window.location.href.substring(window.location.href.lastIndexOf(path) + path.length);
}

export function getEllipseValue(text, length) {
    if (text.length < length) {
        return text;
    }
    return text.substring(0, length) + "...";
}

export function isEmpty(text) {
    return !text || text.length === 0;
}