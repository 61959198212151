import React from 'react'
import {Card} from 'react-bootstrap';

const CardComp = (props) => {
  return (
    <div className={"card-wrapper card-" + props.id + (props.active ? " active" : "")}>
        <Card className={`${props.hover ? "is-hover" : ""}`}>
            <div className="card-img">
                <span style={{ backgroundImage: `url(${props.image})`}}></span>
            </div>
            
            <div className="card-main">
                <Card.Body>
                    {props.title && <Card.Title>
                        <Card.Link className="text-dark" href={"/" + (props.slugPath || "property") + "/" + props.slug}>{props.title}</Card.Link>
                    </Card.Title>}

                    {props.price && <Card.Subtitle>
                        {props.price == 'hidden' ? "Price on request" : <>&#x20B9;{props.price} <span>Onwards</span></>}
                    </Card.Subtitle>}

                    <Card.Text>
                        {props.children}
                    </Card.Text>
                </Card.Body>

                {props.footer}
            </div>
        </Card>
    </div>
)
}

export default CardComp