import React, {Component} from "react";
import {Button} from 'react-bootstrap';

export default class RightContentHeader extends Component {
    render() {
        const {headerText, buttons} = this.props;

        return (
            <div className="admin-header-title">
                <h5>{headerText}</h5>
                {
                    buttons && buttons.map((button, index) => {
                        return <Button href={button.href} key={index}
                                       onClick={button.onClick}>{button.text}</Button>
                    })
                }
            </div>
        );
    }
}