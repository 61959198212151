import { Button } from "react-bootstrap";
import {Helmet} from "react-helmet";
import * as Constants from "../../common/constants";
import React from "react";

export default function AdminPageNotFound({organization}) {
    return (
        <div className="not-found-wrapper">
            <Helmet>
                <link rel="icon" type="image/png" href={Constants.serverUrl + "file/get/" + organization.favIconId} />
            </Helmet>
            <span>404</span>
            <p>Not Found</p>
            <div>
                <Button href={'/admin'} variant="dark">Take Me Back</Button>
            </div>
        </div>
    )
}
