import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import * as AreaUnit from "../../enums/area_unit";
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import InputError from "../components/InputError";
import FileUpload from "../components/FileUpload";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import * as FileUtil from "../../common/util/file-util";
import RightContent from "../components/RightContent";
import * as Constants from "../../common/constants";

const Apartment = ({apartment, apartmentTypes, setSavingStatus, propertyId}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        if (isAdd()) {
            Action.callPostApi("apartment/add", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("apartment/update", data, afterSave, onSaveError, null, true);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("floorPlan", form.floorPlan[0]);
        const apartmentData = {
            "id": isAdd() ? null : +apartment.id,
            "propertyId": propertyId,
            "description": form.description,
            "typeId": form.typeId,
            "price": form.price,
            areaUnit: form.areaUnit,
            reraCarpetArea: form.reraCarpetArea,
            carpetArea: form.carpetArea,
            bathrooms: form.bathrooms,
            balcony: form.balcony,
            parking: form.parking,
            "floorPlan": getFloorPlanDetails(form)
        };
        formData.append("data", JSON.stringify(apartmentData));
        return formData;
    }

    const getFloorPlanDetails = (form) => {
        if (form.floorPlan && form.floorPlan.length > 0) {
            return {
                id: form.floorPlanId,
                fileName: form.floorPlan[0].name,
                displayName: form.floorPlan[0].name,
                fileType: 3,
                size: form.floorPlan[0].size,
                mimetype: form.floorPlan[0].type,
                format: FileUtil.getFileFormat(form.floorPlan[0].name)
            }
        }
        if (form.floorPlanId > 0) {
            return getFieldValue("floorPlan");
        }
        if (form.floorPlanId == -1) {
            return {
                id: getFieldValue("floorPlan.id")
            }
        }
        return null;
    }

    const afterSave = () => {
        setSavingStatus(2);
        document.location.href = document.location.href;
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(apartment, name);
    }

    const onRemoveFloorPlan = () => {
        setValue("floorPlanId", -1);
    }

    const isAdd = () => {
        return !apartment || !apartment.id || apartment.id == 0;
    }

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <Card>
                <Card.Body>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            About the Apartment
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control as="textarea" name={"description"}
                                          className={getInvalidFieldClass('description')}
                                          aria-label="About the project"
                                          maxLength={1000}
                                          defaultValue={getFieldValue("description")}
                                          {...register('description')}/>
                            <InputError error={errors.description}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Apartment Type
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Select aria-label="Type" name="typeId"
                                         {...register('typeId')}
                                         defaultValue={getFieldValue("typeId")}>
                                {
                                    apartmentTypes && apartmentTypes.map((type) => {
                                        return <option key={type.id}
                                                       value={type.id}>{type.name + " " + Constants.APARTMENT_CONFIG_SUFFIX}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Price
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="price" type="number"
                                          defaultValue={getFieldValue("price")}
                                          min={0} {...register('price', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('price')}
                                          aria-label="Price"/>
                            <InputError error={errors.price}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Area Unit
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Select aria-label="Area Unit" name="areaUnit"
                                         {...register('areaUnit')}
                                         defaultValue={getFieldValue("areaUnit")}>
                                {
                                    AreaUnit.area_units.map((areaUnit) => {
                                        return <option key={areaUnit.value}
                                                       value={areaUnit.value}>{areaUnit.label}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Usable Carpet Area
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="carpetArea" type="number"
                                          defaultValue={getFieldValue("carpetArea")}
                                          min={0} {...register('carpetArea', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('carpetArea')}
                                          aria-label="Usable Carpet Area"/>
                            <InputError error={errors.carpetArea}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Rera Carpet Area
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="reraCarpetArea" type="number"
                                          defaultValue={getFieldValue("reraCarpetArea")}
                                          min={0} {...register('reraCarpetArea', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('reraCarpetArea')}
                                          aria-label="Rera Carpet Area"/>
                            <InputError error={errors.reraCarpetArea}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Bathrooms
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="bathrooms" type="number"
                                          defaultValue={getFieldValue("bathrooms")}
                                          min={0} {...register('bathrooms', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('bathrooms')}
                                          aria-label="Bathrooms"/>
                            <InputError error={errors.bathrooms}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Balcony
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="balcony" type="number"
                                          defaultValue={getFieldValue("balcony")}
                                          min={0} {...register('balcony', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('balcony')}
                                          aria-label="Balcony"/>
                            <InputError error={errors.balcony}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Car Parking
                        </Form.Label>
                        <Col sm="9" className="form-types">
                            <Form.Control name="parking" type="number"
                                          defaultValue={getFieldValue("parking")}
                                          min={0} {...register('parking', {required: 'Cannot be empty'})}
                                          className={getInvalidFieldClass('parking')}
                                          aria-label="Car Parking"/>
                            <InputError error={errors.parking}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="floorPlan" className="mb-3">
                        <Form.Label column sm="3">
                            Floor Plan
                        </Form.Label>
                        <Col sm="9" className="form-types has-file-upload">
                            <FileUpload id={"floorPlan"}
                                        value={getFieldValue("floorPlanId")}
                                        onRemoveClick={onRemoveFloorPlan}
                                        register={register}/>
                        </Col>
                    </Form.Group>
                    <div className="form-group action-btn-wrapper">
                        <Button type="submit">Save</Button>
                        <Button href={"/admin/apartments"} variant="dark">Cancel</Button>
                    </div>
                </Card.Body>
            </Card>
        </form>
    )
}

export default Apartment;