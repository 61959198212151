import React, {useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import * as AreaUnit from "../../enums/area_unit";
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import InputError from "../components/InputError";
import FileUpload from "../components/FileUpload";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import * as FileUtil from "../../common/util/file-util";
import RightContent from "../components/RightContent";
import PropertyDropdown from "../../common/component/PropertyDropdown";
import CountryDropdown from "../../common/component/CountryDropdown";

const State = ({state, setSavingStatus}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        if (isAdd()) {
            Action.callPostApi("states/add", data, afterSave, onSaveError);
        } else {
            Action.callPostApi("states/update", data, afterSave, onSaveError);
        }
    }

    const getData = (form) => {
        return {
            "id": isAdd() ? null : +state.id,
            "name": form.name,
            "code": form.code,
            "countryId": form.countryId.value
        };
    }

    const afterSave = () => {
        setSavingStatus(2);
        if (isAdd()) {
            document.location.href = '/admin/states';
        }
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(state, name);
    }

    const isAdd = () => {
        return !state || !state.id || state.id == 0;
    }

    const [selectedCountry, setSelectedCountry] = useState(getFieldValue("country.id") != ""
        ? {value: getFieldValue("country.id")} : null);

    const onChangeSelectedCountry = (selectedCountryOption) => {
        if (selectedCountryOption != selectedCountry) {
            setSelectedCountry(selectedCountryOption);
            setValue("countryId", selectedCountryOption);
        }
    }
    return (
        <>
            <RightContent headerText={(!isAdd() ? "Edit" : "Add") + " State"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                State Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Code
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="code" type="text" defaultValue={getFieldValue("code")}
                                                  className={getInvalidFieldClass('code')}
                                                  aria-label="Code"
                                                  {...register('code', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.code}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="countryId" className="mb-3">
                                <Form.Label column sm="3">
                                    Country
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <CountryDropdown className={getInvalidFieldClass('countryId')}
                                                     selectedCountry={selectedCountry}
                                                     control={control}
                                                     setSelectedCountry={onChangeSelectedCountry}/>
                                </Col>
                            </Form.Group>

                            <div className="form-group action-btn-wrapper">
                                <Button type="submit">Save</Button>
                                <Button href={"/admin/states"} variant="dark">Cancel</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </RightContent>
        </>
    )
}

export default State;