import React from 'react';
import { Card } from 'react-bootstrap';


const CardFooter = (props) => {
  return (
    <Card.Footer>
        {props.children}
    </Card.Footer>
  );
}

export default CardFooter;
