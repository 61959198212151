import React, {Component} from 'react';
import './app/styles/style';
import Home from "./app/pages/Home";
import StaticPage from "./app/pages/StaticPage";
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import Login from "./app/pages/Login";
import Signup from "./app/pages/Signup";
import * as Session from './app/util/session'
import * as Constants from './common/constants';
import 'material-icons/iconfont/material-icons.scss';
import PropertyDetails from './app/pages/PropertyDetails';
import SearchResult from './app/pages/SearchResult';
import * as Action from "./common/api-action";
import Footer from "./app/components/Footer";
import Header from "./app/components/Header";
import AdminHome from "./admin/pages/AdminHome";
import PageNotFound from "./app/pages/PageNotFound";
import AdminPageNotFound from "./admin/pages/AdminPageNotFound";
import AppLoader from "./common/component/AppLoader";
import $ from 'jquery';
import VerifyPhone from "./app/components/VerifyPhone";
import Blog from "./app/pages/Blog";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            cities: [],
            localities: [],
            faqs: [],
            organization: null,
            currentCity: Session.getCurrentCity(true),
            apartmentTypes: [],
            staticPages: [],
            topLocalities: [],
            blogs: [],
            reviews: [],
            canShowVerificationPopup: Session.canShowVerificationPopup(),
            showVerificationPopup: false
        }
    }

    componentDidMount() {
        if (!document.location.pathname.startsWith("/admin/") && !document.location.pathname.endsWith("/signup")
            && !document.location.pathname.endsWith("/login")) {
            this.setState({loading: true});
            this.populateHomeData();
            setTimeout(this.checkTime.bind(this), 3000);
        } else {
            this.populateAdminData();
        }
    }

    checkTime() {
        if (($('.phone-verification--modal') && $('.phone-verification--modal').length > 0)
            || $('.sidebar-form-wrapper').hasClass('active')) {
            return;
        }
        if (Session.canShowVerificationPopup()) {
            this.setState({canShowVerificationPopup: Session.canShowVerificationPopup()});
        } else if (!Session.isPhoneVerified()) {
            setTimeout(this.checkTime.bind(this), Constants.VERIFICATION_TIME);
        }
    }

    hideVerificationPopup() {
        Session.setCloseTime();
        this.setState({canShowVerificationPopup: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!document.location.pathname.startsWith("/admin/") && this.state.organization) {
            if (this.state.organization.gtmHeader && $('head').html().indexOf(this.state.organization.gtmHeader) < 0
                && this.state.organization.gtmHeader.length > 0) {
                $('head').prepend(this.state.organization.gtmHeader);
            }
            if (this.state.organization.gtmBody && $('body').html().indexOf(this.state.organization.gtmBody) < 0
                && this.state.organization.gtmBody.length > 0) {
                $('body').prepend(this.state.organization.gtmBody);
            }
        }
    }

    populateAdminData() {
        this.setState({loading: true});
        Action.callPostApi("home/admin", {}, this.onSuccessAdminApi.bind(this));
    }

    populateHomeData() {
        Action.callPostApi("home", {district: this.state.currentCity
                ? this.state.currentCity : Constants.defaultCity}, this.onSuccessHomeApi.bind(this));
    }

    onSuccessHomeApi(response) {
        this.setState({
            cities: response.popularCities,
            localities: response.localities,
            organization: response.organization,
            //properties: response.properties,
            faqs: response.faqs,
            reviews: response.reviews,
            featuredProperties: response.featuredProperties,
            apartmentTypes: response.apartmentTypes,
            staticPages: response.staticPages,
            topLocalities: response.topLocalities,
            blogs: response.blogs,
            loading: false
        });
        if (response.currentCity) {
            this.setState({currentCity: response.currentCity})
            Session.setCurrentCity(response.currentCity);
        } else {
            this.getBrowserLocation(response.popularCities);
        }
    }

    onSuccessAdminApi(response) {
        this.setState({
            apartmentTypes: response.apartmentTypes,
            organization: response.organization,
            loading: false
        });
    }

    onVerificationSuccess() {
        this.setState({canShowVerificationPopup: Session.canShowVerificationPopup()})
     }

    getCurrentCityIdFromCoordinates(cities, latitude, longitude) {
        if (!latitude || !longitude) {
            return Constants.defaultCity;
        }
        let closestCity = cities[0];
        let minLatDiff = Math.abs(closestCity.latitude - latitude);
        let minLongDiff = Math.abs(closestCity.longitude - longitude);
        for (let i = 1; i < cities.length; i++) {
            if (Math.abs(cities[i].latitude - latitude) < minLatDiff
                && Math.abs(cities[i].longitude - longitude) < minLongDiff) {
                closestCity = cities[i];
                minLatDiff = Math.abs(cities[i].latitude - latitude);
                minLongDiff = Math.abs(cities[i].longitude - longitude);
            }
        }
        return closestCity.id;
    }

    onUpdateCurrentCity(cityId) {
        this.updateCurrentCityTemp(cityId);
        if (Session.isValidUser()) {
            Action.callPostApi("user/updateCurrentCity", {cityId: cityId}, this.populateHomeData.bind(this));
        } else {
            setTimeout(this.populateHomeData.bind(this), 1);
        }
    }

    updateCurrentCityTemp(cityId) {
        this.setState({currentCity: cityId});
        Session.setCurrentCity(cityId);
    }

    async getBrowserLocation(cities) {
        const options = {
            enableHighAccuracy: true,
            timeout: Infinity,
            maximumAge: 0,
        };
        const self = this;
        if (navigator.geolocation) {
            navigator.permissions
                .query({name: "geolocation"})
                .then(function (result) {
                    if (result.state === "granted") {
                        navigator.geolocation.getCurrentPosition(self.success.bind(self, cities));
                    } else if (result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(self.success.bind(self, cities), self.errors.bind(self), options);
                    } else if (result.state === "denied") {
                        self.errors.bind(self);
                    }
                });
        }
    }

    success(cities, pos) {
        const coordinates = pos.coords;
        console.log(`Latitude : ${coordinates.latitude}`);
        console.log(`Longitude: ${coordinates.longitude}`);
        this.updateCurrentCityTemp(this.getCurrentCityIdFromCoordinates(cities,
            coordinates.latitude, coordinates.longitude));
        this.populateHomeData();
    }

    errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    canShowHeaderFooter() {
        return !window.location.pathname.match(/login/)
            && !window.location.pathname.match(/signup/)
            && !window.location.pathname.match(/admin/)
            && !window.location.pathname.match(/page-not-found/);
    }

    afterEntry = (data, isAdmin) => {
        Session.setLoggedInUser(data);
        if (isAdmin && data.userType !== 0) {
            document.location.href = '/admin/properties';
        } else {
            document.location.href = '/';
        }
    }

    render() {
        if (this.state.loading) {
            return <AppLoader position='fixed' />
        }
        return (
            <>
                {this.canShowHeaderFooter() && <Header cities={this.state.cities}
                                                       gtmHeader={this.state.organization.gtmHeader}
                                                       gtmBody={this.state.organization.gtmBody}
                                                       additionalScripts={this.state.organization.additionalScripts}
                                                       favIconId={this.state.organization.favIconId}
                                                       logoFileId={this.state.organization.logoFileId}
                                                       onUpdateCurrentCity={this.onUpdateCurrentCity.bind(this)}/>}
                <Router basename={"/"}>
                    <Routes>
                        <Route exact path="/" element={<Home apartmentTypes={this.state.apartmentTypes}
                                                             cities={this.state.cities}
                                                             localities={this.state.localities}
                                                             reviews={this.state.reviews}
                                                             blogs={this.state.blogs}
                                                             //properties={this.state.properties}
                                                             featuredProperties={this.state.featuredProperties}
                                                             organization={this.state.organization}/>}/>
                        <Route exact path="/property/:slug" element={<PropertyDetails apartmentTypes={this.state.apartmentTypes}
                                                                                      faqs={this.state.faqs}
                                                                                      organization={this.state.organization}/>}/>
                        <Route exact path="/searchresult" element={<SearchResult apartmentTypes={this.state.apartmentTypes}
                                                                                 organizationId={this.state.organization ? this.state.organization.id : 0}
                                                                                 organizationName={this.state.organization ? this.state.organization.name : ''}
                                                                                 localities={this.state.localities}
                                                                                 cities={this.state.cities}/>}/>
                        {/*<Route exact path="/signup" element={(Session.isValidUser()*/}
                            {/*? <Navigate to={"/"}/> : <Signup afterEntry={this.afterEntry} organization={this.state.organization}/>)}/>*/}
                        {/*<Route exact path="/login" element={(Session.isValidUser()*/}
                            {/*? <Navigate to={"/"}/> : <Login afterEntry={this.afterEntry} organization={this.state.organization}/>)}/>*/}
                        <Route exact path="/:link" element={<StaticPage staticPages={this.state.staticPages}/>}/>

                        <Route exact path="blog/:link" element={<Blog blogs={this.state.blogs}/>}/>

                        <Route exact path="/admin" element={(Session.isValidUser()
                            ? <Navigate to={"/admin/properties"}/> : <Login admin={true} organization={this.state.organization}
                                                                            afterEntry={this.afterEntry}/>)}/>

                        <Route exact path="/admin/signup" element={(Session.isValidUser()
                            ? <Navigate to={"/admin/properties"}/> : <Signup admin={true} organization={this.state.organization}
                                                                             afterEntry={this.afterEntry}/>)}/>

                        <Route exact path="/admin/users" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"users"}/>)}/>

                        <Route exact path="/admin/users/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"users"} mode={"add"}/>)}/>

                        <Route exact path="/admin/users/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"users"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/properties" element={(!Session.isValidManagerUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"property"}/>)}/>

                        <Route exact path="/admin/properties/add" element={(!Session.isValidManagerUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"property"} mode={"add"}/>)}/>

                        <Route exact path="/admin/properties/edit/:id" element={(!Session.isValidManagerUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"property"}
                                                                     apartmentTypes={this.state.apartmentTypes}
                                                                     mode={"edit"}/>)}/>

                        <Route exact path="/admin/developers" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"developer"}/>)}/>

                        <Route exact path="/admin/developers/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"developer"} mode={"add"}/>)}/>

                        <Route exact path="/admin/developers/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"developer"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/enquiry" element={(!Session.isValidManagerUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization}
                                                                     apartmentTypes={this.state.apartmentTypes}
                                                                     type={"leads"}/>)}/>

                        <Route exact path="/admin/enquiry/edit/:id" element={(!Session.isValidManagerUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization}
                                                                     apartmentTypes={this.state.apartmentTypes}
                                                                     type={"leads"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/organization" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"org"}/>)}/>

                        <Route exact path="/admin/staticpages" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"static"}/>)}/>

                        <Route exact path="/admin/specifications" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"specifications"}/>)}/>

                        <Route exact path="/admin/amenities" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"amenities"}/>)}/>

                        <Route exact path="/admin/reviews" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"reviews"}/>)}/>

                        <Route exact path="/admin/reviews/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"reviews"} mode={"add"}/>)}/>

                        <Route exact path="/admin/reviews/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"reviews"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/blogs" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"blogs"}/>)}/>

                        <Route exact path="/admin/blogs/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"blogs"} mode={"add"}/>)}/>

                        <Route exact path="/admin/blogs/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"blogs"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/apartmentTypes" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"apartmentTypes"}/>)}/>

                        <Route exact path="/admin/faqs" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"faq"}/>)}/>

                        <Route exact path="/admin/countries" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"countries"}/>)}/>

                        <Route exact path="/admin/localities" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"localities"}/>)}/>

                        <Route exact path="/admin/localities/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"localities"} mode={"add"}/>)}/>

                        <Route exact path="/admin/localities/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"localities"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/districts" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"districts"}/>)}/>

                        <Route exact path="/admin/districts/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"districts"} mode={"add"}/>)}/>

                        <Route exact path="/admin/districts/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"districts"} mode={"edit"}/>)}/>

                        <Route exact path="/admin/states" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"states"}/>)}/>

                        <Route exact path="/admin/states/add" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"states"} mode={"add"}/>)}/>

                        <Route exact path="/admin/states/edit/:id" element={(!Session.isValidAdminUser()
                            ? <Navigate to={"/admin"}/> : <AdminHome organization={this.state.organization} type={"states"} mode={"edit"}/>)}/>

                        <Route path='/admin/*' element={<AdminPageNotFound organization={this.state.organization}/>} />

                        <Route path='/admin/*' element={<Navigate to="/admin/page-not-found" />} />
                        <Route path='/admin/page-not-found' element={<AdminPageNotFound organization={this.state.organization}/>} />

                        <Route path='*' element={<Navigate to="/page-not-found" />} />
                        <Route path='/page-not-found' element={<PageNotFound/>} />
                    </Routes>
                </Router>
                {this.canShowHeaderFooter() && this.state.canShowVerificationPopup &&
                <VerifyPhone onVerificationSuccess={this.onVerificationSuccess.bind(this)}
                             organization={this.state.organization}
                             type={3}
                             onHide={this.hideVerificationPopup.bind(this)}
                             show={this.state.canShowVerificationPopup}/>}
                {this.canShowHeaderFooter() && <Footer organization={this.state.organization}
                                                       topLocalities={this.state.topLocalities}
                                                       staticPages={this.state.staticPages}/>}
            </>
        );
    }
}