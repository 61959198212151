import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "./FormReactSelect";

export default class LocalityDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localities: null,
            title: 'Loading localities...'
        }
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedDistrict !== prevProps.selectedDistrict) {
            this.setState({title: 'Loading localities...'});
            this.loadOptions();
        }
    }

    loadOptions() {
        if (this.props.selectedDistrict) {
            Action.callPostApi("localities/get/" + this.props.selectedDistrict.value, {}, this.populateLocalityData.bind(this));
        } else {
            this.setState({title: 'Please select a city', localities: null});
            this.props.setSelectedLocality(null);
        }
    }

    populateLocalityData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedLocality(null);
        } else {
            this.setState({title: 'Locality', localities: this.getLocalityOptions(response.localities)});
            this.props.setSelectedLocality(this.getSelectedLocalityOption(response.localities));
        }
    }

    getLocalityOptions(localities) {
        return localities && localities.map((locality) => {
            return {
                value: locality.id,
                label: locality.name
            }
        })
    }

    getSelectedLocalityOption(localities) {
        for (let i = 0; localities && i < localities.length; i++) {
            if (this.props.selectedLocality && localities[i].id === this.props.selectedLocality.value) {
                return {
                    value: localities[i].id,
                    label: localities[i].name
                }
            }
        }
        return null;
    }

    render() {
        const {setSelectedLocality, className, control} = this.props;
        return (
            <FormReactSelect id="localityId"
                             name="localityId"
                             title={this.state.title}
                             className={className}
                             valueJSON={this.props.selectedLocality}
                             onChange={setSelectedLocality}
                             control={control}
                             options={this.state.localities ? this.state.localities : []}/>
        )
    }
}