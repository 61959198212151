import React from 'react';
import '../styles/search.scss'
import InputRange from 'react-input-range';
import {Button} from 'react-bootstrap';
import {getFormattedPrice} from "../util/currency-util";
import LocationDropdown from "./LocationDropdown";
import PropertyTypeDropdown from "./PropertyTypeDropdown";
import $ from 'jquery';
import * as Constants from "../../common/constants";
import LocalityDropdown from "./LocalityDropdown";

const SearchCompInner = ({cities, apartmentTypes, selectedCity, localities}) => {
     
    const [price, setPrice] = React.useState({ min: Constants.MIN_PRICE, max: Constants.MAX_PRICE });

    function appendDropdownParam(searchParams, selectedValues, urlParameter, isFirst) {
        for (let i = 0; selectedValues && i < selectedValues.length; i++) {
            if (i == 0) {
                searchParams += !isFirst ? "&" : "";
                searchParams += urlParameter + "=";
                searchParams += selectedValues[i].value;
            } else {
                searchParams += "," + selectedValues[i].value;
            }
        }
        return searchParams;
    }

    React.useEffect(() => {
        setSelectedCities(getSelectedCity());
    }, [selectedCity]);

    function appendPriceRange(searchParams) {
        if (searchParams.length) {
            searchParams += "&";
        }
        searchParams += "min=" + price.min + "&max=" + price.max;
        return searchParams;
    }


    const getSearchParams = () => {
        let searchParams = "";
        searchParams = appendDropdownParam(searchParams, selectedCities, 'cities', true);
        searchParams = appendDropdownParam(searchParams, selectedLocalities, 'localities');
        searchParams = appendDropdownParam(searchParams, propertyTypes, 'propertyType');
        searchParams = appendPriceRange(searchParams);
        return searchParams;
    }

    const onSearch = () => {
        document.location.href = "/searchresult?" + getSearchParams()
    }

    const getSelectedCity = () => {
        for (let i = 0; cities && i < cities.length; i++) {
            const city = cities[i];
            if ('' + city.id === selectedCity) {
                return [{value: city.id, label: city.name}]
            }
        }
    }

    function shwoModalSearch(){
        $('.search-comp-wrapper').toggleClass('active');
    }

    const [selectedCities, setSelectedCities] = React.useState([getSelectedCity()]);
    const [selectedLocalities, setSelectedLocalities] = React.useState([]);
    const [propertyTypes, setPropertyTypes] = React.useState();

    return (
        <div className="search-comp-wrapper">

            <div className="search-filed-wrapper">
                <Button size="sm" variant="link" className="btn-searchClose" onClick={shwoModalSearch}>
                    <i className="material-icons">close</i>
                </Button>

                <LocationDropdown cities={cities} selectedCities={selectedCities}
                                  onUpdateSelectedCities={setSelectedCities}/>
                <LocalityDropdown localities={localities} selectedLocalities={selectedLocalities}
                                  selectedCities={selectedCities}
                                  onUpdateSelectedLocalities={setSelectedLocalities}/>
                <PropertyTypeDropdown apartmentTypes={apartmentTypes}
                                      propertyTypes={propertyTypes} setPropertyTypes={setPropertyTypes}/>

                <div className="search-col has-slider">
                    <i className="material-icons-two-tone">currency_rupee</i>
                    <InputRange
                        step={Constants.PRICE_STEP}
                        draggableTrack={false}
                        allowSameValues={false}
                        maxValue={Constants.MAX_PRICE}
                        minValue={Constants.MIN_PRICE}
                        value={price}
                        onChange={setPrice}
                        onChangeComplete={args => console.log(args)}
                    />
                    <div className="slider-value">
                        <span>&#x20B9; {getFormattedPrice(price.min)}</span>
                        <span>&#x20B9; {getFormattedPrice(price.max) + (price.max >= Constants.MAX_PRICE ? "+" : "")}</span>
                    </div>
                </div>
                <div className="search-col">
                    <Button onClick={onSearch}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                        <span>Search</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SearchCompInner;
