import React, {Component} from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Form, Table} from 'react-bootstrap';

import AppLoader from "../../common/component/AppLoader";
import * as Action from "../../common/api-action";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class Countries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            countries: [],
            errors: {}
        }
    }

    componentDidMount() {
        Action.callPostApi("countries", {}, this.populateCountriesData.bind(this));
    }

    populateCountriesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                countries: response.countries
            });
        }
    }

    onSave(index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.countries[index];
        Action.callPostApi("countries/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onDelete(index) {
        if (window.confirm("Are you sure you want to delete this Country? All the States, Districts and Localities in this country will also be deleted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.countries[index];
            Action.callPostApi("countries/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    afterSave() {
        this.props.setSavingStatus(2);
    }

    afterDelete(index) {
        document.location.href = "/admin/countries";
    }

    setError(error) {
        let errors = this.state.errors;
        errors[error.fieldName] = error.message;
        this.setState({
            errors: errors
        })
    }

    resetErrors() {
        this.setState({errors: {}});
    }

    getError(fieldName) {
        return this.state.errors[fieldName];
    }

    hasError(fieldName) {
        return this.getError(fieldName) && this.getError(fieldName).length > 0;
    }

    onAddNewCountryClick() {
        const countriesUpdated = [...this.state.countries];
        countriesUpdated.push(this.getNewCountryJSON());
        this.setState({countries: countriesUpdated});
    }

    getNewCountryJSON() {
        return {
            id: 0,
            name: "Country name"
        }
    }

    getInvalidFieldClass(field) {
        return this.hasError(field) ? ' is-invalid' : '';
    }

    onChangeField = (index, id, value) => {
        const countriesUpdated = [...this.state.countries];
        countriesUpdated[index][id] = value;
        this.setState({countries: countriesUpdated});
    }

    render() {
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }

        if (this.state.loading) {
            return <AppLoader position={"fixed"}/>
        }

        return (
            <RightContent>
                <div className="admin-header-title">
                    <h5>Countries</h5>
                    <Button variant="primary" onClick={this.onAddNewCountryClick.bind(this)}>
                        <span>Add Country</span>
                    </Button>
                </div>


                <Card>
                    <Card.Body>

                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Code</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {!this.state.countries || this.state.countries.length == 0 &&
                            <tr><td colSpan={6}>No Countries found.</td></tr>}
                            {this.state.countries.map((country, index) => (
                                <tr key={index}>
                                    <td width="47%">
                                        <Form.Control type="hidden" defaultValue={country.id}
                                                      id={"id_" + index}/>
                                        <Form.Control type="text" defaultValue={country.name}
                                                      className={this.getInvalidFieldClass('name')}
                                                      onBlur={(e) => this.onChangeField(index, "name", e.currentTarget.value)}
                                                      aria-label="Name"
                                                      id={"name_" + index}/>
                                    </td>
                                    <td width="47%">
                                        <Form.Control type="text" defaultValue={country.code}
                                                      className={this.getInvalidFieldClass('code')}
                                                      onBlur={(e) => this.onChangeField(index, "code", e.currentTarget.value)}
                                                      aria-label="Code"
                                                      id={"code_" + index}/>
                                    </td>
                                    <td>
                                        <div className="action-btn-wrapper">
                                            <Button size="sm" variant="primary" onClick={this.onSave.bind(this, index)}>Save</Button>
                                            <Button size="sm" variant="dark" onClick={this.onDelete.bind(this, index)}>Delete</Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        );
    }
}