import React, {Component} from "react";
import {Col, Dropdown, Row, Table, Card, Form} from 'react-bootstrap';
import RightContentHeader from "./RightContentHeader";
import RightContentFooter from "./RightContentFooter";

export default class RightContent extends Component {
    render() {
        const {headerText, headerButtons, children} = this.props;

        return (
            <main className="admin-main-container">
                <RightContentHeader headerText={headerText} buttons={headerButtons}/>
                {children}
                <RightContentFooter companyName={"Bombay Broking"}/>
            </main>
        );
    }
}