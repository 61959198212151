import React from 'react'
import Slider from "react-slick";
import CardComp from './CardComp';
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import '../styles/carousel.scss';
import SectionTitle from './SectionTitle';
import ContainerMain from './ContainerMain';
import * as StringUtil from "../../common/util/string-util";
import * as Constants from "../../common/constants";

const Blogs = ({blogs}) => {
    const slidesToShow = blogs.length > 2 ? 3 : blogs.length
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slidesToShow,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <ContainerMain className="home-carousel has-blog" bgColor={true}>
                <SectionTitle text="Blogs"/>
                <div className="inner-wrapper">
                    <Slider {...settings}>
                        {
                            blogs && blogs.map((blog, index) => {
                                return (
                                    <CardComp
                                        key={index}
                                        id={blog.id}
                                        title={blog.title}
                                        image={Constants.serverUrl + "file/get/" + blog.thumbnailId}
                                        slugPath={"blog"}
                                        slug={blog.link}>
                                    </CardComp>
                                )
                            })
                        }
                    </Slider>
                </div>
            </ContainerMain>
        </>
    )
}

export default Blogs