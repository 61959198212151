import React, {Component} from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Form, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import InputError from "../components/InputError";

export default class Specifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            amenityTypes: [],
            errors: {}
        }
    }

    componentDidMount() {
        Action.callPostApi("amenity-type", {}, this.populateAmenityTypeData.bind(this));
    }

    populateAmenityTypeData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                amenityTypes: response.amenityTypes
            });
        }
    }

    onSave(index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.amenityTypes[index];
        Action.callPostApi("amenity-type/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onSaveAmenity(amenityTypeIndex, index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.amenityTypes[amenityTypeIndex].amenities[index];
        Action.callPostApi("amenities/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onDelete(index) {
        if (window.confirm("Are you sure you want to delete this Amenity Type? This action cannot be reverted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.amenityTypes[index];
            Action.callPostApi("amenity-type/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    onDeleteAmenity(amenityTypeIndex, index) {
        if (window.confirm("Are you sure you want to delete this Specification? This action cannot be reverted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.amenityTypes[amenityTypeIndex].amenities[index];
            Action.callPostApi("amenities/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    afterSave() {
        this.props.setSavingStatus(2);
    }

    afterDelete() {
        document.location.href = "/admin/specifications";
    }

    setError(error) {
        let errors = this.state.errors;
        errors[error.fieldName] = error.message;
        this.setState({
            errors: errors
        })
    }

    resetErrors() {
        this.setState({errors: {}});
    }

    getError(fieldName) {
        return this.state.errors[fieldName];
    }

    hasError(fieldName) {
        return this.getError(fieldName) && this.getError(fieldName).length > 0;
    }

    onAddNewAmenityTypeClick() {
        let newAmenityType = this.getNewAmenityTypeJSON();
        Action.callPostApi("amenity-type/save", newAmenityType,
            this.afterAddNewAmenity.bind(this), this.setError.bind(this));
    }

    afterAddNewAmenity(data) {
        const amenityTypesUpdated = [...this.state.amenityTypes];
        amenityTypesUpdated.push(data.amenityType);
        this.setState({amenityTypes: amenityTypesUpdated});
    }

    getNewAmenityTypeJSON() {
        return {
            id: 0,
            name: "New Amenity Type",
            amenities: []
        }
    }

    onAddNewSpecificationClick(amenityTypeIndex) {
        const amenityTypesUpdated = [...this.state.amenityTypes];
        amenityTypesUpdated[amenityTypeIndex].amenities.push(this.getNewSpecificationJSON(amenityTypesUpdated[amenityTypeIndex].id));
        this.setState({amenityTypes: amenityTypesUpdated});
    }

    getNewSpecificationJSON(type) {
        return {
            id: 0,
            name: "New Specification",
            type: type
        }
    }

    getInvalidFieldClass(field) {
        return this.hasError(field) ? ' is-invalid' : '';
    }

    onChangeField = (index, id, value) => {
        const amenityTypesUpdated = [...this.state.amenityTypes];
        amenityTypesUpdated[index][id] = value;
        this.setState({amenityTypes: amenityTypesUpdated});
        setTimeout(() => {
            this.onSave(index)
        }, 10);
    }

    onChangeAmenityField = (amenityTypeIndex, index, id, value) => {
        const amenityTypesUpdated = [...this.state.amenityTypes];
        amenityTypesUpdated[amenityTypeIndex].amenities[index][id] = value;
        this.setState({amenityTypes: amenityTypesUpdated});
        setTimeout(() => {
            this.onSaveAmenity(amenityTypeIndex, index)
        }, 10);
    }

    render() {
        return (
            <RightContent>
                <div className="admin-header-title">
                    <h5>Amenity Types</h5>
                    <Button variant="primary" onClick={this.onAddNewAmenityTypeClick.bind(this)}>
                        <span>Add Amenity Type</span>
                    </Button>
                </div>

                {this.state.amenityTypes.map((amenityType, amenityTypeIndex) => (
                    <Card className="has-ckeditor" key={amenityTypeIndex}>
                        <Card.Body>

                            <Card.Title>
                                <Form.Control type="text" defaultValue={amenityType.name}
                                              className={this.getInvalidFieldClass('name' + amenityTypeIndex)}
                                              onBlur={(e) => this.onChangeField(amenityTypeIndex, "name", e.currentTarget.value)}
                                              aria-label="Name"
                                              id={"name_" + amenityTypeIndex}/>
                            </Card.Title>
                            <Table hover>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th><Button size="sm" variant="primary" style={{float: 'right'}}
                                                onClick={this.onAddNewSpecificationClick.bind(this, amenityTypeIndex)}>Add New Specification</Button>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {amenityType.amenities && amenityType.amenities.length
                                    ? amenityType.amenities.map((amenity, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Form.Control type="text" defaultValue={amenity.name}
                                                              className={this.getInvalidFieldClass('name' + index)}
                                                              onBlur={(e) => this.onChangeAmenityField(amenityTypeIndex, index, "name", e.currentTarget.value)}
                                                              aria-label="Name"
                                                              id={"name_" + index}/>
                                                <InputError error={this.state.errors["name_" + index]}/>
                                            </td>
                                            <td width="180px">
                                                <div className="action-btn-wrapper">
                                                    <Button size="sm" variant="secondary"
                                                            onClick={this.onDeleteAmenity.bind(this, amenityTypeIndex, index)}>Delete</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <tr><td colSpan={2}>No specifications present</td></tr>
                                }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <div className="action-btn-wrapper">
                                <Button size="sm" variant="secondary" onClick={this.onDelete.bind(this, amenityTypeIndex)}>Delete</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                ))}
            </RightContent>
        );
    }
}