import React, {useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import InputError from "../components/InputError";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import RightContent from "../components/RightContent";
import DistrictDropdown from "../../common/component/DistrictDropdown";
import $ from "jquery";

const Locality = ({locality, setSavingStatus}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        if (isAdd()) {
            Action.callPostApi("localities/add", data, afterSave, onSaveError);
        } else {
            Action.callPostApi("localities/update", data, afterSave, onSaveError);
        }
    }

    const getData = (form) => {
        return {
            "id": isAdd() ? null : +locality.id,
            "name": form.name,
            "code": form.code,
            "isDefault": $('[name=isDefault]').is(":checked"),
            "districtId": form.districtId.value,
            "pincode": form.pincode
        };
    }

    const afterSave = () => {
        setSavingStatus(2);
        if (isAdd()) {
            document.location.href = '/admin/localities';
        }
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(locality, name);
    }

    const isAdd = () => {
        return !locality || !locality.id || locality.id == 0;
    }

    const [selectedDistrict, setSelectedDistrict] = useState(getFieldValue("district.id") != ""
        ? {value: getFieldValue("district.id")} : null);

    const onChangeSelectedDistrict = (selectedDistrictOption) => {
        if (selectedDistrictOption != selectedDistrict) {
            setSelectedDistrict(selectedDistrictOption);
            setValue("districtId", selectedDistrictOption);
        }
    }
    return (
        <>
            <RightContent headerText={(!isAdd() ? "Edit" : "Add") + " Locality"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Locality Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Code
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="code" type="text" defaultValue={getFieldValue("code")}
                                                  className={getInvalidFieldClass('code')}
                                                  aria-label="Code"
                                                  {...register('code', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.code}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Pincode
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="pincode" type="text" defaultValue={getFieldValue("pincode")}
                                                  className={getInvalidFieldClass('pincode')}
                                                  aria-label="Pincode"
                                                  {...register('pincode', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.pincode}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="districtId" className="mb-3">
                                <Form.Label column sm="3">
                                    District
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <DistrictDropdown className={getInvalidFieldClass('districtId')}
                                                      selectedDistrict={selectedDistrict}
                                                      selectedState={{value: -1}}
                                                      control={control}
                                                      setSelectedDistrict={onChangeSelectedDistrict}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Premium
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Check name="isDefault" type="switch"
                                                aria-label="Premium"
                                                defaultChecked={getFieldValue("isDefault")}/>
                                </Col>
                            </Form.Group>
                            <div className="form-group action-btn-wrapper">
                                <Button type="submit">Save</Button>
                                <Button href={"/admin/localities"} variant="dark">Cancel</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </RightContent>
        </>
    )
}

export default Locality;