export function getLabel(array, value) {
    if (array.length) {
        const filteredItems = array.filter(item => item.value === value);
        return filteredItems && filteredItems.length > 0 ? filteredItems[0].label : "";
    }
    return "";
}

export function getMinimum(array, key) {
    if (array && array.length) {
        return Math.min(...array.map(item => item[key]));
    }
    return "XXXXX";
}

export function getMaximum(array, key) {
    if (array && array.length) {
        return Math.max(...array.map(item => item[key]));
    }
    return "XXXXX";
}

export function getCommaSeparatedValue(array, key, list) {
    let uniqueArray = getUniqueItemsByProperties(array, key);
    if (uniqueArray && uniqueArray.length) {
        return uniqueArray.map((item, index) => {
            return (index !== 0 ? (index === array.length - 1 ? " & " : ", ") : "") + (!list ? item[key] : list.filter(dbItem => dbItem.id === item[key])[0].name);
        })
    }
    return "";
}

export function getFieldValueForId(value, field, list) {
    if (list && list.length) {
        const item = list.filter((item, index) => {
            return item.id === value;
        });
        if (item && item.length) {
            return item[0][field];
        }
    }
    return "";
}


const getUniqueItemsByProperties = (items, propName) =>
    items.filter((item, index, array) =>
        index === array.findIndex(foundItem => foundItem[propName] === item[propName])
    );

export function sort(array, key) {
    return array.sort(function (a, b) {
            return a[key] - b[key];
        }
    );
}