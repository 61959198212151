import React, {Component} from 'react';
import FeaturedProperties from '../components/FeaturedProperties';
import HomeBanner from '../components/HomeBanner';
import Testimonials from '../components/Testimonials';
import Blogs from "../components/Blogs";

export default class Home extends Component {
    render() {
        return (
            <>
                <HomeBanner apartmentTypes={this.props.apartmentTypes} localities={this.props.localities}
                            organization={this.props.organization} cities={this.props.cities}/>
                {this.props.featuredProperties.length > 0 &&
                <FeaturedProperties apartmentTypes={this.props.apartmentTypes}
                                    properties={this.props.featuredProperties}/>}
                {this.props.blogs.length > 0 &&
                <Blogs blogs={this.props.blogs}/>}
                {/*{this.props.properties.length > 0 &&*/}
                {/*<OtherProperties properties={this.props.properties}/>}*/}
                {
                    this.props.featuredProperties.length === 0 &&
                        <div className="noservice-message">
                            <span>
                                <i className="material-icons-outlined">wrong_location</i>
                                We are currently not serving at the desired location.
                            </span>
                        </div>
                }
                {
                    this.props.reviews && this.props.reviews.length > 0 && <Testimonials reviews={this.props.reviews}/>
                }
            </>
        )
    }
}
