import React, {Component} from 'react';
import {Card, Dropdown, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import RightContent from "../components/RightContent";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import Locality from "./Locality";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class LocalityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            localities: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("localities", {}, this.populateLocalitiesData.bind(this));
    }

    populateLocalitiesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                localities: response.localities
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/localities/add",
            icon: "",
            text: "Add Locality"
        }];
    }

    getLocality(id) {
        if (!id || isNaN(id) || !this.state.localities) {
            return null;
        }
        for (let i = 0; i < this.state.localities.length; i++) {
            if (this.state.localities[i].id == id) {
                return this.state.localities[i];
            }
        }
        return null;
    }

    onDeleteLocalityClick(id) {
        if (window.confirm("Are you sure you want to delete this Locality?")) {
            Action.callPostApi("localities/delete",
                {id: id}, this.afterDeleteLocality.bind(this, id));
        }
    }

    afterDeleteLocality(id) {
        const localityListAfterDeletion = this.state.localities.filter(function(locality) { return locality.id !== id; });
        this.setState({localities: localityListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <Locality mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const locality = this.getLocality(StringUtil.getLastPathParam("/edit/"));
            if (locality) {
                return <Locality mode={mode}
                                 locality={locality} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Localities"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="30%">Name</th>
                                <th width="30%">Code</th>
                                <th width="30%">District</th>
                                <th width="30%">Pincode</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.localities || this.state.localities.length == 0 &&
                            <tr><td colSpan={6}>No Localities found.</td></tr>}
                            {this.state.localities && this.state.localities.map((locality) => {
                                return (
                                    <tr key={locality.id}>
                                        <td>{locality.name}</td>
                                        <td>{locality.code}</td>
                                        <td>{locality.district ? locality.district.name : ""}</td>
                                        <td>{locality.pincode}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/localities/edit/" + locality.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteLocalityClick.bind(this, locality.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}