import * as Action from "../../common/api-action";
import {useForm} from 'react-hook-form';
import InputError from "../components/InputError";
import SocialLogin from "../components/SocialLogin";
import * as Constants from "../../common/constants";
import {Helmet} from "react-helmet";
import React from "react";

const Login = (props) => {
    const {register, handleSubmit, formState: {errors}, setError} = useForm();
    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }
    const afterLogin = (data) => {
        props.afterEntry(data, props.admin);
    }
    const onLoginError = (data) => {
        setError(data.field, {type: 'custom', message: data.message});
    }
    const onLogin = (form) => {
        const data = {
            "email": form.email,
            "password": form.password
        }
        Action.callPostApi("auth/signIn", data, afterLogin, onLoginError);
    }
    return (
        <div className="login_reg_outer_wrapper">
            {props.organization && <Helmet>
                <link rel="icon" type="image/png" href={Constants.serverUrl + "file/get/" + props.organization.favIconId} />
            </Helmet>}
            <div className="login_reg_inner_wrapper">
                <a className="mb-5" href="/">
                    {props.organization && <img src={Constants.serverUrl + "file/get/" + props.organization.logoFileId} alt="logo"/>}
                </a>

                <form onSubmit={handleSubmit(onLogin)}>
                    <div className="form-group mb-4">
                        <input type="email" name="email"
                               className={"form-control" + getInvalidFieldClass('email')}
                               placeholder="Email Address" aria-label="Email Address"
                               {...register('email', {required: 'Email cannot be empty'})}/>
                        <InputError error={errors.email}/>
                    </div>

                    <div className="form-group mb-4">
                        <input type="password" name="password" autoComplete="off"
                               className={"form-control" + getInvalidFieldClass('password')}
                               placeholder="Password" aria-label="Password"
                               {...register('password', {required: 'Password cannot be empty'})}/>
                        <InputError error={errors.password}/>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary w-100">Login</button>
                    </div>

                    <div className="forgot_psswd form-group mt-2">
                        <a href="forgot-password">Forgot Password</a>
                    </div>

                    <div className="form_or mb-4 mt-4"></div>

                    <SocialLogin afterLogin={afterLogin} onError={onLoginError}/>

                    <p className="text-center mb-0">Need an account?
                        <a className="link-primary" href={(props.admin ? "/admin" : "") + "/signup"}> SIGN
                        UP</a></p>
                    <p className="text-center mb-0"><a className="link-secondary" href="/">Go back to home</a></p>
                </form>
            </div>
        </div>
    )
}

export default Login