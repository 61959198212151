import moment from "moment";

export function getFormattedDate(date, format) {
    if (!date || date.length <= 0 || !format || format.length <= 0) {
        return date;
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return moment(date).format(format);
}