import React, {Component} from 'react';
import {Card, Dropdown, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import RightContent from "../components/RightContent";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import Developer from "./Developer";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class DeveloperList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            developers: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("developer", {}, this.populateDevelopersData.bind(this));
    }

    populateDevelopersData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                developers: response.developers
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/developers/add",
            icon: "",
            text: "Add Developer"
        }];
    }

    getDeveloper(id) {
        if (!id || isNaN(id) || !this.state.developers) {
            return null;
        }
        for (let i = 0; i < this.state.developers.length; i++) {
            if (this.state.developers[i].id == id) {
                return this.state.developers[i];
            }
        }
        return null;
    }

    onDeleteDeveloperClick(id) {
        if (window.confirm("Are you sure you want to delete this Developer?")) {
            Action.callPostApi("developer/delete",
                {id: id}, this.afterDeleteDeveloper.bind(this, id));
        }
    }

    afterDeleteDeveloper(id) {
        const developerListAfterDeletion = this.state.developers.filter(function(developer) { return developer.id !== id; });
        this.setState({developers: developerListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <Developer mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const developer = this.getDeveloper(StringUtil.getLastPathParam("/edit/"));
            if (developer) {
                return <Developer mode={mode}
                                  developer={developer} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Developers"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="25%">Name</th>
                                <th width="60%">About Developer</th>
                                <th width="15%">Created On</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.developers || this.state.developers.length == 0 &&
                            <tr><td colSpan={6}>No Developers found.</td></tr>}
                            {this.state.developers && this.state.developers.map((developer) => {
                                return (
                                    <tr key={developer.id}>
                                        <td>{developer.name}</td>
                                        <td>{developer.description}</td>
                                        <td>{developer.createdOn}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/developers/edit/" + developer.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteDeveloperClick.bind(this, developer.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}