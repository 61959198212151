import { Button } from "react-bootstrap";

export default function NoAccess() {
    return (
        <div className="not-found-wrapper">
            <span>401</span>
            <p>You are not authorized to view this page</p>
            <div>
                <Button href={'/'} variant="dark">Take me to Home</Button>
            </div>
        </div>
    )
}
