import React, {Component} from 'react'
import RightContent from "../components/RightContent";
import {Card, Dropdown, Table} from "react-bootstrap";
import * as Action from "../../common/api-action";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import User from "./User";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            managers: null
        }
    }

    componentDidMount() {
        Action.callPostApi("user/agents", {}, this.onFetchUsers.bind(this));
    }

    onFetchUsers(data) {
        this.setState({managers: data.agents})
    }

    getHeaderButtons() {
        return [{
            href: "/admin/users/add",
            icon: "",
            text: "Add Manager"
        }];
    }

    onUpdateStatus(id, active) {
        const manager = this.getManager(id);
        if (manager) {
            manager.active = active;
            this.setState({managers: this.state.managers});
        }
    }

    getManager(id) {
        if (!id || isNaN(id) || !this.state.managers) {
            return null;
        }
        for (let i = 0; i < this.state.managers.length; i++) {
            if (this.state.managers[i].id == id) {
                return this.state.managers[i];
            }
        }
        return null;
    }

    changeManagerStatus(id, active) {
        Action.callPostApi("user/active",
            {id: id, active: active}, this.onUpdateStatus.bind(this, id, active));
    }

    onDeleteManagerClick(id) {
        if (window.confirm("Are you sure you want to delete this Manager?")) {
            this.deleteManager(id);
        }
    }

    deleteManager(id) {
        Action.callPostApi("user/delete",
            {id: id}, this.afterDeleteManager.bind(this, id));
    }

    afterDeleteManager(id) {
        const managerListAfterDeletion = this.state.managers.filter(function(manager) { return manager.id !== id; });
        this.setState({managers: managerListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (!this.state.managers) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <User mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const manager = this.getManager(StringUtil.getLastPathParam("/edit/"));
            if (manager) {
                return <User mode={mode} manager={manager} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Managers"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="20%">Name</th>
                                <th width="15%">Email</th>
                                <th width="15%">Phone</th>
                                <th width="15%">Created On</th>
                                <th width="10%">Status</th>
                                <th width="5%">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.managers || this.state.managers.length == 0 &&
                            <tr><td colSpan={6}>No Managers found.</td></tr>}
                            {this.state.managers && this.state.managers.map((manager) => {
                                return (
                                    <tr key={manager.id}>
                                        <td>{manager.firstName + " " + manager.middleName + " " + manager.lastName}</td>
                                        <td>{manager.email}</td>
                                        <td>{manager.phone}</td>
                                        <td>{manager.createdOn}</td>
                                        <td>{(manager.active == 1 ? "Active" : "Inactive")}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/users/edit/" + manager.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                        this.changeManagerStatus(manager.id, manager.active == 0 ? 1 : 0)
                                                    }}>
                                                        <span className="material-icons-outlined">
                                                            {manager.active == 0 ? 'published_with_changes' : 'unpublished'}
                                                        </span>
                                                        {manager.active == 0 ? 'Publish' : 'Hide'}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteManagerClick.bind(this, manager.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}