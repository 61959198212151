import {useForm} from 'react-hook-form';
import * as Action from "../../common/api-action";
import InputError from "../components/InputError";
import SocialLogin from "../components/SocialLogin";
import {Helmet} from "react-helmet";
import * as Constants from "../../common/constants";
import React from "react";

const Signup = (props) => {
    const {register, handleSubmit, formState: {errors}, setError} = useForm();
    const onSignup = (form) => {
        const data = {
            "email": form.email,
            "password": form.password,
            "fullName": form.fullName
        }
        Action.callPostApi("auth/signUp", data, afterSignUp, onSignUpError);
    }

    const afterSignUp = (data) => {
        props.afterEntry(data, props.admin);
    }
    const onSignUpError = (data) => {
        setError(data.field, {type: 'custom', message: data.message});
    }
    return (
        <div className="login_reg_outer_wrapper">
            {props.organization && <Helmet>
                <link rel="icon" type="image/png"
                      href={Constants.serverUrl + "file/get/" + props.organization.favIconId}/>
            </Helmet>}
            <div className="login_reg_inner_wrapper">
                <a className="mb-5" href="/">
                    {props.organization && <img src={Constants.serverUrl + "file/get/" + props.organization.logoFileId} alt="logo"/>}
                </a>

                <form onSubmit={handleSubmit(onSignup)}>
                    <div className="form-group mb-4">
                        <input type="text" name="fullName" placeholder="Full Name" aria-label="Full Name"
                               className={"form-control" + (errors.fullName ? " is-invalid" : "")}
                               {...register('fullName', {required: 'Name cannot be empty'})}/>
                        <InputError error={errors.fullName}/>
                    </div>

                    <div className="form-group mb-4">
                        <input type="email" name="email" placeholder="Email Address" aria-label="Email Address"
                               className={"form-control" + (errors.email ? " is-invalid" : "")}
                               {...register('email', {required: 'Email cannot be empty'})}/>
                        <InputError error={errors.email}/>
                    </div>

                    <div className="form-group mb-4">
                        <input type="password" name="password" autoComplete="off"
                               placeholder="Password" aria-label="Password"
                               className={"form-control" + (errors.password ? " is-invalid" : "")}
                               {...register('password', {required: 'Password cannot be empty'})}/>
                        <InputError error={errors.password}/>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary w-100">Signup</button>
                    </div>

                    <div className="form_or mb-4 mt-4"></div>

                    <SocialLogin afterLogin={afterSignUp} onError={onSignUpError}/>

                    <p className="text-center mb-0">Already a user?
                        <a className="link-primary" href={props.admin ? "/admin" : "/login"}> LOGIN</a></p>
                    <p className="text-center mb-0"><a className="link-secondary" href="/">Go back to home</a></p>
                </form>
            </div>
        </div>
    )
}

export default Signup