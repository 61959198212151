import * as Constants from '../../common/constants';

export function getLoggedInUser() {
    return sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
}

export function setLoggedInUser(user) {
    return sessionStorage.setItem("user", JSON.stringify(user));
}

export function logoutUser() {
    return sessionStorage.removeItem("user");
}

export function isValidUser() {
    const user = getLoggedInUser();
    return user && user.type === 'success' && user.token && user.name;
}

export function setVerifiedPhone(phone, accessToken) {
    sessionStorage.setItem("phone", phone);
    sessionStorage.setItem("accessToken", accessToken);
}

export function isPhoneVerified() {
    return sessionStorage.getItem("phone") && sessionStorage.getItem("accessToken");
}

export function setContactFormSubmissionStatus(status) {
    sessionStorage.setItem("contactFormSubmitted", status);
}

export function isContactFormSubmitted() {
    return sessionStorage.getItem("contactFormSubmitted");
}

export function isAutoShowContactFormDisabled() {
    return sessionStorage.getItem("autoShowContactFormDisabled")
        && sessionStorage.getItem("autoShowContactFormDisabled") === 'true';
}

export function disableAutoShowContactForm() {
    if (!isAutoShowContactFormDisabled()) {
        sessionStorage.setItem("autoShowContactFormDisabled", "true");
    }
}

export function isValidBackendUser() {
    const user = getLoggedInUser();
    return user && user.type === 'success' && user.userType > 0;
}

export function isValidAdminUser() {
    const user = getLoggedInUser();
    return user && user.type === 'success' && user.userType >= 2;
}

export function isValidManagerUser() {
    const user = getLoggedInUser();
    return user && user.type === 'success' && user.userType >= 1;
}

export function getUserToken() {
    const user = getLoggedInUser();
    return user && user.type === 'success' ? user.token : '';
}

export function getCurrentCity(ignoreDefault) {
    return sessionStorage.getItem("currentCity")
        ? sessionStorage.getItem("currentCity") : (ignoreDefault ? null : Constants.defaultCity);
}

export function setCurrentCity(cityId) {
    return sessionStorage.setItem("currentCity", cityId);
}

export function canShowVerificationPopup() {
    if (sessionStorage.getItem("closeTime") && +sessionStorage.getItem("closeTime") > 0) {
        return false;
    }
    if (!sessionStorage.getItem("startTime")) {
        resetStartTime();
    }
    return !isPhoneVerified() && new Date().getTime() - +sessionStorage.getItem("startTime") > (Constants.VERIFICATION_TIME);
}

export function resetStartTime() {
    return sessionStorage.setItem("startTime", new Date().getTime().toString());
}

export function setCloseTime() {
    return sessionStorage.setItem("closeTime", new Date().getTime().toString());
}