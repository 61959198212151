import React from 'react'
import '../styles/footer.scss'
import {Accordion} from "react-bootstrap";
import * as JsonUtil from "../../common/util/json-util";
import * as ArrayUtil from "../../common/util/array-util";
import * as AreaUnit from "../../enums/area_unit";
import Parser from "html-react-parser";

const Faqs = ({faqs, property}) => {
    function getAreaUnit() {
        return ArrayUtil.getLabel(AreaUnit.area_units, JsonUtil.getFieldValue(property, "propertyDetail.areaUnit"));
    }

    const getAgentName = (agent) => {
        return agent.firstName
            + (agent.middleName && agent.middleName.length ? " " + agent.middleName : "")
            + (agent.lastName && agent.lastName.length ? " " + agent.lastName : "");
    }

    const getFaqText = (text) => {
        return text.replaceAll('${propertyName}', property.name)
            .replaceAll('${totalArea}', JsonUtil.getFieldValue(property, "propertyDetail.totalArea"))
            .replaceAll('${localityName}', JsonUtil.getFieldValue(property, "address.locality.name"))
            .replaceAll('${cityName}', JsonUtil.getFieldValue(property, "address.district.name"))
            .replaceAll('${stateName}', JsonUtil.getFieldValue(property, "address.state.name"))
            .replaceAll('${countryName}', JsonUtil.getFieldValue(property, "address.country.name"))
            .replaceAll('${pincode}', JsonUtil.getFieldValue(property, "address.pincode"))
            .replaceAll('${address}', JsonUtil.getFieldValue(property, "address.address"))
            .replaceAll('${areaUnit}', getAreaUnit())
            .replaceAll('${towers}', JsonUtil.getFieldValue(property, "propertyDetail.towers"))
            .replaceAll('${apartmentsPerFloor}', JsonUtil.getFieldValue(property, "propertyDetail.apartmentsPerFloor"))
            .replaceAll('${maharera}', JsonUtil.getFieldValue(property, "propertyDetail.maharera"))
            .replaceAll('${projectStart}', JsonUtil.getFieldValue(property, "propertyDetail.possessionStart"))
            .replaceAll('${projectEnd}', JsonUtil.getFieldValue(property, "propertyDetail.possessionEnd"))
            .replaceAll('${agentName}', getAgentName(JsonUtil.getFieldValue(property, "agent")))
            .replaceAll('${status}', JsonUtil.getFieldValue(property, "status"))
            .replaceAll('${developerName}', JsonUtil.getFieldValue(property, "developer.name"));
    }

    return faqs && faqs.length > 0 ?
        <Accordion alwaysOpen="true">
            {
                faqs.map((faq, index) => {
                    return <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>{getFaqText(faq.question)}</Accordion.Header>
                        <Accordion.Body>
                            {Parser(getFaqText(faq.answer))}
                        </Accordion.Body>
                    </Accordion.Item>
                })
            }
        </Accordion> : null
}

export default Faqs