import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import RightContent from "../components/RightContent";
import * as ArrayUtil from "../../common/util/array-util";
import * as DateUtil from "../../common/util/date-util";
import InputError from "../components/InputError";
import * as EnquiryStatus from "../../enums/enquiry_status";
import * as Constants from "../../common/constants";

const Enquiry = ({enquiry, setSavingStatus, apartmentTypes}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        Action.callPostApi("enquiry/update", data, afterSave, onSaveError);
    }

    const getData = (form) => {
        return {
            "id": +enquiry.id,
            "status": form.status,
            "replyComment": form.replyComment
        };
    }

    const afterSave = () => {
        setSavingStatus(2);
        document.location.href = document.location.href;
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(enquiry, name);
    }

    return (
        <>
            <RightContent headerText={"Update Enquiry"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Enquiry Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("name")} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Property Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("property.name")} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Apartment Type
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text"
                                                  defaultValue={ArrayUtil.getFieldValueForId(enquiry.apartment.typeId,
                                                      "name", apartmentTypes) + " " + Constants.APARTMENT_CONFIG_SUFFIX} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Email
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("email")} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Phone
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("phone")} disabled/>
                                </Col>
                            </Form.Group>

                            {getFieldValue("type") == 1 && <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Visit Date
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={DateUtil.getFormattedDate(getFieldValue("visitDate"),
                                        "DD MMM, YYYY")} disabled/>
                                </Col>
                            </Form.Group>}

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Visit Type
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("type") == 1 ? "In Person" : "Video Chat"} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Created On
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="text" defaultValue={getFieldValue("createdOn")} disabled/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Action Item
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control as="textarea"
                                                  className={getInvalidFieldClass('replyComment')}
                                                  aria-label="Reply"
                                                  maxLength={1000}
                                                  {...register('replyComment', {required: 'Comment cannot be empty'})}/>
                                    <InputError error={errors.replyComment}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Status
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Select aria-label="Status" name="status" defaultValue={getFieldValue("status")}
                                                 {...register('status')}>
                                        {
                                            EnquiryStatus.enquiry_statuses.map((status) => {
                                                return <option key={status.value}
                                                               value={status.value}>{status.label}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>

                            <div>
                                Previous Comments
                                <ul>
                                {enquiry.enquiryLogs && enquiry.enquiryLogs.map((enquiryLog) => {
                                    return (<li>{enquiryLog.agent.firstName + " " + enquiryLog.agent.lastName
                                    + "(" + enquiryLog.createdOn + ") : " + enquiryLog.comment}</li>);
                                })}
                                </ul>
                            </div>

                            <div className="form-group action-btn-wrapper">
                                <Button type="submit">Save</Button>
                                <Button href={"/admin/enquiry"} variant="dark">Cancel</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </RightContent>
        </>
    )
}

export default Enquiry;