import React, {useState} from 'react';
import FsLightbox from 'fslightbox-react';
import * as Constants from "../../common/constants";

const PropertyGallery = ({images}) => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        sourceIndex: 0
    });

    function openLightboxOnSource(sourceIndex) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            sourceIndex: sourceIndex
        });
    }

    const renderAllImages = () => {
        return [
            <div key={0}
                 className="pd-gallery-left pd-gallery-image"
                 onClick={() => openLightboxOnSource(0)}
                 style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + images[0].id + "')"}}>
            </div>,
            <div key={1} className="pd-gallery-right">
                <div
                    className="pd-gallery-image"
                    onClick={() => openLightboxOnSource(1)}
                    style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + images[1].id + "')"}}>
                </div>
                <div
                    className="pd-gallery-image"
                    onClick={() => openLightboxOnSource(2)}
                    style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + images[2].id + "')"}}>
                    <span>{images.length > 3 ? images.length - 3 + " More" : ""}</span>
                </div>
            </div>
        ]
    }

    const renderTwoImage = () => {
        return [
            <div key={0}
                 className="pd-gallery-left pd-gallery-image"
                 onClick={() => openLightboxOnSource(0)}
                 style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + images[0].id + "')"}}>
            </div>,
            <div key={1}
                 className="pd-gallery-right pd-gallery-image"
                 onClick={() => openLightboxOnSource(1)}
                 style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + images[1].id + "')"}}>
            </div>
        ]
    }

    const renderImage = (id) => {
        return (
            <div className={"pd-gallery-center pd-gallery-image"}
                 onClick={() => !id ? "" : openLightboxOnSource(0)}
                 style={{backgroundImage: "url('" + Constants.serverUrl + "file/get/" + id + "')"}}>
            </div>
        )
    }

    const getAppendedImageUrls = (array) => {
        images.map(image => {
            array.push(Constants.serverUrl + "file/get/" + image.id);
        })
        return array;
    }

    return (
        <>
            <div className="pd-gallery-wrapper">
                {
                    images ? (images.length > 2 ?
                        renderAllImages() : (images.length > 1 ? renderTwoImage()
                            : (images.length > 0 ? renderImage(images[0].id) : renderImage()))) :
                        renderImage()
                }
            </div>

            <FsLightbox
                toggler={lightboxController.toggler}
                sourceIndex={lightboxController.sourceIndex}
                thumbs={getAppendedImageUrls([null])}
                sources={getAppendedImageUrls([])}
            />
        </>
    );
}

export default PropertyGallery