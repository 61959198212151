import React, {useState} from 'react'
import * as Action from "../../common/api-action";
import AppLoader from "../../common/component/AppLoader";
import {Card, Col, Form, Row} from "react-bootstrap";

const Amenities = (props) => {
    const [loading, setLoading] = useState(true);
    const [amenities, setAmenities] = useState([]);

    const populateAmenitiesData = (response) => {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            setAmenities(response.amenities);
            props.setAmenities(response.amenities);
            setLoading(false);
        }
    }

    const isAmenitySelected = (id) => {
        for (let i = 0; props.selectedAmenities && i < props.selectedAmenities.length; i++) {
            if (props.selectedAmenities[i].id === id) {
                return true;
            }
        }
        return false;
    }

    React.useEffect(() => {
        Action.callPostApi("amenities/property", {}, populateAmenitiesData);
    }, []);

    return (
        <Card className="aminities-card-wrapper">
            <Card.Header>
                <Card.Title>
                    Amenities
                </Card.Title>
            </Card.Header>
            {loading || !amenities || amenities.length == 0 ?
                <AppLoader position={"fixed"}/> :
                <Card.Body>
                    {
                        amenities.map(amenity => {
                            return (
                                <Form.Label key={amenity.id}>
                                    <input name={"amenity"} value={amenity.id}
                                                defaultChecked={isAmenitySelected(amenity.id)}
                                                {...props.register("amenity")}
                                                type="checkbox" hidden/>
                                    <span className="control-tick"></span>
                                    <i className="bb-icons">{amenity.icon}</i>
                                    <small>{amenity.name}</small>
                                </Form.Label>
                            )
                        })
                    }
                </Card.Body>
            }
        </Card>
    )
}

export default Amenities;