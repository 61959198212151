import React, {Component} from 'react'
import ReactSelect from "../../common/component/ReactSelect";

export default class LocalityDropdown extends Component {
    getLocationOptions() {
        const {localities, selectedCities} = this.props;
        let localitiesOptions = [];
        for (let i = 0; i < localities.length; i++) {
            let locality = localities[i];
            if (selectedCities && selectedCities.filter((selectedCity) => selectedCity.value === locality.districtId).length > 0) {
                localitiesOptions.push({
                    value: locality.id,
                    label: locality.name + ", " + locality.district.name
                });
            }
        }
        return localitiesOptions;
    }

    render() {
        return (
            <div className="search-col">
                <i className="material-icons-two-tone">location_on</i>
                <ReactSelect id="localitySelect"
                             title="Locality"
                             className='select-locality'
                             onValueChange={this.props.onUpdateSelectedLocalities}
                             isMultiple={true}
                             value={this.props.selectedLocalities}
                             options={this.getLocationOptions()}/>
            </div>
        )
    }
}