import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import InputError from "../components/InputError";
import FileUpload from "../components/FileUpload";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import * as FileUtil from "../../common/util/file-util";
import RightContent from "../components/RightContent";

const Developer = ({developer, setSavingStatus}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        if (isAdd()) {
            Action.callPostApi("developer/add", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("developer/update", data, afterSave, onSaveError, null, true);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("logo", form.logo[0]);
        const developerData = {
            "id": isAdd() ? null : +developer.id,
            "name": form.name,
            "logo": getLogoDetails(form),
            "description": form.description
        };
        formData.append("data", JSON.stringify(developerData));
        return formData;
    }

    const getLogoDetails = (form) => {
        if (form.logo && form.logo.length > 0) {
            return {
                id: form.logoId,
                fileName: form.logo[0].name,
                displayName: form.logo[0].name,
                fileType: 1,
                size: form.logo[0].size,
                mimetype: form.logo[0].type,
                format: FileUtil.getFileFormat(form.logo[0].name)
            }
        }
        if (form.logoId > 0) {
            return getFieldValue("logo");
        }
        if (form.logoId == -1) {
            return {
                id: getFieldValue("logo.id")
            }
        }
        return null;
    }
    const afterSave = () => {
        setSavingStatus(2);
        document.location.href = isAdd() ? '/admin/developers' : document.location.href;
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(developer, name);
    }

    const isAdd = () => {
        return !developer || !developer.id || developer.id == 0;
    }

    return (
        <>
            <RightContent headerText={(!isAdd() ? "Edit" : "Add") + " Developer"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Developer Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    About the Developer
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control as="textarea" name={"description"}
                                                  className={getInvalidFieldClass('description')}
                                                  aria-label="About the project"
                                                  maxLength={1000}
                                                  defaultValue={getFieldValue("description")}
                                                  {...register('description')}/>
                                    <InputError error={errors.description}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="logo" className="mb-3">
                                <Form.Label column sm="3">
                                    Logo
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"logo"}
                                                value={getFieldValue("logoId")}
                                                register={register}/>
                                </Col>
                            </Form.Group>

                            <div className="form-group action-btn-wrapper">
                                <Button type="submit">Save</Button>
                                <Button href={"/admin/developers"} variant="dark">Cancel</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </RightContent>
        </>
    )
}

export default Developer;