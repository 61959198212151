import React from 'react'

const ContainerMain = (props) => {
  return (
    <div className={`section-wrapper ${props.bgColor ? "has-bg " : ""}${props.carea ? "content-area " : ""}` + props.className}>
      <div className="container">
        {props.children}
      </div>
    </div>
  )
}

export default ContainerMain