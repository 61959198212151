import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import SectionTitle from './SectionTitle';
import ContainerMain from './ContainerMain';
import * as Constants from "../../common/constants";
import * as StringUtil from "../../common/util/string-util";
import Parser from 'html-react-parser';

const Testimonials = ({reviews}) => {
    const slidesToShow = reviews.length > 2 ? 2 : reviews.length

    const settingsReview = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slidesToShow,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <ContainerMain className="testimonial-carousel" bgColor={true}>
            <SectionTitle text="Customer" span="Reviews"/>
            <div className="inner-wrapper">
                <Slider {...settingsReview}>
                    {
                        reviews && reviews.length > 0 && reviews.map(review => {
                            return (
                                <div className="testimonial-wrapper" key={review.id}>
                                    <div className="testimonial-info">
                                        <div className="user-section">
                                            <div className="user-thumb">
                                                <img src={Constants.serverUrl + "file/get/" + review.photoId} alt="User Image"/>
                                                <div className="icon">
                                                    <i className="material-icons-round">format_quote</i>
                                                </div>
                                            </div>
                                            <div className="user-name">
                                                <h5>{review.name}</h5>
                                                <p>{review.title}</p>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>
                                                {StringUtil.getEllipseValue(Parser(review.description), 400)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </ContainerMain>
    )
}

export default Testimonials