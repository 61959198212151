import React, {Component} from 'react'
import RightContent from "../components/RightContent";
import {Card, Dropdown, Table} from "react-bootstrap";
import * as Action from "../../common/api-action";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import Enquiry from "./Enquiry";
import * as ArrayUtil from "../../common/util/array-util";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";
import * as Constants from "../../common/constants";

export default class EnquiryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enquiries: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("enquiry", {}, this.populateEnquiriesData.bind(this));
    }

    getHeaderButtons() {
        return [{
            onClick: ()=> {Action.callPostApi("excel/leads", {}, null, null, false)},
            text: "Download Leads"
        }];
    }

    populateEnquiriesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                enquiries: response.enquiries
            });
        }
    }

    getEnquiry(id) {
        if (!id || isNaN(id) || !this.state.enquiries) {
            return null;
        }
        for (let i = 0; i < this.state.enquiries.length; i++) {
            if (this.state.enquiries[i].id == id) {
                return this.state.enquiries[i];
            }
        }
        return null;
    }

    render() {
        const {mode, setSavingStatus} = this.props;

        if (!Session.isValidManagerUser()) {
            return <NoAccess/>;
        }

        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'edit') {
            const enquiry = this.getEnquiry(StringUtil.getLastPathParam("/edit/"));
            if (enquiry) {
                return <Enquiry mode={mode} apartmentTypes={this.props.apartmentTypes}
                                 enquiry={enquiry} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Enquiries"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="5%">Id</th>
                                <th width="15%">Name</th>
                                <th width="20%">Property Name</th>
                                <th width="15%">Apartment Type</th>
                                <th width="15%">Email</th>
                                <th width="15%">Phone</th>
                                <th width="15%">Created On</th>
                                <th width="5%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.enquiries || this.state.enquiries.length == 0 &&
                            <tr><td colSpan={8}>No Enquiries found.</td></tr>}
                            {this.state.enquiries && this.state.enquiries.map((enquiry) => {
                                return (
                                    <tr key={enquiry.id}>
                                        <td>{enquiry.id}</td>
                                        <td>{enquiry.name}</td>
                                        <td>{enquiry.property?.name}</td>
                                        <td>{StringUtil.isEmpty(ArrayUtil.getFieldValueForId(enquiry.apartment?.typeId,
                                            "name", this.props.apartmentTypes)) ? "" : ArrayUtil.getFieldValueForId(enquiry.apartment?.typeId,
                                            "name", this.props.apartmentTypes)  + " " + Constants.APARTMENT_CONFIG_SUFFIX}</td>
                                        <td>{enquiry.email}</td>
                                        <td>{enquiry.phone}</td>
                                        <td>{enquiry.createdOn}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/enquiry/edit/" + enquiry.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}