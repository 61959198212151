import React, {Component} from 'react'
import RightContent from "../components/RightContent";
import {Card, Dropdown, Table} from "react-bootstrap";
import * as Action from "../../common/api-action";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import Review from "./Review";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class ReviewList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reviews: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("user-reviews", {}, this.populateReviewsData.bind(this));
    }

    populateReviewsData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                reviews: response.reviews
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/reviews/add",
            icon: "",
            text: "Add Review"
        }];
    }

    getReview(id) {
        if (!id || isNaN(id) || !this.state.reviews) {
            return null;
        }
        for (let i = 0; i < this.state.reviews.length; i++) {
            if (this.state.reviews[i].id == id) {
                return this.state.reviews[i];
            }
        }
        return null;
    }

    onDeleteReviewClick(id) {
        if (window.confirm("Are you sure you want to delete this Review?")) {
            Action.callPostApi("user-reviews/delete",
                {id: id}, this.afterDeleteReview.bind(this, id));
        }
    }

    afterDeleteReview(id) {
        const reviewListAfterDeletion = this.state.reviews.filter(function(review) { return review.id !== id; });
        this.setState({reviews: reviewListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <Review mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const review = this.getReview(StringUtil.getLastPathParam("/edit/"));
            if (review) {
                return <Review mode={mode} review={review} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Reviews"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="40%">Name</th>
                                <th width="40%">Title</th>
                                <th width="15%">Created On</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.reviews || this.state.reviews.length == 0 &&
                            <tr><td colSpan={6}>No Reviews found.</td></tr>}
                            {this.state.reviews && this.state.reviews.map((review) => {
                                return (
                                    <tr key={review.id}>
                                        <td>{review.name}</td>
                                        <td>{review.title}</td>
                                        <td>{review.createdOn}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/reviews/edit/" + review.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteReviewClick.bind(this, review.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}