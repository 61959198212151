import * as Constants from "./constants";
import * as Session from '../app/util/session'

export function getAuthHeader(contentType, token, isFormData) {
    let header = {};
    if (!isFormData) {
        header['Content-Type'] = contentType || 'application/json';
    }
    if (token) {
        header.Authorization = 'Bearer ' + token;
    }
    return header;
}

export function callPostApi(methodName, data, onSuccess, onError, contentType, isFormData) {
    const url = Constants.serverUrl + methodName;
    fetch(url, {
        method: 'POST',
        headers: getAuthHeader(contentType, Session.getUserToken(), isFormData),
        body: isFormData ? data : JSON.stringify(data)
    }).then((response) => {
        if (response.ok) {
            if (contentType === false) {
                return response.blob();
            }
            return response.clone().json();
        } else {
            throw new Error('Something went wrong');
        }
    }).then(data => {
        if (data.type === 'customError' && data.message === 'User not authorised') {
            Session.logoutUser();
        }
        if (contentType === false) {
            const href = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.download = 'Leads.xlsx';
            link.href = href;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if ((!data || data.type === 'success') && onSuccess) {
            onSuccess(data);
        } else if (data.type === 'customError' && onError) {
            onError(data);
        }
    }).catch((error) => {
        console.log(error)
    });
}

export function callGetApi(methodName, onSuccess, onError, token) {
    const url = Constants.serverUrl + methodName;
    fetch(url, {
        method: 'GET',
        headers: getAuthHeader(null, token),
    }).then((response) => {
        if (response.ok) {
            return response.clone().json();
        } else {
            throw new Error('Something went wrong');
        }
    }).then(data => {
        if ((!data || data.type === 'success') && onSuccess) {
            onSuccess(data);
        } else if (data.type === 'customError' && onError) {
            onError(data);
        }
    }).catch((error) => {
        console.log(error)
    });
}

export async function callApiSync(methodName, data, contentType, token) {
    const url = Constants.serverUrl + methodName;
    const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeader(contentType, token),
        body: JSON.stringify(data)
    });
    return await response.json();
}