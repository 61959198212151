import React, {Component} from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Form} from 'react-bootstrap';

import AppLoader from "../../common/component/AppLoader";
import * as Action from "../../common/api-action";
import FormCKEditor from "../../common/component/FormCKEditor";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            faqs: [],
            errors: {}
        }
    }

    componentDidMount() {
        Action.callPostApi("faq/all", {}, this.populateFaqsData.bind(this));
    }

    populateFaqsData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                faqs: response.faqs
            });
        }
    }

    onSave(index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.faqs[index];
        Action.callPostApi("faq/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onDelete(index) {
        if (window.confirm("Are you sure you want to delete this FAQ? This action cannot be reverted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.faqs[index];
            Action.callPostApi("faq/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    afterSave() {
        this.props.setSavingStatus(2);
    }

    afterDelete() {
        document.location.href = "/admin/faqs";
    }

    setError(error) {
        let errors = this.state.errors;
        errors[error.fieldName] = error.message;
        this.setState({
            errors: errors
        })
    }

    resetErrors() {
        this.setState({errors: {}});
    }

    getError(fieldName) {
        return this.state.errors[fieldName];
    }

    hasError(fieldName) {
        return this.getError(fieldName) && this.getError(fieldName).length > 0;
    }

    onAddNewPageClick() {
        const faqsUpdated = [...this.state.faqs];
        faqsUpdated.push(this.getNewPageJSON());
        this.setState({faqs: faqsUpdated});
    }

    getNewPageJSON() {
        return {
            id: 0,
            question: "New Question",
            answer: "Answer to this question"
        }
    }

    getInvalidFieldClass(field) {
        return this.hasError(field) ? ' is-invalid' : '';
    }

    onChangeField = (index, id, value) => {
        const faqsUpdated = [...this.state.faqs];
        faqsUpdated[index][id] = value;
        this.setState({faqs: faqsUpdated});
    }

    render() {
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position={"fixed"}/>
        }

        return (
            <RightContent>
                <div className="admin-header-title">
                    <h5>FAQs</h5>
                    <Button variant="primary" onClick={this.onAddNewPageClick.bind(this)}>
                        <span>Add Question</span>
                    </Button>

                </div>

                {this.state.faqs.map((faq, index) => (
                    <Card className="has-ckeditor" key={index}>
                        <Card.Body>

                            <Card.Title>
                                <Form.Control type="hidden" defaultValue={faq.id}
                                                id={"hidden_" + index}/>
                                <Form.Control type="text" defaultValue={faq.question}
                                              className={this.getInvalidFieldClass('question')}
                                              onBlur={(e) => this.onChangeField(index, "question", e.currentTarget.value)}
                                              aria-label="Question"
                                              placeholder={"Question"}
                                              id={"question_" + index}/>
                                
                            </Card.Title>
                            <FormCKEditor id={'answer_' + index}
                                          name={'answer_' + index}
                                          title={'Answer'}
                                          defaultHtml={faq.answer}
                                          onChange={this.onChangeField.bind(this, index, "answer")}/>

                        </Card.Body>
                        <Card.Footer>
                            <div className="action-btn-wrapper">
                                <Button variant='primary' onClick={this.onSave.bind(this, index)}>Save</Button>
                                <Button variant='dark' onClick={this.onDelete.bind(this, index)}>Delete</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                ))}


            </RightContent>
        );
    }
}