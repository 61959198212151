import React, {Component} from 'react';
import {Card, Dropdown, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import RightContent from "../components/RightContent";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import State from "./State";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class StateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("states", {}, this.populateStatesData.bind(this));
    }

    populateStatesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                states: response.states
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/states/add",
            icon: "",
            text: "Add State"
        }];
    }

    getState(id) {
        if (!id || isNaN(id) || !this.state.states) {
            return null;
        }
        for (let i = 0; i < this.state.states.length; i++) {
            if (this.state.states[i].id == id) {
                return this.state.states[i];
            }
        }
        return null;
    }

    onDeleteStateClick(id) {
        if (window.confirm("Are you sure you want to delete this State?")) {
            Action.callPostApi("states/delete",
                {id: id}, this.afterDeleteState.bind(this, id));
        }
    }

    afterDeleteState(id) {
        const stateListAfterDeletion = this.state.states.filter(function(state) { return state.id !== id; });
        this.setState({states: stateListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }

        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <State mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const state = this.getState(StringUtil.getLastPathParam("/edit/"));
            if (state) {
                return <State mode={mode}
                                  state={state} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"States"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="30%">Name</th>
                                <th width="30%">Code</th>
                                <th width="30%">Country</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.states || this.state.states.length == 0 &&
                            <tr><td colSpan={6}>No States found.</td></tr>}
                            {this.state.states && this.state.states.map((state) => {
                                return (
                                    <tr key={state.id}>
                                        <td>{state.name}</td>
                                        <td>{state.code}</td>
                                        <td>{state.country ? state.country.name : ""}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/states/edit/" + state.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteStateClick.bind(this, state.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}