import React, {Component} from 'react';
import {Button, Card, Tab, Tabs, Accordion} from 'react-bootstrap';
import ContainerMain from '../components/ContainerMain';
import PropertyGallery from '../components/PropertyGallery';
import '../styles/propertydetail.scss'
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import * as Action from "../../common/api-action";
import * as JsonUtil from "../../common/util/json-util";
import * as Constants from "../../common/constants";
import {MapComponent} from '../../common/component/MapComponent';
import * as ArrayUtil from "../../common/util/array-util";
import * as AreaUnit from "../../enums/area_unit";
import CardFooter from '../components/CardFooter';
import ReactSelect from "../../common/component/ReactSelect";
import $ from 'jquery';
import ContactForm from "../components/ContactForm";
import {Helmet} from "react-helmet";
import * as Session from '../../app/util/session'
import {getFormattedPrice} from "../util/currency-util";
import "firebase/auth";
import FloorPlan from "../components/FloorPlan";
import SimilarProperties from "../components/SimilarProperties";
import RequestFinalPrice from "../components/RequestFinalPrice";
import Faqs from "../components/Faqs";

export default class PropertyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            property: null,
            showFloorPlan: false,
            requestFinalPrice: false,
            isContactFormOpen: null,
            apartmentTypes: [],
            selectedApartmentType: {},
            apartmentSizes: [],
            selectedApartment: {},
            amenityTypes: []
        }
    }

    componentDidMount() {
        const propertySlug = StringUtil.getLastPathParam("/property/");
        if(propertySlug && propertySlug.length > 0 && propertySlug !== 'null') {
            Action.callPostApi("property/get/" + propertySlug, {orgId: this.props.organization.id}, this.onSuccessPropertyApi.bind(this));
            this.setWindowScrollEvent();
        } else {
            document.location.href = '/page-not-found';
        }
    }

    setWindowScrollEvent() {
        $(window).on('scroll', () => {
            if (!Session.isAutoShowContactFormDisabled() && this.state.isContactFormOpen == null && window.scrollY >= 1000) {
                this.setState({isContactFormOpen: true});
            }
        })

    }

    getCardFooter(location) {
        return (
            <CardFooter>
                <i className="material-icons-two-tone">pin_drop</i>
                <span>{location}</span>
            </CardFooter>
        )
    }

    onSuccessPropertyApi(response) {
        if (response.property.length === 0) {
            document.location.href = '/page-not-found';
            return;
        }
        const apartmentTypes = this.getApartmentTypes(response.property);
        const apartmentSizes = this.getApartmentTypeSizes(response.property, (apartmentTypes.length ? apartmentTypes[0].value : 0));
        const amenityTypes = this.getSelectedSpecifications(response.property);
        this.setState({
            property: response.property,
            apartmentTypes: apartmentTypes,
            selectedApartmentType: apartmentTypes.length ? apartmentTypes[0] : {},
            apartmentSizes: apartmentSizes,
            selectedApartment: apartmentSizes ? apartmentSizes[0] : {},
            amenityTypes: amenityTypes
        });
    }

    getPropertyAmenities() {
        let allAmenities = this.getFieldValue("amenities");
        return allAmenities && allAmenities.filter(amenity => amenity.type === 1);
    }

    getAllSpecifications(property) {
        let allAmenities = JsonUtil.getFieldValue(property, "amenities");
        return allAmenities && allAmenities.filter(amenity => amenity.type !== 1);
    }

    getAmenityTypes(property) {
        let specifications = this.getAllSpecifications(property);
        let amenityTypes = [];
        for (let specification of specifications) {
            if (amenityTypes.length == 0 || amenityTypes.filter(amenityType => amenityType.id === specification.type).length == 0) {
                amenityTypes.push(specification.amenityType);
            }
        }
        return amenityTypes;
    }

    getSelectedSpecifications(property) {
        let amenityTypes = this.getAmenityTypes(property);
        let specifications = this.getAllSpecifications(property);
        amenityTypes.forEach((amenityType) => {
            amenityType.amenities = specifications.filter(specification => specification.type === amenityType.id);
        })
        return amenityTypes;
    }

    getLatitude() {
        return JsonUtil.getFieldValue(this.state.property, "address.latitude") ?
            JsonUtil.getFieldValue(this.state.property, "address.latitude") : Constants.DEFAULT_LATITUDE;
    }

    getLongitude() {
        return JsonUtil.getFieldValue(this.state.property, "address.longitude") ?
            JsonUtil.getFieldValue(this.state.property, "address.longitude") : Constants.DEFAULT_LONGITUDE;
    }

    getFieldValue(name) {
        const {property} = this.state;
        return JsonUtil.getFieldValue(property, name);
    }

    getMarker() {
        return {
            latitude: this.getLatitude(),
            longitude: this.getLongitude(),
            name: JsonUtil.getFieldValue(this.state.property, "name") ? JsonUtil.getFieldValue(this.state.property, "name") : "New Property"
        }
    }

    showHideAmenities() {
        if ($('.project-amenities-cad').hasClass("show-full")) {
            $('.project-amenities-cad').removeClass("show-full");
            $('.project-amenities-cad .btn-smore').html('Show More');
        } else {
            $('.project-amenities-cad').addClass("show-full");
            $('.project-amenities-cad .btn-smore').html('Show Less');
        }
    }

    getApartmentTypes(property) {
        let apartments = JsonUtil.getFieldValue(property, "apartments");
        const apartmentTypes = [];
        apartments && apartments.map(apartment => {
            if (apartmentTypes.filter(type => type.value === apartment.typeId).length === 0) {
                apartmentTypes.push({value: apartment.typeId, label: apartment.type.name + " " + Constants.APARTMENT_CONFIG_SUFFIX});
            }
        });
        return apartmentTypes;
    }

    getApartmentTypeSizes(property, typeId) {
        let apartments = JsonUtil.getFieldValue(property, "apartments");
        const apartmentSizes = [];
        apartments && apartments.map(apartment => {
            if (apartment.typeId === typeId) {
                apartmentSizes.push({value: apartment.id, label: apartment.reraCarpetArea + " "
                        + ArrayUtil.getLabel(AreaUnit.area_units, apartment.areaUnit), floorPlanId: apartment.floorPlanId,
                    carpetArea: apartment.carpetArea, price: apartment.price,
                    areaUnit: apartment.areaUnit, parking: apartment.parking, bathrooms: apartment.bathrooms, balcony: apartment.balcony});
            }
        });
        return apartmentSizes;
    }

    onApartmentTypeChange(selectedApartmentType) {
        const apartmentSizes = this.getApartmentTypeSizes(this.state.property, selectedApartmentType.value);
        this.setState({selectedApartmentType: selectedApartmentType, apartmentSizes: apartmentSizes, selectedApartment: apartmentSizes[0]});
    }

    onApartmentSizeChange(selectedApartment) {
        this.setState({selectedApartment: selectedApartment});
    }

    getCompletedPercentage() {
        const startDate = new Date(this.getFieldValue("propertyDetail.possessionStart")).getTime();
        const current = new Date().getTime() - startDate;
        const totalTime = new Date(this.getFieldValue("propertyDetail.possessionEnd")).getTime() - startDate;

        const completedPercentage = 100 * current / totalTime;
        return Math.round(completedPercentage < 0 ? 0 : (completedPercentage > 100 ? 100 : completedPercentage));
    }

    render() {
        if(!this.state.property) {
            return <div className="loader-wrapper"><AppLoader position="fixed"/></div>
        }
        let propertyAmenities = this.getPropertyAmenities();
        return (
            <>
                <Helmet>
                    <title>{this.getFieldValue("name")}</title>
                    <meta name="description" content={this.getFieldValue("propertyDetail.description")}/>
                    <style type="text/css">{`
                        .main_header {
                            background-color: #000;
                        }
                    `}</style>
                </Helmet>

                <ContainerMain className="pd-wrapper" carea="true">
                    <div className="img-absolute-top">
                        <img src="/images/pd-bg.png" alt="img"/>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 section-full">
                            <Card className="project-details pd-main-detail mt-4">
                                <div className="project-details-img" style={{backgroundImage: "url('"
                                        + Constants.serverUrl + "file/get/" + this.getFieldValue("bannerFileId") + "')"}}></div>
                                <Card.Header>
                                    <Card.Title as="h1">
                                        {this.getFieldValue("name")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <span className={"prop-id hidden"}>{this.getFieldValue("id")}</span>
                                    <span className={"apart-id hidden"}>{this.state.selectedApartment.value}</span>
                                    <ul>
                                        <li>
                                            <i className="material-icons-two-tone">location_on</i>
                                            {this.getFieldValue("address.address")
                                            + ", " + this.getFieldValue("address.locality.name")
                                            + ", " + this.getFieldValue("address.district.name")
                                            + ", " + this.getFieldValue("address.state.name")
                                            + ", " + this.getFieldValue("address.country.name")
                                            + ", " + this.getFieldValue("address.pincode")}
                                        </li>
                                        {this.getFieldValue("apartments") && this.getFieldValue("apartments").length ?
                                        [<li key={0}>
                                            <i className="material-icons-two-tone">apartment</i> 
                                            Apartments
                                        </li>,
                                        <li key={1}>
                                            <i className="material-icons-two-tone">area_chart</i>
                                            {this.getApartmentArea()}
                                            &nbsp;Usable Carpet Area
                                        </li>,
                                        <li key={2}>
                                            <i className="material-icons-two-tone">bed</i>
                                            {ArrayUtil.getCommaSeparatedValue(ArrayUtil.sort(this.getFieldValue("apartments"), "typeId"),
                                                "typeId", this.props.apartmentTypes)}
                                            {" " + Constants.APARTMENT_CONFIG_SUFFIX}
                                        </li>] : null}
                                    </ul>
                                    <div className="mt-3 d-flex flex-column">MahaRERA No. {this.getFieldValue("propertyDetail.maharera")}
                                        {JsonUtil.getIntFieldValue(this.state.property, "mahareraCertId") &&
                                        <a href={Constants.serverUrl + "file/get/" + this.getFieldValue("mahareraCertId")}
                                           target={"_blank"} rel="noopener noreferrer">
                                            Download Maharera Certificate</a>}
                                        {JsonUtil.getIntFieldValue(this.state.property, "mahareraCert2Id") &&
                                        <a href={Constants.serverUrl + "file/get/" + this.getFieldValue("mahareraCert2Id")}
                                           target={"_blank"} rel="noopener noreferrer">
                                            Download Maharera Certificate 2</a>}
                                    </div>
                                    {false && Session.isValidUser() && <div className="button-groups">
                                        <Button size="sm" variant="outline-primary" className="btn-pd">
                                            <i className="material-icons-outlined">share</i>Share</Button>
                                        <Button size="sm" variant="outline-primary" className="btn-pd">
                                            <i className="material-icons-outlined">bookmark_border</i>Bookmark</Button>
                                    </div>}
                                </Card.Body>
                                <Card.Footer className="d-flex flex-column">
                                    <div className="project-progress-bar">
                                        <p><span style={{width: this.getCompletedPercentage() + '%'}}><small>{this.getCompletedPercentage()}%</small></span></p>
                                    </div>
                                    <div className="project-se-detail">
                                        <span>{this.getFieldValue("propertyDetail.possessionStart")} Started</span>
                                        <span>{this.getFieldValue("propertyDetail.possessionEnd")} Possession</span>
                                    </div>
                                </Card.Footer>
                            </Card>

                            <Card className="project-summary">
                                <Card.Body>
                                    <PropertyGallery images={this.getFieldValue("galleryImages")}/>
                                </Card.Body>
                            </Card>

                            <Card className="project-summary">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        Project Summary
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="summary-lists">
                                        <li>
                                            <img src="/images/1.svg"/>
                                            <span>Total towers
                                                <small>{this.getFieldValue("propertyDetail.towers")}</small>
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/images/2.svg"/>
                                            <span className="span-heading">Total area ({ArrayUtil.getLabel(AreaUnit.area_units,
                                                this.getFieldValue("propertyDetail.areaUnit"))})
                                                <small>{this.getFieldValue("propertyDetail.totalArea")}</small>
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/images/3.svg"/>
                                            <span className="span-heading">Total floors
                                                <small>{this.getFieldValue("propertyDetail.floors")}</small>
                                            </span>
                                        </li>
                                        <li>
                                            <img src="/images/4.svg"/>
                                            <span className="span-heading">Apartments per floor
                                            <small>{this.getFieldValue("propertyDetail.apartmentsPerFloor")}</small>
                                            </span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>

                            {this.state.selectedApartment &&
                            <Card className="price-floor-card">
                                <Card.Body>
                                    <div className="d-flex gap-3">
                                        <div className="d-flex flex-column gap-3">
                                            <p className="d-flex">
                                                <ReactSelect id="apartmentTypeSelect"
                                                             title="Property Type"
                                                             className='select-bedrooms'
                                                             onValueChange={this.onApartmentTypeChange.bind(this)}
                                                             value={this.state.selectedApartmentType}
                                                             options={this.state.apartmentTypes}/>

                                                <ReactSelect id="apartmentSizeSelect"
                                                             title="Property Type"
                                                             onValueChange={this.onApartmentSizeChange.bind(this)}
                                                             className='select-bedrooms'
                                                             value={this.state.selectedApartment}
                                                             options={this.state.apartmentSizes}/>
                                            </p>

                                            <p className="d-flex">
                                                {!this.getFieldValue("hidePrice") &&
                                                    <span className="d-flex flex-column align-items-center">
                                                        <strong>&#x20B9; {getFormattedPrice(this.state.selectedApartment.price)}*</strong>
                                                        <small>Price is inclusive of all taxes</small>
                                                </span>}

                                                <span className="d-flex flex-column align-items-center">
                                                    <strong>
                                                        {this.state.selectedApartment.carpetArea
                                                    + " " + ArrayUtil.getLabel(AreaUnit.area_units, this.state.selectedApartment.areaUnit)}
                                                    </strong>
                                                    <small>Usable carpet area</small>
                                                </span>

                                                <span className="d-flex flex-column align-items-center">
                                                    <strong>{this.state.selectedApartment.parking}</strong>
                                                    <small>Number of car parking</small>
                                                </span>
                                                <span className="d-flex flex-column align-items-center">
                                                    <strong>{this.state.selectedApartment.bathrooms}</strong>
                                                    <small>Bathrooms</small>
                                                </span>
                                                <span className="d-flex flex-column align-items-center">
                                                    <strong>{this.state.selectedApartment.balcony}</strong>
                                                    <small>Balconies</small>
                                                </span>
                                            </p>

                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="vfp-img" style={{backgroundImage: 'url("/images/floor-plan-demo.jpeg")'}}></div>
                                            {this.state.selectedApartment && this.state.selectedApartment.floorPlanId &&
                                            <Button variant="link" className="request-price"
                                                    onClick={() => {this.setState({showFloorPlan: true})}}>
                                                View Floor Plan</Button>
                                            }
                                        </div>
                                    </div>

                                    <Button onClick={() => {this.setState({requestFinalPrice: true})}}
                                            variant="success">Request Final Price</Button>

                                </Card.Body>
                            </Card>}

                            <Card className="project-map-card">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        Property Location
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <MapComponent marker={this.getMarker()} canShowInfo={true} disabled={true}/>
                                </Card.Body>
                            </Card>

                            {propertyAmenities && propertyAmenities.length ?
                                <Card className={"project-amenities-cad" + (propertyAmenities.length > 8 ? " this-show-more" : "")}>
                                    <Card.Header>
                                        <Card.Title as="h3">
                                            Amenities
                                            <Button size="sm" variant="outline-primary" className="btn-smore"
                                                    onClick={() => {this.showHideAmenities()}}>
                                                Show More
                                            </Button>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="amenities">
                                            {
                                                this.getPropertyAmenities().map(amenity => {
                                                    return <li key={amenity.id}>
                                                        <span>
                                                        <i className="bb-icons">{amenity.icon}</i>
                                                        {amenity.name}
                                                        </span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </Card.Body>
                                </Card> : null
                            }

                            {this.state.amenityTypes.length ? <Card className="pr-specification">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        Specifications
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Tabs id="propertySpecifications">
                                        {
                                            this.state.amenityTypes.map(amenityType => {
                                                return <Tab eventKey={amenityType.id} title={amenityType.name}>
                                                    <ul className="amenities2">
                                                        {amenityType.amenities.map((amenity) => {
                                                            return <li key={amenity.id}><i
                                                                className="fa fa-check"></i>{amenity.name}</li>
                                                        })}
                                                    </ul>
                                                </Tab>
                                            })
                                        }
                                    </Tabs>

                                    <Accordion alwaysOpen="true">
                                        {
                                            this.state.amenityTypes.map((amenityType, index) => {
                                                return <Accordion.Item eventKey={index} key={index}>
                                                    <Accordion.Header>{amenityType.name}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className="amenities2">
                                                            {amenityType.amenities.map((amenity) => {
                                                                return <li key={amenity.id}><i
                                                                    className="fa fa-check"></i>{amenity.name}</li>
                                                            })}
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            })
                                        }
                                    </Accordion>
                                </Card.Body>
                            </Card> : null}

                            <Card className="project-description">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        About the Project
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        {this.getFieldValue("propertyDetail.description")}
                                    </p>
                                </Card.Body>
                            </Card>

                            {this.getFieldValue("youtube") && this.getFieldValue("youtube").length > 0
                            && <Card className="video-card">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        Property Video
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <iframe width="100%" src={this.getFieldValue("youtube")}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </Card.Body>
                            </Card>}


                            <Card>
                                <Card.Header>
                                    <Card.Title as="h3">
                                        About the Developer (<span>{this.getFieldValue("developer.name")}</span>)
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        {this.getFieldValue("developer.description")}
                                    </p>
                                </Card.Body>
                            </Card>

                            <Card>
                                <SimilarProperties apartmentTypes={this.props.apartmentTypes}
                                                   selectedApartmentType={this.state.selectedApartmentType.value}
                                                   propertyId={this.getFieldValue("id")}/>
                            </Card>

                            {this.props.faqs && this.props.faqs.length ? <Card className="project-faq-accordion">
                                <Card.Header>
                                    <Card.Title as="h3">
                                        Frequently asked questions
                                    </Card.Title>
                                </Card.Header>

                                <Card.Body>
                                    <Faqs faqs={this.props.faqs} property={this.state.property}/>
                                </Card.Body>
                            </Card> : null}
                        </div>
                    </div>
                </ContainerMain>

                <ContactForm isOpen={this.state.isContactFormOpen} user={this.getFieldValue("agent")}
                             propertyId={this.getFieldValue("id")}
                             organization={this.props.organization}
                             propertyName={this.getFieldValue("name")}
                             apartmentId={this.state.selectedApartment.value}
                             apartmentName={this.state.selectedApartment.label}
                             apartmentType={this.state.selectedApartmentType.label}
                             onToggle={this.toggleContactForm.bind(this)}/>

                {this.state.showFloorPlan ? <FloorPlan show={this.state.showFloorPlan} organization={this.props.organization}
                                                       floorPlanId={JsonUtil.getFieldValue(this.state.selectedApartment, "floorPlanId")}
                                                       onHide={() => {this.setState({showFloorPlan: false})}}/> : null}

                {this.state.requestFinalPrice ? <RequestFinalPrice show={this.state.requestFinalPrice}
                                                                   organization={this.props.organization}
                                                                   onHide={() => {this.setState({requestFinalPrice: false})}}/> : null}
            </>
        );
    }

    toggleContactForm() {
        this.setState({isContactFormOpen: !this.state.isContactFormOpen});
        Session.disableAutoShowContactForm();
    }

    getApartmentArea() {
        const min = ArrayUtil.getMinimum(this.getFieldValue("apartments"), "carpetArea");
        const max = ArrayUtil.getMaximum(this.getFieldValue("apartments"), "carpetArea")

        if (min === max) {
            return ArrayUtil.getMinimum(this.getFieldValue("apartments"), "carpetArea")
                + " " + ArrayUtil.getLabel(AreaUnit.area_units, this.getFieldValue("apartments")[0].areaUnit);
        }
        return ArrayUtil.getMinimum(this.getFieldValue("apartments"), "carpetArea")
            + " to " + ArrayUtil.getMaximum(this.getFieldValue("apartments"), "carpetArea")
            + " " + ArrayUtil.getLabel(AreaUnit.area_units, this.getFieldValue("apartments")[0].areaUnit);
    }
}
