import React, {useState} from 'react'
import {Button, Form, InputGroup, Tab, Tabs} from "react-bootstrap";
import * as Action from "../../common/api-action";
import {useForm} from 'react-hook-form';
import InputError from '../../admin/components/InputError';
import * as Constants from "../../common/constants";
import * as Session from "../util/session";
import * as TimeUtil from "../util/time-util";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from "../../common/component/AppLoader";
import $ from 'jquery';
import {initializeApp} from "firebase/app";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyArteBJZsvK2QkHfUV_R9nQTkCWh0HlC7Y",
    authDomain: "bombay-broking-6c953.firebaseapp.com",
    projectId: "bombay-broking-6c953",
    storageBucket: "bombay-broking-6c953.appspot.com",
    messagingSenderId: "190730582012",
    appId: "1:190730582012:web:794ef5dbff2d8016cb2479",
    measurementId: "G-N6SF8LVG8G"
};

const ContactForm = ({isOpen, onToggle, user, propertyId, apartmentId,
                         propertyName, apartmentName, apartmentType, organization}) => {
    const [otpUser, setOtpUser] = useState([]);
    const [showInfo, setShowInfo] = useState(true);
    const [viewOtpForm, setViewOtpForm] = useState(false);
    const [viewInfoOtpForm, setViewInfoOtpForm] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isInfoOtpVerified, setIsInfoOtpVerified] = useState(false);

    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm();

    const [submitStatus, setSubmitStatus] = useState(Session.isContactFormSubmitted());
    const showSpinner = (className, value) => {
        if (value) {
            $('.' + className).addClass('has-loader');
        } else {
            $('.' + className).removeClass('has-loader');
        }
    }

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const showOtpSpinner = (value) => {
        showSpinner((showInfo ? 'property-info-form-wrap' : 'property-schedule-form-wrap') + ' .otp-button', value);
    }

    auth.onAuthStateChanged((updatedUser) => {
        if (updatedUser && updatedUser != otpUser) {
            setOtpUser(updatedUser);
        }
    });

    React.useEffect(() => {
        window.recaptchaVerifier = window.recaptchaVerifier ? window.recaptchaVerifier :
            new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {

                }
            }, auth);
    }, []);

    const onOtpError = (message) => {
        setError("otp" + (showInfo ? "Info" : ""), {message: message});
    }

    const onSuccess = (response) => {
        Session.setVerifiedPhone(response.phoneNumber, response.accessToken);
        showOtpSpinner(false);
        if (showInfo) {
            setIsInfoOtpVerified(true);
            $('#side-contact-tabpane-info form #infoFormSubmit').click();
            $('#side-contact-tabpane-info form #infoFormSubmit').attr('disabled', 'true');
        } else {
            setIsOtpVerified(true);
            $('#side-contact-tabpane-schedule form #scheduleFormSubmit').click();
            $('#side-contact-tabpane-schedule form #scheduleFormSubmit').attr('disabled', 'true');
        }
    }

    const onVerificationError = (response) => {
        showOtpSpinner(false);
        if (showInfo) {
            setIsInfoOtpVerified(false);
        } else {
            setIsOtpVerified(false);
        }
    }

    const getForm = () => {
        let form = new FormData();
        if (showInfo) {
            form["nameInfo"] = $('[name=nameInfo]').val();
            form["phoneInfo"] = $('[name=phoneInfo]').val();
            form["emailInfo"] = $('[name=emailInfo]').val();
            form["termsInfo"] = $('[name=termsInfo]:checked');
        } else {
            form["name"] = $('[name=name]').val();
            form["phone"] = $('[name=phone]').val();
            form["email"] = $('[name=email]').val();
            form["terms"] = $('[name=terms]:checked');
            form["visitDate"] = $('[name=visitDate]').val();
        }
        return form;
    }

    const onGetOtp = (e) => {
        e.preventDefault();
        if (!isValidForm(getForm())) {
            return;
        }
        showOtpSpinner(true);
        let phone_number = '+91' + $("[name=phone" + (showInfo ? "Info" : "") + "]").val();
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phone_number, appVerifier)
            .then((confirmationResult) => {
                if (showInfo) {
                    setViewInfoOtpForm(true);
                } else {
                    setViewOtpForm(true);
                }
                window.confirmationResult = confirmationResult;
                showOtpSpinner(false);
            })
            .catch((error) => {
                onOtpError(error.message);
                showOtpSpinner(false);
            });
    };

    const otpSubmit = (e) => {
        e.preventDefault();
        if (!isValidForm(getForm())) {
            return;
        }
        showOtpSpinner(true);
        let opt_number = $('#otp' + (showInfo ? "Info" : "")).val();

        window.confirmationResult
            .confirm(opt_number)
            .then((confirmationResult) => {
                Action.callGetApi("auth/phone/verify",
                    onSuccess, onVerificationError, confirmationResult.user.accessToken);
            })
            .catch((error) => {
                onOtpError(error.message);
                showOtpSpinner(false);;
            });
    };

    function isValidForm(form) {
        return showInfo && isValidInfoForm(form) || !showInfo && isValidVisitForm(form);
    }

    const onSave = (form) => {
        if (isValidForm(form)) {
            showSpinner((showInfo ? "submit-info" : "submit-tour"), true);
            const data = showInfo ? getInfoData(form) : getData(form);
            Action.callPostApi("enquiry/save", data, afterSave.bind(this), onSaveError);
        }
    }

    const removeOtpError = () => {
        if (errors['otp']) {
            delete errors['otp'];
        }
    }

    const removeOtpInfoError = () => {
        if (errors['otpInfo']) {
            delete errors['otpInfo'];
        }
    }

    const getData = (form) => {
        return {
            visitDate: form.visitDate,
            type: 1,
            name: form.name,
            phone: form.phone,
            email: form.email,
            propertyId: propertyId,
            apartmentId: apartmentId,
            propertyName: propertyName,
            apartmentName: apartmentName,
            apartmentType: apartmentType,
            agentId: user.id,
            orgId: organization.id
        };
    }

    const getInfoData = (form) => {
        return {
            name: form.nameInfo,
            type: 0,
            phone: form.phoneInfo,
            email: form.emailInfo,
            propertyId: propertyId,
            apartmentId: apartmentId,
            propertyName: propertyName,
            apartmentType: apartmentType,
            apartmentName: apartmentName,
            agentId: user.id,
            orgId: organization.id
        };
    }

    const afterSave = () => {
        Session.setContactFormSubmissionStatus(true);
        showSpinner((showInfo ? "submit-info" : "submit-tour"), false);
        setSubmitStatus(true);
        if (organization && organization.additionalScripts
            && $('body').html().indexOf(organization.additionalScripts) < 0
            && organization.additionalScripts.length > 0) {
            $('body').append(organization.additionalScripts);
        }
    }

    const isValidInfoForm = (form) => {
        let hasError = false;
        if (!form.nameInfo || form.nameInfo.trim().length == 0) {
            setError("nameInfo", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("nameInfo", null);
        }
        if (!form.phoneInfo || form.phoneInfo.trim().length == 0) {
            setError("phoneInfo", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("phoneInfo", null);
        }
        if (!form.emailInfo || form.emailInfo.trim().length == 0) {
            setError("emailInfo", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("emailInfo", null);
        }
        if (!form.termsInfo || form.termsInfo.length == 0) {
            setError("termsInfo", {message: "Please agree to terms and conditions"})
            hasError = true;
        } else {
            setError("termsInfo", null);
        }

        return !hasError;
    }

    const isValidVisitForm = (form) => {
        let hasError = false;
        if (!form.name || form.name.trim().length == 0) {
            setError("name", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("name", null);
        }
        if (!form.phone || form.phone.trim().length == 0) {
            setError("phone", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("phone", null);
        }
        if (!form.email || form.email.trim().length == 0) {
            setError("email", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("email", null);
        }
        if (!form.visitDate || form.visitDate.trim().length == 0) {
            setError("visitDate", {message: "Cannot be empty"})
            hasError = true;
        } else {
            setError("visitDate", null);
        }
        if (!form.terms || form.terms.length == 0) {
            setError("terms", {message: "Please agree to terms and conditions"})
            hasError = true;
        } else {
            setError("terms", null);
        }

        return !hasError;
    }

    const onSaveError = (data) => {
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] && errors[field] != null && errors[field].message && !StringUtil.isEmpty(errors[field].message) ? ' is-invalid' : '';
    }

    return (
        <div className={"sidebar-form-wrapper" + (isOpen ? " active" : "")}>
            <div className="form-overlay"></div>
            <span className="open-side-btn" onClick={onToggle}>
                {isOpen ? "Close" : "Contact Us"}
            </span>
            {submitStatus
                ?
                <div className="tab-content form-smsg">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6"
                                strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6"
                                  strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                    <p>Thanks for showing interest in the property, Our team will contact you soon!</p>
                    <Button href={"tel:" + user.phone} variant="outline-primary" className="col-xs-6">Call Agent</Button>
                </div>
                :
                <Tabs id="side-contact" onSelect={(tabId) => {setShowInfo(tabId === 'info')}}>
                    <Tab eventKey="info" title="Request Info">
                        <Form onSubmit={handleSubmit(onSave.bind(this))} >
                            <div className="property-info-form-wrap">
                                <div className="profile-wrapper">
                                    <img src={Constants.serverUrl + "file/get/" + user.photoId} className="brround"
                                        alt="user"/>
                                    <div className="user-info">
                                        <h4 className="user-name">
                                            {user.firstName + ' ' + user.lastName}</h4>
                                        <span className="text-muted">
                                                    Member Since {TimeUtil.getFormattedDate(user.createdOn, 'MMM YYYY')}
                                                </span>
                                    </div>
                                </div>

                                <InputGroup className="mb-2 mt-3">
                                    <Form.Control name="nameInfo" type="text" placeholder="Name"
                                                  disabled={viewInfoOtpForm || isInfoOtpVerified}
                                                  readOnly={viewInfoOtpForm || isInfoOtpVerified}
                                                  className={getInvalidFieldClass('nameInfo')}
                                                  aria-label="Name"
                                                  {...register('nameInfo')}/>
                                    <InputError error={errors.nameInfo}/>
                                </InputGroup>

                                <InputGroup className="mb-2">
                                    <Form.Control name="phoneInfo" type="tel" placeholder="Phone"
                                                  disabled={viewInfoOtpForm || isInfoOtpVerified}
                                                  readOnly={viewInfoOtpForm || isInfoOtpVerified}
                                                  className={getInvalidFieldClass('phoneInfo')}
                                                  aria-label="Phone"
                                                  {...register('phoneInfo')}/>
                                    <InputError error={errors.phoneInfo}/>
                                </InputGroup>

                                <InputGroup className="mb-2">
                                    <Form.Control name="emailInfo" type="email" placeholder="Email"
                                                  className={getInvalidFieldClass('emailInfo')}
                                                  disabled={viewInfoOtpForm || isInfoOtpVerified}
                                                  readOnly={viewInfoOtpForm || isInfoOtpVerified}
                                                  aria-label="Name"
                                                  {...register('emailInfo')}/>
                                    <InputError error={errors.emailInfo}/>
                                </InputGroup>

                                <Form.Check type="checkbox" id="agreeInfo" className="mb-4 is-agree">
                                    <Form.Check.Input type="checkbox" className={getInvalidFieldClass('termsInfo')}
                                                    {...register('termsInfo')}
                                                    aria-label="Terms"/>
                                    <Form.Check.Label>
                                        By submitting this form I agree to <a href="#">Terms of Use</a>
                                    </Form.Check.Label>
                                    <InputError error={errors.termsInfo}/>
                                </Form.Check>

                                {!isInfoOtpVerified && viewInfoOtpForm && <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="OTP"
                                        aria-label="OTP"
                                        className={errors['otpInfo'] ? ' is-invalid': ''}
                                        aria-describedby="otpInfo"
                                        type="number"
                                        id={"otpInfo"}
                                        onChange={()=> {removeOtpInfoError()}}
                                        minLength="6"
                                        maxLength="6"/>
                                    <InputError error={errors['otpInfo'] ? errors['otpInfo'].message : null}/>
                                </InputGroup>}
                                {!viewInfoOtpForm && <div className="date-time-wrapper">
                                    <Button variant="primary" id={"otp-button"} className={"otp-button"}
                                            onClick={(e) => onGetOtp(e)}>Get OTP<AppLoader position={"absolute"}/></Button>
                                </div>}
                                {!isInfoOtpVerified && viewInfoOtpForm && <div className="date-time-wrapper">
                                    <Button variant="primary" onClick={(e) => otpSubmit(e)}
                                            className={"otp-button"}>Submit<AppLoader position={"absolute"}/></Button>
                                </div>}
                                {viewInfoOtpForm && <Button variant="primary" className="col-xs-6 submit-info hidden"
                                        id={"infoFormSubmit"} style={{display:'none'}}
                                        type="submit">
                                    Sending Message<AppLoader position={"absolute"}/></Button>}
                            </div>
                        </Form>
                    </Tab>
                    <Tab eventKey="schedule" title="Schedule a tour">
                        <Form onSubmit={handleSubmit(onSave.bind(this))}>
                            <div className="property-schedule-form-wrap">
                                <InputGroup className="mb-3">
                                    <Form.Control name="visitDate" type="datetime-local"
                                                  disabled={viewOtpForm || isOtpVerified}
                                                  readOnly={viewOtpForm || isOtpVerified}
                                                  className={getInvalidFieldClass('visitDate')}
                                                  aria-label="Schedule Date and Time"
                                                  {...register('visitDate')}/>
                                    <InputError error={errors.visitDate}/>
                                </InputGroup>

                                <InputGroup className="mb-2 mt-3">
                                    <Form.Control name="name" type="text" placeholder="Name"
                                                  disabled={viewOtpForm || isOtpVerified}
                                                  readOnly={viewOtpForm || isOtpVerified}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name')}/>
                                    <InputError error={errors.name}/>
                                </InputGroup>

                                <InputGroup className="mb-2">
                                    <Form.Control name="phone" type="tel" placeholder="Phone"
                                                  disabled={viewOtpForm || isOtpVerified}
                                                  readOnly={viewOtpForm || isOtpVerified}
                                                  className={getInvalidFieldClass('phone')}
                                                  aria-label="Phone"
                                                  {...register('phone')}/>
                                    <InputError error={errors.phone}/>
                                </InputGroup>

                                <InputGroup className="mb-2">
                                    <Form.Control name="email" type="email" placeholder="Email"
                                                  disabled={viewOtpForm || isOtpVerified}
                                                  readOnly={viewOtpForm || isOtpVerified}
                                                  className={getInvalidFieldClass('email')}
                                                  aria-label="Name"
                                                  {...register('email')}/>
                                    <InputError error={errors.email}/>
                                </InputGroup>

                                <Form.Check type="checkbox" id="agreeVisit" className="mb-4 is-agree">
                                    <Form.Check.Input type="checkbox" className={getInvalidFieldClass('terms')}
                                                      {...register('terms')}
                                                      aria-label="Name"/>
                                    <Form.Check.Label>
                                        By submitting this form I agree to <a href="#">Terms of Use</a>
                                    </Form.Check.Label>
                                    <InputError error={errors.terms}/>
                                </Form.Check>

                                {!isOtpVerified && viewOtpForm && <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="OTP"
                                        aria-label="OTP"
                                        className={errors['otp'] ? ' is-invalid': ''}
                                        aria-describedby="otp"
                                        type="number"
                                        id={"otp"}
                                        onChange={()=> {removeOtpError()}}
                                        minLength="6"
                                        maxLength="6"/>
                                    <InputError error={errors['otp'] ? errors['otp'].message : null}/>
                                </InputGroup>}

                                <div className="date-time-wrapper">
                                    {!viewOtpForm &&
                                    <Button variant="primary" id={"otp-button"} className={"otp-button"}
                                            onClick={(e) => onGetOtp(e)}>Get OTP<AppLoader position={"absolute"}/></Button>}
                                    {!isOtpVerified && viewOtpForm &&
                                    <Button variant="primary" onClick={(e) => otpSubmit(e)}
                                            className={"otp-button"}>Submit<AppLoader position={"absolute"}/></Button>}
                                    {viewOtpForm && <Button variant="primary" id={"scheduleFormSubmit"} style={{display:'none'}}
                                             className="col-sm-12 submit-tour hidden" type="submit">
                                        Submitting Tour Request<AppLoader position={"absolute"}/></Button>}
                                </div>
                            </div>
                        </Form>
                    </Tab>
                </Tabs>
            }
        </div>
    )
}

export default ContactForm