import React, {Component} from 'react'
import ReactSelect from "../../common/component/ReactSelect";
import * as Constants from "../../common/constants";

export default class PropertyTypeDropdown extends Component {

    getApartmentTypesOptions() {
        if (!this.props.apartmentTypes) {
            return;
        }
        return this.props.apartmentTypes.map(apartmentType => {
            return {
                value: apartmentType.id,
                label: apartmentType.name + " " + Constants.APARTMENT_CONFIG_SUFFIX
            }
        })
    }

    render() {
        const {propertyTypes, setPropertyTypes} = this.props;
        return (
            <div className="search-col">
                <i className="material-icons-two-tone">home</i>
                <ReactSelect id="propertyTypeSelect"
                             title="Property Type"
                             className='select-bedrooms'
                             isMultiple={true}
                             value={propertyTypes}
                             onValueChange={setPropertyTypes}
                             options={this.getApartmentTypesOptions()}/>
            </div>
        )
    }
}