import React, {Component} from "react";
import Select from 'react-select';

export default class ReactSelect extends Component {

    onValueChange(e) {
        const {onValueChange, isMultiple} = this.props;
        const valueJSON = isMultiple ? (Array.isArray(e) ? e : []) : e;
        if (onValueChange) {
            onValueChange(valueJSON);
        }
    }

    render() {
        const {id, title, className, isMultiple, name, isSearchable, options} = this.props;

        return (
            <Select
                id={id}
                name={name}
                title={title}
                onChange={this.onValueChange.bind(this)}
                placeholder={title}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={this.props.value}
                className={'bb-select' + (className ? " " + className : "")}
                closeMenuOnSelect={!isMultiple}
                isSearchable={typeof isSearchable != 'undefined' ? isSearchable : true}
                isMulti={isMultiple}
                options={options}
            />
        )
    }
}