import React, {Component} from 'react'
import RightContent from "../components/RightContent";
import {Card, Dropdown, Table} from "react-bootstrap";
import * as Action from "../../common/api-action";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import Blog from "./Blog";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class BlogList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blogs: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("blog/all", {}, this.populateBlogsData.bind(this));
    }

    populateBlogsData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                blogs: response.blogs
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/blogs/add",
            icon: "",
            text: "Add Blog"
        }];
    }

    getBlog(id) {
        if (!id || isNaN(id) || !this.state.blogs) {
            return null;
        }
        for (let i = 0; i < this.state.blogs.length; i++) {
            if (this.state.blogs[i].id == id) {
                return this.state.blogs[i];
            }
        }
        return null;
    }

    onDeleteBlogClick(id) {
        if (window.confirm("Are you sure you want to delete this Blog?")) {
            Action.callPostApi("blog/delete",
                {id: id}, this.afterDeleteBlog.bind(this, id));
        }
    }

    afterDeleteBlog(id) {
        const blogListAfterDeletion = this.state.blogs.filter(function(blog) { return blog.id !== id; });
        this.setState({blogs: blogListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <Blog mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const blog = this.getBlog(StringUtil.getLastPathParam("/edit/"));
            if (blog) {
                return <Blog mode={mode} blog={blog} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Blogs"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="40%">Title</th>
                                <th width="40%">Link</th>
                                <th width="15%">Created On</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.blogs || this.state.blogs.length == 0 &&
                            <tr><td colSpan={6}>No Blogs found.</td></tr>}
                            {this.state.blogs && this.state.blogs.map((blog) => {
                                return (
                                    <tr key={blog.id}>
                                        <td>{blog.title}</td>
                                        <td>{blog.link}</td>
                                        <td>{blog.createdOn}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/blogs/edit/" + blog.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteBlogClick.bind(this, blog.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}