import React, {useState} from 'react';
import {Card, Col, Form, Row, Modal} from 'react-bootstrap';
import RightContent from "../components/RightContent";
import * as Action from "../../common/api-action";
import * as TimeUtil from "../../app/util/time-util";
import * as AreaUnit from "../../enums/area_unit";
import * as PropertyStatus from "../../enums/property_status";
import {useForm} from 'react-hook-form';
import InputError from "../components/InputError";
import Button from "react-bootstrap/Button";
import * as StringUtil from "../../common/util/string-util";
import * as Constants from "../../common/constants";
import {MapComponent} from "../../common/component/MapComponent";
import FileUpload from "../components/FileUpload";
import * as JsonUtil from "../../common/util/json-util";
import * as FileUtil from "../../common/util/file-util";
import FileUploadMultiple from "../components/FileUploadMultiple";
import CountryDropdown from "../../common/component/CountryDropdown";
import StateDropdown from "../../common/component/StateDropdown";
import DistrictDropdown from "../../common/component/DistrictDropdown";
import LocalityDropdown from "../../common/component/LocalityDropdown";
import AgentDropdown from "../../common/component/AgentDropdown";
import Amenities from "../components/Amenities";
import DeveloperDropdown from "../../common/component/DeveloperDropdown";
import ApartmentList from "./ApartmentList";
import $ from 'jquery';
import Apartment from "./Apartment";
import Specifications from "../components/Specifications";

const Property = (props) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const propertyId = StringUtil.getLastPathParam("/edit/");

    const isAdd = !propertyId || isNaN(propertyId);

    const onSave = (form) => {
        if (!form.slug.match(/^[a-zA-Z0-9-]+$/)) {
            setError("slug", {message: "Links can only have alphabets and hyphen.",  type: 'required'});
            return;
        }
        props.setSavingStatus(1);
        const data = getData(form);
        const propertyIdParam = isAdd ? null : +propertyId;
        if (!propertyIdParam) {
            Action.callPostApi("property/add", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("property/update", data, afterSave, onSaveError, null, true);
        }
    }

    const appendGalleryImageArray = (form, formData) => {
        for (let i = 0; i < form.galleryImages.length; i++) {
            formData.append('galleryImages', form.galleryImages[i], form.galleryImages[i].name);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("bannerFile", form.bannerFile[0]);
        formData.append("mahareraCert", form.mahareraCert[0]);
        formData.append("mahareraCert2", form.mahareraCert2[0]);
        appendGalleryImageArray(form, formData);
        const propertyData = {
            "id": isAdd ? null : +propertyId,
            "name": form.name,
            "address": getAddress(form),
            "propertyDetails": getPropertyDetails(form),
            "agentId": form.agentId.value,
            "developerId": form.developerId.value,
            "status": form.status,
            "slug": form.slug,
            "youtube": form.youtube,
            "hidePrice": $('[name=hidePrice]').is(":checked"),
            "facebook": form.facebook,
            "active": getIntFieldValue("active"),
            "bannerFile": getFileDetails(form, "bannerFile"),
            "mahareraCert": getFileDetails(form, "mahareraCert"),
            "mahareraCert2": getFileDetails(form, "mahareraCert2"),
            "galleryImages": getGalleryImageDetails(form),
            "ordNum": form.ordNum,
            "amenities": getAmenities(form)
        };
        formData.append("data", JSON.stringify(propertyData));
        return formData;
    }

    const getAmenities = (form) => {
        if (!form.amenity && !form.specification) {
            return [];
        }
        let selectedAmenities = [];

        let selectedAmenity = form.amenity;
        if (!form.amenity || !Array.isArray(form.amenity)) {
            selectedAmenity = [];
            if (form.amenity) {
                selectedAmenity.push(form.amenity)
            }
        }
        selectedAmenity.map(amenityId => {
            const amenity = amenities.filter(amenity => amenity.id == amenityId);
            if (amenity && amenity.length > 0) {
                selectedAmenities.push(amenity[0]);
            }
        })

        let selectedSpecification = form.specification;
        if (!form.specification || !Array.isArray(form.specification)) {
            selectedSpecification = [];
            if (form.specification) {
                selectedSpecification.push(form.specification)
            }
        }
        selectedSpecification.map(specificationId => {
            const specification = specifications.filter(specification => specification.id == specificationId);
            if (specification && specification.length > 0) {
                selectedAmenities.push(specification[0]);
            }
        })
        return selectedAmenities;
    }

    const getAddress = (form) => {
        return {
            id: getIntFieldValue("address.id"),
            address: form.address,
            localityId: form.localityId.value,
            districtId: form.districtId.value,
            stateId: form.stateId.value,
            countryId: form.countryId.value,
            latitude: form.latitude,
            longitude: form.longitude,
            pincode: form.pincode
        }
    }

    const getPropertyDetails = (form) => {
        return {
            id: getIntFieldValue("propertyDetail.id"),
            description: form.description,
            totalArea: form.totalArea,
            areaUnit: form.areaUnit,
            towers: form.towers,
            floors: form.floors,
            apartmentsPerFloor: form.apartmentsPerFloor,
            maharera: form.maharera,
            possessionStart: form.possessionStart,
            possessionEnd: form.possessionEnd
        }
    }

    const getFileDetails = (form, name) => {
        if (form[name] && form[name].length > 0) {
            return {
                id: form[name + "Id"],
                fileName: form[name][0].name,
                displayName: form[name][0].name,
                fileType: 0,
                size: form[name][0].size,
                mimetype: form[name][0].type,
                format: FileUtil.getFileFormat(form[name][0].name)
            }
        }
        if (form[name + "Id"] > 0) {
            return getFieldValue(name);
        }
        if (form[name + "Id"] == -1) {
            return {
                id: getFieldValue(name + ".id")
            }
        }
        return null;
    }

    const getGalleryImageDetails = (form) => {
        if (form.galleryImages && form.galleryImages.length > 0) {
            let galleryImages = [];

            for (let i = 0; i < form.galleryImages.length; i++) {
                const galleryImage = form.galleryImages[i];
                galleryImages.push({
                    fileName: galleryImage.name,
                    displayName: galleryImage.name,
                    fileType: 0,
                    size: galleryImage.size,
                    mimetype: galleryImage.type,
                    format: FileUtil.getFileFormat(galleryImage.name)
                })
            }
            return galleryImages;
        }
        return null;
    }

    const getFileFormat = (name) => {
        let fileParts = name.split(".");
        return fileParts[fileParts.length - 1];
    }

    const afterSave = () => {
        props.setSavingStatus(2);
        document.location.href = isAdd ? '/admin/properties' : document.location.href;
    }

    const onSaveError = (data) => {
        props.setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getLatitude = () => {
        return getFieldValue("address.latitude") ?
            getFieldValue("address.latitude") : Constants.DEFAULT_LATITUDE;
    }

    const getLongitude = () => {
        return getFieldValue("address.longitude") ?
            getFieldValue("address.longitude") : Constants.DEFAULT_LONGITUDE;
    }

    const getMarker = () => {
        return {
            latitude: getLatitude(),
            longitude: getLongitude(),
            name: getFieldValue("name") ? getFieldValue("name") : "New Property"
        }
    }

    const onMarkerChange = ({latitude, longitude}) => {
        setValue('latitude', latitude);
        setValue('longitude', longitude);
        document.getElementById('latitude').value = latitude;
        document.getElementById('longitude').value = longitude;
    }

    const getFieldValue = (name) => {
        const {property} = props;
        return JsonUtil.getFieldValue(property, name);
    }

    const getIntFieldValue = (name) => {
        const {property} = props;
        return JsonUtil.getIntFieldValue(property, name);
    }

    const onRemoveBannerImage = () => {
        setValue("bannerFileId", -1);
    }

    const onRemovePropertyPhoto = (id) => {
        let galleryImagesUpdated = galleryImages.filter((image) => {
            return image.id !== id;
        })
        setGalleryImages(galleryImagesUpdated);
        if (!id) {
            return;
        }
        Action.callPostApi("property/deleteFile", {propertyId: +propertyId, fileId: id});
    }

    const [amenities, setAmenities] = useState([]);

    const [specifications, setSpecifications] = useState([]);

    const [showApartmentModal, setShowApartmentModal] = useState(false);
    const [apartment, setApartment] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(getFieldValue("agent.id") != ""
        ? {value: getFieldValue("agent.id")} : null);
    const [selectedDeveloper, setSelectedDeveloper] = useState(getFieldValue("developer.id") != ""
        ? {value: getFieldValue("developer.id")} : null);
    const [selectedCountry, setSelectedCountry] = useState(getFieldValue("address.country.id") != ""
        ? {value: getFieldValue("address.country.id")} : null);
    const [selectedState, setSelectedState] = useState(getFieldValue("address.state.id") != ""
        ? {value: getFieldValue("address.state.id")} : null);
    const [selectedDistrict, setSelectedDistrict] = useState(getFieldValue("address.district.id") != ""
        ? {value: getFieldValue("address.district.id")} : null);
    const [selectedLocality, setSelectedLocality] = useState(getFieldValue("address.locality.id") != ""
        ? {value: getFieldValue("address.locality.id")} : null);

    const changeApartmentModal = (value, apartment) => {
        setShowApartmentModal(value);
        setApartment(apartment);
    }

    const onChangeSelectedCountry = (selectedCountryOption) => {
        if (selectedCountryOption != selectedCountry) {
            setSelectedCountry(selectedCountryOption);
            setValue("countryId", selectedCountryOption);
        }
    }

    const onChangeSelectedState = (selectedStateOption) => {
        if (selectedStateOption != selectedState) {
            setSelectedState(selectedStateOption);
            setValue("stateId", selectedStateOption);
        }
    }

    const onChangeSelectedDistrict = (selectedDistrictOption) => {
        if (selectedDistrictOption != selectedDistrict) {
            setSelectedDistrict(selectedDistrictOption);
            setValue("districtId", selectedDistrictOption);
        }
    }

    const onChangeSelectedLocality = (selectedLocalityOption) => {
        if (selectedLocalityOption != selectedLocality) {
            setSelectedLocality(selectedLocalityOption);
            setValue("localityId", selectedLocalityOption);
        }
    }

    const onChangeSelectedAgent = (selectedAgentOption) => {
        if (selectedAgentOption != selectedAgent) {
            setSelectedAgent(selectedAgentOption);
            setValue("agentId", selectedAgentOption);
        }
    }

    const onChangeSelectedDeveloper = (selectedDeveloperOption) => {
        if (selectedDeveloperOption != selectedDeveloper) {
            setSelectedDeveloper(selectedDeveloperOption);
            setValue("developerId", selectedDeveloperOption);
        }
    }

    const [galleryImages, setGalleryImages] = useState(getFieldValue("galleryImages"));

    return (
        <>
            <RightContent headerText={(propertyId ? "Edit" : "Add") + " Property"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Property Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Slug
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("slug")}
                                                  className={getInvalidFieldClass('slug')}
                                                  aria-label="Slug"
                                                  {...register('slug', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.slug}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Project Status
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Select aria-label="Project Status" name="status"
                                                 defaultValue={getFieldValue("status")} {...register('status')}>
                                        {
                                            PropertyStatus.property_status.map((status) => {
                                                return <option key={status.value}
                                                               value={status.value}>{status.label}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Maharera Number
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="maharera" type="text"
                                                  {...register('maharera', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('maharera')}
                                                  aria-label="Maharera Number"
                                                  defaultValue={getFieldValue("propertyDetail.maharera")}/>
                                    <InputError error={errors.maharera}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="mahareraCert" className="mb-3">
                                <Form.Label column sm="3">
                                    Maharera Certificate
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"mahareraCert"}
                                                value={getFieldValue("mahareraCertId")}
                                                fileTypes={".pdf"}
                                                register={register}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="mahareraCert2" className="mb-3">
                                <Form.Label column sm="3">
                                    Maharera Certificate 2
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"mahareraCert2"}
                                                value={getFieldValue("mahareraCert2Id")}
                                                fileTypes={".pdf"}
                                                register={register}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Project Start Date
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="possessionStart" type="date"
                                                  {...register('possessionStart', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('possessionStart')}
                                                  aria-label="Project Start Date"
                                                  defaultValue={TimeUtil
                                                      .getFormattedDate(getFieldValue("propertyDetail.possessionStart"),
                                                          "yyyy-MM-DD")}/>
                                    <InputError error={errors.possessionStart}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    About the project
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control as="textarea" name={"description"}
                                                  className={getInvalidFieldClass('description')}
                                                  aria-label="About the project"
                                                  maxLength={1000}
                                                  defaultValue={getFieldValue("propertyDetail.description")}
                                                  {...register('description', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.description}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Total Area
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="totalArea" type="number"
                                                  defaultValue={getFieldValue("propertyDetail.totalArea")}
                                                  step=".1"
                                                  min={0} {...register('totalArea', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('totalArea')}
                                                  aria-label="Total Area"/>
                                    <InputError error={errors.totalArea}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Area Unit
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Select aria-label="Area Unit" name="areaUnit"
                                                 {...register('areaUnit')}
                                                 defaultValue={getFieldValue("propertyDetail.areaUnit")}>
                                        {
                                            AreaUnit.area_units.map((areaUnit) => {
                                                return <option key={areaUnit.value}
                                                               value={areaUnit.value}>{areaUnit.label}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Number of towers
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="towers" type="number"
                                                  defaultValue={getFieldValue("propertyDetail.towers")}
                                                  min={0} {...register('towers')}
                                                  className={getInvalidFieldClass('towers')}
                                                  aria-label="Number of Towers"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Number of floors
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="floors" type="number"
                                                  defaultValue={getFieldValue("propertyDetail.floors")}
                                                  min={0} {...register('floors')}
                                                  className={getInvalidFieldClass('floors')}
                                                  aria-label="Number of floors"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Apartments / Floor
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="apartmentsPerFloor" type="number"
                                                  defaultValue={getFieldValue("propertyDetail.apartmentsPerFloor")}
                                                  min={0} {...register('apartmentsPerFloor')}
                                                  className={getInvalidFieldClass('apartmentsPerFloor')}
                                                  aria-label="Apartments / floors"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Possession Date
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="possessionEnd" type="date"
                                                  {...register('possessionEnd', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('possessionEnd')}
                                                  aria-label="Possession Date"
                                                  defaultValue={TimeUtil
                                                      .getFormattedDate(getFieldValue("propertyDetail.possessionEnd"),
                                                          "yyyy-MM-DD")}/>
                                    <InputError error={errors.possessionEnd}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Hide Price
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Check name="hidePrice" type="switch"
                                                aria-label="Hide Price"
                                                defaultChecked={getFieldValue("hidePrice")}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Property Address
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Address
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="address" type="text"
                                                  className={getInvalidFieldClass('address')}
                                                  aria-label="Address" defaultValue={getFieldValue("address.address")}
                                                  {...register('address', {required: 'Address cannot be empty'})}/>
                                    <InputError error={errors.address}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Country
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <CountryDropdown className={getInvalidFieldClass('countryId')}
                                                     selectedCountry={selectedCountry}
                                                     control={control}
                                                     setSelectedCountry={onChangeSelectedCountry}/>
                                    <InputError error={errors.countryId}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    State
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <StateDropdown className={getInvalidFieldClass('stateId')}
                                                   selectedState={selectedState}
                                                   selectedCountry={selectedCountry}
                                                   control={control}
                                                   setSelectedState={onChangeSelectedState}/>
                                    <InputError error={errors.stateId}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    City
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <DistrictDropdown className={getInvalidFieldClass('districtId')}
                                                      selectedDistrict={selectedDistrict}
                                                      selectedState={selectedState}
                                                      control={control}
                                                      setSelectedDistrict={onChangeSelectedDistrict}/>
                                    <InputError error={errors.districtId}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Locality
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <LocalityDropdown className={getInvalidFieldClass('localityId')}
                                                      selectedLocality={selectedLocality}
                                                      selectedDistrict={selectedDistrict}
                                                      control={control}
                                                      setSelectedLocality={onChangeSelectedLocality}/>
                                    <InputError error={errors.localityId}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Pincode
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="pincode" type="text"
                                                  defaultValue={getFieldValue("address.pincode")}
                                                  className={getInvalidFieldClass('pincode')}
                                                  aria-label="Pincode"
                                                  {...register('pincode', {required: 'Pincode cannot be empty'})}/>
                                    <InputError error={errors.pincode}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Map Location
                                </Form.Label>
                                <Col sm="9" className="form-types has-mapinit">
                                    <input type="hidden" name="latitude" id="latitude"
                                           defaultValue={getLatitude()} {...register('latitude')}/>
                                    <input type="hidden" name="longitude" id="longitude"
                                           defaultValue={getLongitude()} {...register('longitude')}/>
                                    <MapComponent marker={getMarker()}
                                                  onMarkerChange={onMarkerChange.bind(this)}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Media
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Youtube URL
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="youtube" type="text" defaultValue={getFieldValue("youtube")}
                                                  className={getInvalidFieldClass('youtube')}
                                                  aria-label="Youtube URL"
                                                  {...register('youtube')}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Facebook URL
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="facebook" type="text" defaultValue={getFieldValue("facebook")}
                                                  className={getInvalidFieldClass('facebook')}
                                                  aria-label="Facebook URL"
                                                  {...register('facebook')}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="bannerFile" className="mb-3">
                                <Form.Label column sm="3">
                                    Property Banner
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"bannerFile"}
                                                value={getFieldValue("bannerFileId")}
                                                onRemoveClick={onRemoveBannerImage}
                                                register={register}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="galleryImages" className="mb-3">
                                <Form.Label column sm="3">
                                    Property Photos
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <FileUploadMultiple id={"galleryImages"}
                                                        values={galleryImages}
                                                        onRemoveClick={onRemovePropertyPhoto}
                                                        register={register}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Agent Contact Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Agent
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <AgentDropdown className={getInvalidFieldClass('agentId')}
                                                   selectedAgent={selectedAgent}
                                                   control={control}
                                                   setSelectedAgent={onChangeSelectedAgent}/>
                                    <InputError error={errors.agentId}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Developer Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Developer
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <DeveloperDropdown className={getInvalidFieldClass('developerId')}
                                                       selectedDeveloper={selectedDeveloper}
                                                       control={control}
                                                       setSelectedDeveloper={onChangeSelectedDeveloper}/>
                                    <InputError error={errors.developerId}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    {!isAdd &&
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Apartments
                                <Button size="sm" variant="outline-primary" className="btn-location"
                                        onClick={() => changeApartmentModal(true)}>
                                    <span>Add Apartments</span>
                                </Button>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <ApartmentList apartmentTypes={props.apartmentTypes}
                                           apartments={getFieldValue("apartments")}
                                           changeApartmentModal={changeApartmentModal}
                                           setSavingStatus={props.setSavingStatus.bind(this)}/>
                        </Card.Body>
                    </Card>}

                    <Amenities register={register}
                               setAmenities={setAmenities}
                               selectedAmenities={getFieldValue("amenities")}/>

                    <Specifications register={register}
                               setSpecifications={setSpecifications}
                               selectedSpecifications={getFieldValue("amenities")}/>

                    <div className="form-group action-btn-wrapper">
                        <Button type="submit">Save</Button>
                        <Button href={"/admin/properties"} variant="dark">Cancel</Button>
                    </div>
                </form>
            </RightContent>
            <Modal
                size="xl"
                show={showApartmentModal}
                onHide={() => changeApartmentModal(false)}
                dialogClassName="apartment-modal"
            >
                <div className="container">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Apartment Details
                        </Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <Apartment apartment={apartment} apartmentTypes={props.apartmentTypes}
                               setSavingStatus={props.setSavingStatus} propertyId={getFieldValue("id")}/>;
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Property;