import React, {Component} from 'react';
import {Dropdown, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import * as ArrayUtil from "../../common/util/array-util";
import * as AreaUnit from "../../enums/area_unit";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";
import {getFormattedPrice} from "../../app/util/currency-util";
import * as Constants from "../../common/constants";

export default class ApartmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apartments: props.apartments,
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/apartments/add",
            icon: "",
            text: "Add Apartment"
        }];
    }

    getApartment(id) {
        if (!id || isNaN(id) || !this.state.apartments) {
            return null;
        }
        for (let i = 0; i < this.state.apartments.length; i++) {
            if (this.state.apartments[i].id == id) {
                return this.state.apartments[i];
            }
        }
        return null;
    }

    onDeleteApartmentClick(id) {
        if (window.confirm("Are you sure you want to delete this Apartment?")) {
            Action.callPostApi("apartment/delete",
                {id: id}, this.afterDeleteApartment.bind(this, id));
        }
    }

    onEditApartmentClick(id) {
        this.props.changeApartmentModal(true, this.getApartment(id));
    }

    afterDeleteApartment(id) {
        const apartmentListAfterDeletion = this.state.apartments.filter(function (apartment) {
            return apartment.id !== id;
        });
        this.setState({apartments: apartmentListAfterDeletion});
    }

    render() {
        if (!Session.isValidManagerUser()) {
            return <NoAccess/>;
        }
        return (

            <Table className="has-action" striped bordered hover>
                <thead>
                <tr>
                    <th width="15%">Rera Carpet Area</th>
                    <th width="15%">Usable Carpet Area</th>
                    <th width="10%">Configuration</th>
                    <th width="5%">Bathrooms</th>
                    <th width="5%">Balconies</th>
                    <th width="5%">Parking</th>
                    <th width="10%">Price</th>
                    <th width="30%">Description</th>
                </tr>
                </thead>
                <tbody>
                {!this.state.apartments || this.state.apartments.length == 0 &&
                <tr>
                    <td colSpan={6}>No Apartments found.</td>
                </tr>}
                {this.state.apartments && this.state.apartments.map((apartment) => {
                    return (
                        <tr key={apartment.id}>
                            <td>{apartment.carpetArea + " " + ArrayUtil.getLabel(AreaUnit.area_units, apartment.areaUnit)}</td>
                            <td>{apartment.reraCarpetArea + " " + ArrayUtil.getLabel(AreaUnit.area_units, apartment.areaUnit)}</td>
                            <td>{ArrayUtil.getFieldValueForId(apartment.typeId, "name", this.props.apartmentTypes)
                            + " " + Constants.APARTMENT_CONFIG_SUFFIX}</td>
                            <td>{apartment.bathrooms}</td>
                            <td>{apartment.balcony}</td>
                            <td>{apartment.parking}</td>
                            <td>{getFormattedPrice(apartment.price)}</td>
                            <td>{apartment.description}</td>
                            <td>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="outline-dark" id="more-action">
                                        <span className="material-icons-sharp">more_horiz</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu renderOnMount="true">
                                        <Dropdown.Item onClick={this.onEditApartmentClick.bind(this, apartment.id)}>
                                            <span className="material-icons-sharp">mode_edit</span>
                                            Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onDeleteApartmentClick.bind(this, apartment.id)}>
                                            <span className="material-icons-sharp">delete</span>
                                            Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )
    }
}