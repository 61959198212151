import React, {Component} from 'react'
import '../../app/styles/admin.scss';

import Header from "../components/Header";
import LeftNavBar from "../components/LeftNavBar";
import PropertyList from "./PropertyList";
import Organization from "./Organization";
import StaticPages from "./StaticPages";
import * as Session from '../../app/util/session'
import NoAccess from "./NoAccess";
import MessageAlerts from '../../common/component/MessageAlerts';
import UserList from "./UserList";
import Amenities from "./Amenities";
import ReviewList from "./ReviewList";
import ApartmentTypes from "./ApartmentTypes";
import DeveloperList from "./DeveloperList";
import Countries from "./Countries";
import StateList from "./StateList";
import DistrictList from "./DistrictList";
import LocalityList from "./LocalityList";
import EnquiryList from './EnquiryList';
import AdminPageNotFound from "./AdminPageNotFound";
import BlogList from "./BlogList";
import Specifications from './Specifications';
import Faqs from "./Faqs";


export default class AdminHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingStatus: 0
        }
    }

    setSavingStatus(value) {
        this.setState({savingStatus: value});
    }

    getLinks() {
        const links = [];
        if (Session.isValidManagerUser()) {
            links.push(this.getPropertyLink());
        }
        if (Session.isValidAdminUser()) {
            links.push(this.getDeveloperLink());
            links.push(this.getStaticPageLink());
            links.push(this.getBlogLink());
            links.push(this.getReviewsPageLink());
            links.push(this.getManageUserLink());
        }
        if (Session.isValidManagerUser()) {
            links.push(this.getLeadsPageLink());
        }
        if (Session.isValidAdminUser()) {
            links.push(this.getOrganizationLink());
            links.push(this.getSpecificationsPageLink());
            links.push(this.getAmenitiesPageLink());
            links.push(this.getApartmentTypesPageLink());
            links.push(this.getFAQPageLink());
            links.push(this.getCountriesPageLink());
            links.push(this.getStatesPageLink());
            links.push(this.getDistrictsPageLink());
            links.push(this.getLocalitiesPageLink());

        }
        return links;
    }

    getOrganizationLink() {
        return {
            href: "/admin/organization",
            className: this.props.type === 'org' ? "active" : "",
            icon: "corporate_fare",
            text: "Organisation"
        };
    }

    getManageUserLink() {
        return {
            href: "/admin/users",
            className: this.props.type === 'users' ? "active" : "",
            icon: "group",
            text: "Manage Users"
        };
    }

    getLeadsLink() {
        return {
            href: "/admin/leads",
            className: this.props.type === 'leads' ? "active" : "",
            icon: "leaderboard",
            text: "Leads"
        };
    }

    getGlobalSettingsLink() {
        return {
            href: "/admin/settings",
            className: this.props.type === 'settings' ? "active" : "",
            icon: "settings_suggest",
            text: "Global Settings"
        };
    }

    getPropertyLink() {
        return {
            href: "/admin/properties",
            className: this.props.type === 'property' ? "active" : "",
            icon: "real_estate_agent",
            text: "Properties"
        };
    }

    getApartmentLink() {
        return {
            href: "/admin/apartments",
            className: this.props.type === 'apartment' ? "active" : "",
            icon: "",
            text: "Apartments",
            liClass: "sub-link"
        };
    }

    getDeveloperLink() {
        return {
            href: "/admin/developers",
            className: this.props.type === 'developer' ? "active" : "",
            icon: "",
            text: "Developers",
            liClass: "sub-link"
        };
    }

    getStaticPageLink() {
        return {
            href: "/admin/staticpages",
            className: this.props.type === 'static' ? "active" : "",
            icon: "article",
            text: "Static Pages"
        };
    }

    getBlogLink() {
        return {
            href: "/admin/blogs",
            className: this.props.type === 'blog' ? "active" : "",
            icon: "article",
            text: "Blogs"
        };
    }

    getSpecificationsPageLink() {
        return {
            href: "/admin/specifications",
            className: this.props.type === 'specifications' ? "active" : "",
            text: "Specifications",
            liClass: "sub-link"
        };
    }


    getAmenitiesPageLink() {
        return {
            href: "/admin/amenities",
            className: this.props.type === 'amenities' ? "active" : "",
            text: "Amenities",
            liClass: "sub-link"
        };
    }

    getReviewsPageLink() {
        return {
            href: "/admin/reviews",
            className: this.props.type === 'reviews' ? "active" : "",
            icon: "reviews",
            text: "Reviews"
        };
    }

    getApartmentTypesPageLink() {
        return {
            href: "/admin/apartmentTypes",
            className: this.props.type === 'apartmentTypes' ? "active" : "",
            text: "Apartment Types",
            liClass: "sub-link"
        };
    }

    getFAQPageLink() {
        return {
            href: "/admin/faqs",
            className: this.props.type === 'faq' ? "active" : "",
            text: "FAQs",
            liClass: "sub-link"
        };
    }

    getCountriesPageLink() {
        return {
            href: "/admin/countries",
            className: this.props.type === 'countries' ? "active" : "",
            text: "Countries",
            liClass: "sub-link"
        };
    }

    getStatesPageLink() {
        return {
            href: "/admin/states",
            className: this.props.type === 'states' ? "active" : "",
            text: "States",
            liClass: "sub-link"
        };
    }

    getDistrictsPageLink() {
        return {
            href: "/admin/districts",
            className: this.props.type === 'districts' ? "active" : "",
            text: "Districts",
            liClass: "sub-link"
        };
    }

    getLocalitiesPageLink() {
        return {
            href: "/admin/localities",
            className: this.props.type === 'localities' ? "active" : "",
            text: "Localities",
            liClass: "sub-link"
        };
    }

    getLeadsPageLink() {
        return {
            href: "/admin/enquiry",
            className: this.props.type === 'enquiry' ? "active" : "",
            icon: "leaderboard",
            text: "Leads",
        };
    }

    renderPage() {
        const {type, mode, apartmentTypes} = this.props;
        if (type === 'org') {
            return <Organization setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'property') {
            return <PropertyList mode={mode} apartmentTypes={apartmentTypes}
                                 setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'developer') {
            return <DeveloperList mode={mode}
                                  setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'users') {
            return <UserList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'leads') {
            return <EnquiryList mode={mode} apartmentTypes={apartmentTypes}
                                setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'static') {
            return <StaticPages setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'faq') {
            return <Faqs setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'specifications') {
            return <Specifications setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'amenities') {
            return <Amenities setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'reviews') {
            return <ReviewList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'apartmentTypes') {
            return <ApartmentTypes setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'countries') {
            return <Countries setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'states') {
            return <StateList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'districts') {
            return <DistrictList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'localities') {
            return <LocalityList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        } else if (type === 'blogs') {
            return <BlogList mode={mode} setSavingStatus={this.setSavingStatus.bind(this)}/>;
        }
        return <AdminPageNotFound organization={this.props.organization}/>
    }

    getSavingMessage() {
        const {savingStatus} = this.state;
        if (savingStatus == 1) {
            return "Saving...";
        } else if (savingStatus == 2) {
            setTimeout(() => this.setSavingStatus(0), 2000);
            return "Success"
        } else if (savingStatus == 3) {
            setTimeout(() => this.setSavingStatus(0), 2000);
            return "Failure"
        }
    }

    getSavingMessageType() {
        const {savingStatus} = this.state;
        if (savingStatus == 1) {
            return "warning";
        } else if (savingStatus == 2) {
            return "success"
        } else if (savingStatus == 3) {
            return "danger"
        }
    }

    getSavingDetailedMessage() {
        const {savingStatus} = this.state;
        if (savingStatus == 1) {
            return "";
        } else if (savingStatus == 2) {
            return "Changes saved successfully."
        } else if (savingStatus == 3) {
            return "There was some error, please try again later"
        }
    }

    render() {
        const {type, organization} = this.props;
        if (!Session.isValidBackendUser()) {
            return <NoAccess/>;
        }
        return (
            <>
                <Header organization={organization}/>
                <LeftNavBar type={type} links={this.getLinks()}/>
                {this.renderPage()}
                {this.getSavingMessageType() && <MessageAlerts position="fixed" type={this.getSavingMessageType()} message={this.getSavingMessage()}
                                close={true} onClose={() => {this.setSavingStatus(0)}}>
                    {this.getSavingDetailedMessage() && <p>{this.getSavingDetailedMessage()}</p>}
                </MessageAlerts>}
            </>
        )
    }
}