import React, {useState} from 'react';
import {Badge, Button, Dropdown, Modal} from 'react-bootstrap';
import '../styles/header.scss'
import * as Session from "../util/session";
import * as Constants from "../../common/constants";
import {Helmet} from "react-helmet";
import $ from 'jquery';

const Header = (props) => {
    const [locationShow, setLocationShow] = useState(false);
    const [searchShow, setSearchShow] = useState(false);

    React.useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.main_header');
        const scrollTop = window.scrollY;
        scrollTop >= 50 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    // function showNavBar() {
    //     $('#navbar').toggleClass('show');
    //     $('#drawer').toggleClass('active');
    // }

    function onLogout() {
        Session.logoutUser();
        document.location.href = '/';
    }

    function onCitySelect(cityId) {
        props.onUpdateCurrentCity(cityId);
        setLocationShow(false);
    }

    function isCityActive(cityID) {
        return Session.getCurrentCity() == cityID ? "selected" : "";
    }

    function getActiveCity() {
        return props.cities.filter(city => city.id == Session.getCurrentCity());
    }

    function shwoMap(){
        $('.map-wrapper').toggleClass('active');
        $('body').toggleClass('stop-scroll');
    }

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={Constants.serverUrl + "file/get/" + props.favIconId} />
            </Helmet>
            <header className="main_header">
                <div id="recaptcha-container"></div>
                <div className="container">
                    <nav className="navbar_wrapper navbar">
                        <a className="brand_logo navbar-brand" href="/">
                            <img src={Constants.serverUrl + "file/get/" + props.logoFileId} alt="logo"/>
                        </a>

                        <div className="navbar_collapse" id="navbar">
                            {
                                (document.location.pathname == "/" || document.location.pathname == '/searchresult') &&
                                <Button size="sm" variant="outline-light" className="btn-location" onClick={() => setLocationShow(true)}>
                                    <span>{getActiveCity() ? getActiveCity()[0].name : "Select Location"}</span> <i className="material-icons">arrow_drop_down</i>
                                </Button>
                            }

                            <span>
                                {
                                    (document.location.pathname == "/searchresult") &&
                                    <Button className="is-rounded btn-search" id="searchMobile" variant="outline-light" onClick={shwoMap}>
                                        <i className="material-icons-outlined">map</i>
                                    </Button>
                                }

                                {/*{!Session.isValidUser() &&*/}
                                    {/*<Button className="btn-register"size="sm" variant="outline-light" href="/signup">*/}
                                        {/*<i className="material-icons">person</i> <span>Register</span>*/}
                                    {/*</Button>*/}
                                {/*}*/}

                                {/*{Session.isValidUser() ?*/}

                                    {/*<Dropdown align="end" className="user-dropdown">*/}
                                        {/*<Dropdown.Toggle className="is-rounded" variant="outline-light" id="dropdown-basic">*/}
                                            {/*<span className="material-icons-sharp">person</span>*/}
                                        {/*</Dropdown.Toggle>*/}

                                        {/*<Dropdown.Menu renderOnMount="true">*/}
                                            {/*/!*<Dropdown.Item href="#/action-1"><i className="material-icons-outlined">account_balance_wallet</i> My Account</Dropdown.Item>*!/*/}
                                            {/*<Dropdown.Item href="/admin"><i className="material-icons-outlined">admin_panel_settings</i> Admin</Dropdown.Item>*/}
                                            {/*<Dropdown.Item onClick={onLogout}><i className="material-icons-outlined">logout</i> Logout</Dropdown.Item>*/}
                                        {/*</Dropdown.Menu>*/}
                                    {/*</Dropdown> */}

                                    {/*:*/}

                                    {/*<Button size="sm" variant="outline-light" href="/login">*/}
                                        {/*<i className="material-icons">login</i> <span>Login</span>*/}
                                    {/*</Button>*/}
                                {/*}*/}
                            </span>
                        </div>
                    </nav>
                </div>
            </header>
            
            <Modal
                size="lg"
                show={locationShow}
                onHide={() => setLocationShow(false)}
                dialogClassName="location-modal">
                <div className="container">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Please setup location
                            <p>Help us with your location so we can serve you better.</p>
                        </Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    {/*<div className="modal-search">*/}
                        {/*<div className="container">*/}
                            {/*<Form>*/}
                                {/*<Form.Control/>*/}
                                {/*<Button variant="primary">*/}
                                    {/*<i className="fa fa-search" aria-hidden="true"></i>*/}
                                    {/*<span>Search</span>*/}
                                {/*</Button>*/}
                            {/*</Form>*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    <div className="container">
                        <div className="default-cities">
                            <Badge>Popular Cities</Badge>
                            <ul>
                                {props.cities.length && props.cities.map((city) => {
                                    return (
                                        <li key={city.id} onClick={onCitySelect.bind(this, city.id)}
                                            className={isCityActive(city.id)}>
                                            <img src={"/images/city/" + city.name.replaceAll(" ", "_") + ".svg"}
                                                 alt={city.name}/>
                                            <span>{city.name}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        {/*<div className="suburbs-cities">*/}
                            {/*<Badge>Suburbs</Badge>*/}
                            {/*<ul>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Malad</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Kandivali</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Goregaon</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Jogeshwari</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Andheri</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Villeparle</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Malad</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Kandivali</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Goregaon</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Jogeshwari</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Andheri</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Villeparle</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Malad</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Kandivali</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Goregaon</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Jogeshwari</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Andheri</Button>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                    {/*<Button as="a" variant="outline-dark">Villeparle</Button>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Header;
