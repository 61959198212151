import React, {Component} from 'react'
import RightContent from "../components/RightContent";
import {Card, Table} from "react-bootstrap";
import {Dropdown} from "react-bootstrap";
import * as Action from "../../common/api-action";
import Property from "./Property";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class PropertyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            properties: null
        }
    }

    componentDidMount() {
        Action.callPostApi("property", {}, this.onFetchProperties.bind(this));
    }

    onFetchProperties(data) {
        this.setState({properties: data.properties})
    }

    getHeaderButtons() {
        return [{
            href: "/admin/properties/add",
            icon: "",
            text: "Add Property"
        }];
    }

    onUpdateStatus(id, active) {
        const property = this.getProperty(id);
        if (property) {
            property.active = active;
            this.setState({properties: this.state.properties});
        }
    }

    getProperty(id) {
        if (!id || isNaN(id) || !this.state.properties) {
            return null;
        }
        for (let i = 0; i < this.state.properties.length; i++) {
            if (this.state.properties[i].id == id) {
                return this.state.properties[i];
            }
        }
        return null;
    }

    changePropertyStatus(id, active) {
        Action.callPostApi("property/active",
            {id: id, active: active}, this.onUpdateStatus.bind(this, id, active));
    }

    onDeletePropertyClick(id) {
        if (window.confirm("Are you sure you want to delete this property? This action cannot be reverted.\nIf you are not sure, you can Hide the property.")) {
            this.deleteProperty(id);
        }
    }

    deleteProperty(id) {
        Action.callPostApi("property/delete",
            {id: id}, this.afterDeleteProperty.bind(this, id));
    }

    afterDeleteProperty(id) {
        const propertyListAfterDeletion = this.state.properties.filter(function(property) { return property.id !== id; });
        this.setState({properties: propertyListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus, apartmentTypes} = this.props;

        if (!Session.isValidManagerUser()) {
            return <NoAccess/>;
        }
        if (!this.state.properties) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <Property mode={mode} apartmentTypes={apartmentTypes} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const property = this.getProperty(StringUtil.getLastPathParam("/edit/"));
            if (property) {
                return <Property mode={mode} property={property}
                                 apartmentTypes={apartmentTypes}
                                 setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Properties"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>

                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="40%">Name</th>
                                <th width="15%">Location</th>
                                <th width="15%">Possession</th>
                                <th width="15%">Created On</th>
                                <th width="10%">Status</th>
                                <th width="5%">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.properties || this.state.properties.length == 0 &&
                            <tr><td colSpan={6}>No Properties found.</td></tr>}
                            {this.state.properties && this.state.properties.map((property) => {
                                return (
                                    <tr key={property.id}>
                                        <td>{property.name}</td>
                                        <td>{property.address.district.name}</td>
                                        <td>{property.propertyDetail.possessionStart}</td>
                                        <td>{property.createdOn}</td>
                                        <td>{property.active == 2 ? "Featured" : (property.active == 1 ? "Active" : "Inactive")}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item target={"_blank"} href={"/property/" + property.slug}>
                                                        <span className="material-icons-sharp">remove_red_eye</span>
                                                        View</Dropdown.Item>
                                                    <Dropdown.Item href={"/admin/properties/edit/" + property.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    {(property.active == 0 || property.active == 1) &&
                                                    <Dropdown.Item onClick={() => {
                                                        this.changePropertyStatus(property.id, property.active == 0 ? 1 : 0)
                                                    }}>
                                                        <span className="material-icons-outlined">
                                                            {property.active == 0 ? 'published_with_changes' : 'unpublished'}
                                                        </span>
                                                        {property.active == 0 ? 'Publish' : 'Hide'}
                                                    </Dropdown.Item>}
                                                    {(property.active == 2 || property.active == 1) &&
                                                    <Dropdown.Item onClick={() => {
                                                        this.changePropertyStatus(property.id, property.active == 2 ? 1 : 2)
                                                    }}>
                                                        <span className="material-icons-outlined">
                                                            {property.active == 2 ? 'star' : 'star'}
                                                        </span>
                                                        {property.active == 2 ? 'Normal' : 'Premium'}
                                                    </Dropdown.Item>}
                                                    <Dropdown.Item onClick={this.onDeletePropertyClick.bind(this, property.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}