import React from 'react';
import * as Action from "../../common/api-action";
import {LoginSocialFacebook, LoginSocialGoogle,} from 'reactjs-social-login'

const SocialLogin = (props) => {
    const onGoogleLogin = async (provider, response) => {
        if (!response.email) {
            props.onError({field: 'email', message: 'Unable to verify user'});
        } else {
            const signedUser = await Action.callApiSync("google/authentication",
                {token: response.access_token});

            console.log("Signed User" + signedUser);
            if (signedUser.type === "success") {
                props.afterLogin(signedUser);
            }
        }
    }

    const onFacebookLogin = async (provider, response) => {
        const signedUser = await Action.callApiSync("facebook/authentication", {token: response.accessToken});
        console.log("Signed User" + signedUser);
        if (signedUser.type === "success") {
            props.afterLogin(signedUser);
        }
    }

    return (
        <div className="social_list mb-4">
            {/*<LoginSocialFacebook*/}
                {/*appId={process.env.REACT_APP_FACEBOOK_APP_ID}*/}
                {/*onResolve={({ provider, data }) => {*/}
                    {/*onFacebookLogin(provider, data);*/}
                {/*}}*/}
                {/*onReject={(err) => {*/}
                    {/*console.log(err)*/}
                {/*}}>*/}
                {/*<span className="login-btns facebook_bg"><i className="fa fa-facebook"></i></span>*/}
            {/*</LoginSocialFacebook>*/}
            <LoginSocialGoogle
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                scope="openid profile email"
                onResolve={({provider, data}) => {
                    onGoogleLogin(provider, data);
                }}
                onReject={(err) => {
                    console.log(err)
                }}>
                <span className="login-btns google_bg"><i className="fa fa-google"></i></span>
            </LoginSocialGoogle>

            {/*<a href="#" className="twitter_bg"><i className="fa fa-twitter"></i></a>*/}
            {/*<a href="#" className="linkedin_bg"><i className="fa fa-linkedin"></i></a>*/}
        </div>
    )
}

export default SocialLogin