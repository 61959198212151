import { Button } from "react-bootstrap";

export default function PageNotFound() {
    return (
        <div className="not-found-wrapper">
            <span>404</span>
            <p>Not Found</p>
            <div>
                <Button variant="dark" href={"/"}>Take Me Back</Button>
            </div>
        </div>
    )
}
