import React from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import InputError from "../components/InputError";
import {useForm} from 'react-hook-form';
import * as StringUtil from "../../common/util/string-util";
import FileUpload from "../components/FileUpload";
import * as JsonUtil from "../../common/util/json-util";
import * as FileUtil from "../../common/util/file-util";
import FormCKEditor from "../../common/component/FormCKEditor";

const Review = (props) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const reviewId = StringUtil.getLastPathParam("/edit/");

    const isAdd = !reviewId || isNaN(reviewId);

    const onSave = (form) => {
        props.setSavingStatus(1);
        const data = getData(form);
        const reviewIdParam = isAdd ? null : +reviewId;
        if (!reviewIdParam) {
            Action.callPostApi("user-reviews/add", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("user-reviews/update", data, afterSave, onSaveError, null, true);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("photo", form.photo[0]);
        const reviewData = {
            "id": isAdd ? null : +reviewId,
            "name": form.name,
            "title": form.title,
            "description": form.description,
            "photo": getPhotoDetails(form)
        };
        formData.append("data", JSON.stringify(reviewData));
        return formData;
    }

    const getPhotoDetails = (form) => {
        if (form.photo && form.photo.length > 0) {
            return {
                id: form.photoId,
                fileName: form.photo[0].name,
                displayName: form.photo[0].name,
                fileType: 1,
                size: form.photo[0].size,
                mimetype: form.photo[0].type,
                format: FileUtil.getFileFormat(form.photo[0].name)
            }
        }
        if (form.photoId > 0) {
            return getFieldValue("photo");
        }
        if (form.photoId == -1) {
            return {
                id: getFieldValue("photo.id")
            }
        }
        return null;
    }

    const afterSave = () => {
        props.setSavingStatus(2);
        document.location.href = isAdd ? '/admin/reviews' : document.location.href;
    }

    const onSaveError = (data) => {
        props.setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        const {review} = props;
        return JsonUtil.getFieldValue(review, name);
    }

    return (
        <>
            <RightContent headerText={(reviewId ? "Edit" : "Add") + " Review"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Review Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Title
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="title" type="text" defaultValue={getFieldValue("title")}
                                                  aria-label="Title"
                                                  className={getInvalidFieldClass('title')}
                                                  {...register('title', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.title}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Comment
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <FormCKEditor id={'description'}
                                                  name={'description'}
                                                  title={'Comment'}
                                                  maxLength={1000}
                                                  defaultHtml={getFieldValue("description")}
                                                  onChange={(value) => setValue('description', value)}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="photo" className="mb-3">
                                <Form.Label column sm="3">
                                    Photo
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"photo"}
                                                value={getFieldValue("photoId")}
                                                register={register}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <div className="form-group action-btn-wrapper">
                        <Button type="submit">Save</Button>
                        <Button href={"/admin/reviews"} variant="dark">Cancel</Button>
                    </div>
                </form>
            </RightContent>
        </>
    )
}

export default Review;