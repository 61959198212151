import React from "react";
import Select from 'react-select';
import { Controller } from "react-hook-form";

const FormReactSelect = ({isMultiple, id, title, className, name, isSearchable,
                             options, control, onChange, valueJSON, defaultValue}) => {
    const getSelectedValue = () => {
        if (!isMultiple) {
            for (let i = 0; i < options.length; i++) {
                if (valueJSON && valueJSON.value === options[i].value) {
                    return options[i];
                }
            }
        } else {
            let selectedOption = [];
            for (let i = 0; i < valueJSON.length; i++) {
                for (let j = 0; j < options.length; j++) {
                    if (valueJSON[i].value === options[j].value) {
                        selectedOption.push(options[j]);
                    }
                }
            }
            return selectedOption;
        }
    }

    return (
        <Controller
            name={name || id}
            control={control}
            rules={{ required: title + ' cannot be empty' }}
            render={({ field }) => (
                <Select id={id}
                        name={name}
                        title={title}
                        onChange={(options) =>
                        {
                            const valueJSON = isMultiple ? (Array.isArray(options) ? options : []) : options;
                            field.onChange(valueJSON);
                            onChange && onChange(valueJSON) ;
                        }}
                        placeholder={title}
                        defaultValue={defaultValue}
                        value={getSelectedValue()}
                        className={'bb-select' + (className ? " " + className : "")}
                        closeMenuOnSelect={!isMultiple}
                        isSearchable={typeof isSearchable != 'undefined' ? isSearchable : true}
                        isMulti={isMultiple}
                        options={options}/>
            )}/>
    )
}
export default FormReactSelect;