import React, {useState} from 'react'
import * as Action from "../../common/api-action";
import AppLoader from "../../common/component/AppLoader";
import {Card, Col, Form, Row, Tab, Tabs} from "react-bootstrap";

const Specifications = (props) => {
    const [loading, setLoading] = useState(true);
    const [specifications, setSpecifications] = useState([]);

    const populateAmenityTypeData = (response) => {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            setSpecifications(response.amenityTypes);
            props.setSpecifications(getSpecificationAmenities(response.amenityTypes));
            setLoading(false);
        }
    }

    const getSpecificationAmenities = (amenityTypes) => {
        let specificationAmenities = [];
        amenityTypes.map(amenityType => {
            specificationAmenities.push(...amenityType.amenities);
        })
        return specificationAmenities;
    }

    const isSpecificationSelected = (id) => {
        for (let i = 0; props.selectedSpecifications && i < props.selectedSpecifications.length; i++) {
            if (props.selectedSpecifications[i].id === id) {
                return true;
            }
        }
        return false;
    }

    React.useEffect(() => {
        Action.callPostApi("amenity-type", {}, populateAmenityTypeData);
    }, []);

    return (
        <Card className="specification-card-wrapper">
            <Card.Header>
                <Card.Title>
                    Specifications
                </Card.Title>
            </Card.Header>
            {loading || !specifications || specifications.length == 0 ?
                <AppLoader position={"fixed"}/> :
                <Card.Body>
                    <Tabs id="specificationAdminTab">
                        {
                            specifications.map(specification => {
                                return (
                                    <Tab eventKey={specification.id} title={specification.name} key={specification.id}>
                                        <ul className="specification-admin">
                                            {
                                                specification.amenities && specification.amenities.map((amenity) => {
                                                    return (
                                                        <li key={amenity.id}>
                                                            <Form.Label>
                                                                <input name={"specification"} value={amenity.id}
                                                                            defaultChecked={isSpecificationSelected(amenity.id)}
                                                                            {...props.register("specification")}
                                                                            type="checkbox" hidden/>
                                                                <i className="control-check"></i>
                                                                <small>{amenity.name}</small>
                                                            </Form.Label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </Card.Body>
            }
        </Card>
    )
}

export default Specifications;