import React from 'react'
import Slider from "react-slick";
import CardComp from './CardComp';
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import '../styles/carousel.scss';
import CardFooter from './CardFooter';
import SectionTitle from './SectionTitle';
import ContainerMain from './ContainerMain';
import * as Constants from "../../common/constants";
import * as StringUtil from "../../common/util/string-util";
import * as AreaUnit from "../../enums/area_unit";
import * as JsonUtil from "../../common/util/json-util";
import * as ArrayUtil from "../../common/util/array-util";
import {getFormattedPrice} from "../util/currency-util";

const FeaturedProperties = ({properties, apartmentTypes}) => {
    const slidesToShow = properties.length > 2 ? 3 : properties.length
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slidesToShow,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const getCardFooter = (location) => {
        return (
            <CardFooter>
                <i className="material-icons-two-tone">pin_drop</i>
                <span>{location}</span>
            </CardFooter>
        )
    }

    return (
        <>
            <ContainerMain className="home-carousel" bgColor={true}>
                <SectionTitle text="Featured" span="Properties"/>
                <div className="inner-wrapper">
                    <Slider {...settings}>
                        {
                            properties && properties.map((property, index) => {
                                return (
                                    <CardComp
                                        key={index}
                                        id={property.id}
                                        image={Constants.serverUrl + "file/get/" + property.bannerFileId}
                                        price={!property.hidePrice
                                            ? getFormattedPrice(ArrayUtil.getMinimum(JsonUtil.getFieldValue(property, "apartments"), "price")) : "hidden"}
                                        footer={getCardFooter(JsonUtil.getFieldValue(property, "address.locality.name")
                                            + ", " + JsonUtil.getFieldValue(property, "address.district.name"))}
                                        hover={true}
                                        slug={property.slug}
                                        title={property.name}>
                                        <span className="card-description">
                                            {StringUtil.getEllipseValue(JsonUtil.getFieldValue(property, "propertyDetail.description"), 90)}
                                        </span>
                                        <span className="card-items">
                                            <span>
                                                <i className="material-icons-two-tone">home_work</i>
                                                <b>
                                                    {JsonUtil.getFieldValue(property, "propertyDetail.totalArea")}
                                                </b>
                                                &nbsp;{ArrayUtil.getLabel(AreaUnit.area_units,
                                                JsonUtil.getFieldValue(property, "propertyDetail.areaUnit"))}
                                            </span>
                                            {JsonUtil.getFieldValue(property, "apartments").length > 0 &&
                                            <span><b>
                                                {ArrayUtil.getCommaSeparatedValue(ArrayUtil.sort(JsonUtil.getFieldValue(property,
                                                    "apartments"), "typeId"), "typeId", apartmentTypes)}
                                                {" " + Constants.APARTMENT_CONFIG_SUFFIX}
                                            </b></span>}
                                        </span>
                                    </CardComp>
                                )
                            })
                        }
                    </Slider>
                </div>
            </ContainerMain>
        </>
    )
}

export default FeaturedProperties