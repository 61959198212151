import React, {useState} from 'react'
import {Card, Col, Form, Row} from 'react-bootstrap';
import RightContent from "../components/RightContent";
import * as Action from "../../common/api-action";
import AppLoader from "../../common/component/AppLoader";
import FileUpload from "../components/FileUpload";
import {useForm} from "react-hook-form";
import Button from "react-bootstrap/Button";
import InputError from "../components/InputError";
import * as FileUtil from "../../common/util/file-util";
import * as Session from '../../app/util/session'
import NoAccess from "./NoAccess";
import FormCKEditor from "../../common/component/FormCKEditor";

const Organization = (props) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm();
    const [loading, setLoading] = useState(true);
    const [organization, setOrganization] = useState();

    React.useEffect(() => {
        Action.callPostApi("user/organisation", {}, populateOrganisationData.bind(this));
    }, []);

    if (!Session.isValidAdminUser()) {
        return <NoAccess/>;
    }

    const onSave = (form) => {
        props.setSavingStatus(1);
        if (!id) {
            onSaveError({name: 'name', message: "Something went wrong, please refresh the page and try again."})
        }
        Action.callPostApi("organisation/update", getData(form),
            afterSave, onSaveError, null, true);
    }

    const afterSave = () => {
        props.setSavingStatus(2);
        document.location.href = '/admin/organization';
    }

    const onSaveError = (data) => {
        props.setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const onRemoveBannerImage = () => {
        setValue("bannerImageId", -1);
    }

    const onRemoveLogo = () => {
        setValue("logoFileId", -1);
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("logoFile", form.logoFile[0]);
        formData.append("bannerImage", form.bannerImage[0]);
        formData.append("favIcon", form.favIcon[0]);
        const organizationData = {
            "id": id,
            "name": form.name,
            "phone": form.phone,
            "email": form.email,
            "displayAddress": form.displayAddress,
            "description": form.description,
            "logoFile": getLogoFileDetails(form),
            "bannerText": form.bannerText,
            "reraNumber": form.reraNumber,
            "url": form.url,
            "facebookUrl": form.facebookUrl,
            "youtubeUrl": form.youtubeUrl,
            "twitterUrl": form.twitterUrl,
            "googleUrl": form.googleUrl,
            "linkedInUrl": form.linkedInUrl,
            "gtmHeader": form.gtmHeader,
            "gtmBody": form.gtmBody,
            "additionalScripts": form.additionalScripts,
            "bannerImage": getBannerImageDetails(form),
            "favIcon": getFavIconDetails(form),
            "ordNum": form.ordNum
        };
        formData.append("data", JSON.stringify(organizationData));
        return formData;
    }

    const getLogoFileDetails = (form) => {
        if (form.logoFile && form.logoFile.length > 0) {
            return {
                id: form.logoFileId,
                fileName: form.logoFile[0].name,
                displayName: form.logoFile[0].name,
                fileType: 2,
                size: form.logoFile[0].size,
                mimetype: form.logoFile[0].type,
                format: FileUtil.getFileFormat(form.logoFile[0].name)
            }
        }
        if (form.logoFileId > 0) {
            return logoFile;
        }
        if (form.logoFileId == -1) {
            return {
                id: logoFileId
            }
        }
        return null;
    }

    const getBannerImageDetails = (form) => {
        if (form.bannerImage && form.bannerImage.length > 0) {
            return {
                id: form.bannerImageId,
                fileName: form.bannerImage[0].name,
                displayName: form.bannerImage[0].name,
                fileType: 2,
                size: form.bannerImage[0].size,
                mimetype: form.bannerImage[0].type,
                format: FileUtil.getFileFormat(form.bannerImage[0].name)
            }
        }
        if (form.bannerImageId > 0) {
            return bannerImage;
        }
        if (form.bannerImageId == -1) {
            return {
                id: bannerImageId
            }
        }
        return null;
    }

    const getFavIconDetails = (form) => {
        if (form.favIcon && form.favIcon.length > 0) {
            return {
                id: +form.favIconId,
                fileName: form.favIcon[0].name,
                displayName: form.favIcon[0].name,
                fileType: 2,
                size: form.favIcon[0].size,
                mimetype: form.favIcon[0].type,
                format: FileUtil.getFileFormat(form.favIcon[0].name)
            }
        }
        if (form.favIconId > 0) {
            return favIcon;
        }
        if (form.favIconId == -1) {
            return {
                id: favIconId
            }
        }
        return null;
    }

    const populateOrganisationData = (response) => {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            setOrganization(response.organisation);
            setLoading(false);
        }
    }

    if (loading) {
        return <AppLoader position={"fixed"}/>
    }
    const {
        id, name, phone, email, description, logoFile, logoFileId, displayAddress, favIcon, favIconId, gtmHeader,
        gtmBody, additionalScripts, reraNumber,
        bannerImage, bannerImageId, bannerText, url, facebookUrl, youtubeUrl, twitterUrl, linkedInUrl, googleUrl
    } = organization;

    return (
        <RightContent headerText={"Manage Organisation"}>
            <form onSubmit={handleSubmit(onSave)}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Basic Details
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Name
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={name}
                                              className={getInvalidFieldClass('name')}
                                              aria-label="Name"
                                              {...register('name', {required: 'Name cannot be empty'})}/>
                                <InputError error={errors.name}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="logoFile" className="mb-3">
                            <Form.Label column sm="3">
                                Logo
                            </Form.Label>
                            <Col sm="9" className="form-types has-file-upload">
                                <FileUpload id={"logoFile"} value={logoFileId}
                                            onRemoveClick={onRemoveLogo}
                                            isRequired={true}
                                            register={register}/>
                                <InputError error={errors.logoFile}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Email Address
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="email" defaultValue={email}
                                              className={getInvalidFieldClass('email')}
                                              aria-label="Email"
                                              {...register('email', {required: 'Email cannot be empty'})}/>
                                <InputError error={errors.email}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" style={{display: 'none'}}>
                            <Form.Label column sm="3">
                                Website Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={url}
                                              className={getInvalidFieldClass('url')}
                                              aria-label="Website Url"
                                              {...register('url', {required: 'Website Url cannot be empty'})}/>
                                <InputError error={errors.url}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                RERA Number
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={reraNumber}
                                              className={getInvalidFieldClass('reraNumber')}
                                              aria-label="RERA Number"
                                              {...register('reraNumber')}/>
                                <InputError error={errors.reraNumber}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Contact Number
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="phone" defaultValue={phone}
                                              className={getInvalidFieldClass('phone')}
                                              aria-label="Contact Number"
                                              {...register('phone', {required: 'Phone cannot be empty'})}/>
                                <InputError error={errors.phone}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="favIcon" className="mb-3">
                            <Form.Label column sm="3">
                                FavIcon
                            </Form.Label>
                            <Col sm="9" className="form-types has-file-upload">
                                <FileUpload id={"favIcon"} value={favIconId} fileTypes={".ico"}
                                            register={register}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                About Us
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control as="textarea" defaultValue={description}
                                              className={getInvalidFieldClass('description')}
                                              aria-label="Description"
                                              maxLength={1000}
                                              {...register('description', {required: 'Description cannot be empty'})}/>
                                <InputError error={errors.description}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Banner Text
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <FormCKEditor id={'bannerText'}
                                              name={'bannerText'}
                                              title={'Banner Text'}
                                              maxLength={1000}
                                              defaultHtml={bannerText}
                                              onChange={(value) => setValue('bannerText', value)}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="bannerImage" className="mb-3">
                            <Form.Label column sm="3">
                                Banner Image
                            </Form.Label>
                            <Col sm="9" className="form-types has-file-upload">
                                <FileUpload id={"bannerImage"} value={bannerImageId}
                                            onRemoveClick={onRemoveBannerImage}
                                            register={register}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="displayAddress" className="mb-3">
                            <Form.Label column sm="3">
                                Address
                            </Form.Label>
                            <Col sm="9" className="form-types has-file-upload">
                                <Form.Control type="text" defaultValue={displayAddress}
                                              className={getInvalidFieldClass('displayAddress')}
                                              aria-label="Display Address"
                                              {...register('displayAddress')}/>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Media Details
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Facebook Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={facebookUrl}
                                              aria-label="Facebook Url"
                                              {...register('facebookUrl')}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Google Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={googleUrl}
                                              aria-label="Google Url"
                                              {...register('googleUrl')}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Youtube Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={youtubeUrl}
                                              aria-label="Youtube Url"
                                              {...register('youtubeUrl')}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Twitter Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={twitterUrl}
                                              aria-label="Twitter Url"
                                              {...register('twitterUrl')}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                LinkedIn Url
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control type="text" defaultValue={linkedInUrl}
                                              aria-label="LinkedIn Url"
                                              {...register('linkedInUrl')}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Google Tag Manager Header
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control as="textarea" name={"gtmHeader"}
                                              aria-label="Google Tag Manager Header"
                                              defaultValue={gtmHeader}
                                              {...register('gtmHeader')}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Google Tag Manager Body
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control as="textarea" name={"gtmBody"}
                                              aria-label="Google Tag Manager Body"
                                              defaultValue={gtmBody}
                                              {...register('gtmBody')}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                Additional Scripts
                            </Form.Label>
                            <Col sm="9" className="form-types">
                                <Form.Control as="textarea" name={"additionalScripts"}
                                              aria-label="Additional Scripts"
                                              defaultValue={additionalScripts}
                                              {...register('additionalScripts')}/>
                            </Col>
                        </Form.Group>
                        <div className="form-group action-btn-wrapper">
                            <Button type="submit">Save</Button>
                        </div>
                    </Card.Body>
                </Card>
            </form>
        </RightContent>
    )
}
export default Organization;