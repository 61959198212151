import React from 'react'
import '../styles/footer.scss'
import * as Constants from "../../common/constants";
import {isEmpty} from "../../common/util/string-util";

const Footer = ({organization, staticPages, topLocalities}) => {
    const socialUrlsPresent = organization.facebookUrl && organization.facebookUrl.length > 0 ||
        organization.twitterUrl && organization.twitterUrl.length > 0 ||
        organization.googleUrl && organization.googleUrl.length > 0 ||
        organization.youtubeUrl && organization.youtubeUrl.length > 0 ||
        organization.linkedInUrl && organization.linkedInUrl.length > 0;

    return (
        <>
            {/*<NewsletterSub/>*/}
            <footer>
                <div className="footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 footer-left">
                                <img src={Constants.serverUrl + "file/get/" + organization.logoFileId} className='foo-logo' alt="logo"/>
                                <p>{organization.name}</p>
                                {organization.reraNumber && !isEmpty(organization.reraNumber) &&
                                <p>
                                    RERA Registration No: {organization.reraNumber}
                                </p>}
                                <p>Phone: {organization.phone}</p>
                                <p>
                                    <small>registered & corporate office</small>
                                    {organization.displayAddress}
                                </p>
                            </div>

                            <div className="col-md-6 footer-right">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="row">
                                            <li className="col-md-6">
                                                <h6>write to us</h6>
                                                <a href={"mailto:" + organization.email} className="footer-icon-circle">{organization.email}</a> 
                                            </li>
                                            <li className="col-md-6">
                                                <h6>call us</h6>
                                                <a href={"tel:" + organization.phone} className="footer-icon-circle">{organization.phone}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    {staticPages && staticPages.length > 0 && <div className="foo-sbs col-md-6">
                                        <h6>Company</h6>
                                        <ul className="mb-0 mt-2">
                                            {
                                                staticPages.map(staticPage => {
                                                    return (
                                                        <li key={staticPage.id}>
                                                            <a href={"/" + staticPage.link}>
                                                            {staticPage.header}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>}

                                    {topLocalities && topLocalities.length > 0 && <div className="foo-sbs col-md-6">
                                        <h6>Top locations</h6>
                                        <ul className="mb-0 mt-2">
                                            {
                                                topLocalities.map(locality => {
                                                    return (
                                                        <li key={locality.id}>
                                                            <a href={"/searchresult?cities=" + locality.districtId + "&localities=" + locality.id}>
                                                                {"Properties in " + locality.name}</a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>}

                                    {socialUrlsPresent && <div className="col-xl-12">
                                        <h6>Social Icons</h6>
                                        <ul className="social-icons mb-0 mt-2">
                                            {organization.facebookUrl && organization.facebookUrl.length > 0 && <li>
                                                <a className="social-icon" href={organization.facebookUrl}
                                                target={"_blank"} rel="noopener noreferrer">
                                                    <i className="fa fa-facebook"></i></a> </li>}
                                            {organization.twitterUrl && organization.twitterUrl.length > 0 && <li>
                                                <a className="social-icon" href={organization.twitterUrl}
                                                target={"_blank"} rel="noopener noreferrer">
                                                    <i className="fa fa-twitter"></i></a> </li>}
                                            {organization.googleUrl && organization.googleUrl.length > 0 && <li>
                                                <a className="social-icon" href={organization.googleUrl}
                                                target={"_blank"} rel="noopener noreferrer">
                                                    <i className="fa fa-rss"></i></a> </li>}
                                            {organization.youtubeUrl && organization.youtubeUrl.length > 0 && <li>
                                                <a className="social-icon" href={organization.youtubeUrl}
                                                target={"_blank"} rel="noopener noreferrer">
                                                    <i className="fa fa-youtube"></i></a> </li>}
                                            {organization.linkedInUrl && organization.linkedInUrl.length > 0 && <li>
                                                <a className="social-icon" href={organization.linkedInUrl}
                                                target={"_blank"} rel="noopener noreferrer">
                                                    <i className="fa fa-linkedin"></i></a> </li>}
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-last">
                    <div className="container">
                        <div className="row d-flex">
                            <div className="col-sm-12 mt-2 mb-2"> Copyright © 2022 <a href="/" className="fs-14 text-primary">Bombay Broking</a>. All rights reserved. </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer