import React, {Component} from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Col, Form} from 'react-bootstrap';

import AppLoader from "../../common/component/AppLoader";
import * as Action from "../../common/api-action";
import FormCKEditor from "../../common/component/FormCKEditor";
import InputError from "../components/InputError";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class StaticPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            staticPages: [],
            errors: {}
        }
    }

    componentDidMount() {
        Action.callPostApi("static-page/all", {}, this.populateStaticPagesData.bind(this));
    }

    populateStaticPagesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                staticPages: response.staticPages
            });
        }
    }

    isValidValue(value) {
        return value.match(/^[A-Za-z]+$/);
    }

    onSave(index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.staticPages[index];
        if (!this.isValidValue(data.link)) {
            this.setError({fieldName: "link_" + index, message: "Link can only have alphabets."});
            this.props.setSavingStatus(3);
            return;
        }
        Action.callPostApi("static-page/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onDelete(index) {
        if (window.confirm("Are you sure you want to delete this page? This action cannot be reverted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.staticPages[index];
            Action.callPostApi("static-page/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    afterSave() {
        this.props.setSavingStatus(2);
    }

    afterDelete(index) {
        document.location.href = "/admin/staticpages";
    }

    setError(error) {
        let errors = this.state.errors;
        errors[error.fieldName] = error.message;
        this.setState({
            errors: errors
        })
    }

    resetErrors() {
        this.setState({errors: {}});
    }

    getError(fieldName) {
        return this.state.errors[fieldName];
    }

    hasError(fieldName) {
        return this.getError(fieldName) && this.getError(fieldName).length > 0;
    }

    onAddNewPageClick() {
        const staticPagesUpdated = [...this.state.staticPages];
        staticPagesUpdated.push(this.getNewPageJSON());
        this.setState({staticPages: staticPagesUpdated});
    }

    getNewPageJSON() {
        return {
            id: 0,
            header: "New Page Header",
            html: "New Page Content here"
        }
    }

    getInvalidFieldClass(field) {
        return this.hasError(field) ? ' is-invalid' : '';
    }

    onChangeField = (index, id, value) => {
        const staticPagesUpdated = [...this.state.staticPages];
        staticPagesUpdated[index][id] = value;
        this.setState({staticPages: staticPagesUpdated});
    }

    render() {
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position={"fixed"}/>
        }

        return (
            <RightContent>
                <div className="admin-header-title">
                    <h5>Static Pages</h5>
                    <Button variant="primary" onClick={this.onAddNewPageClick.bind(this)}>
                        <span>Add Page</span>
                    </Button>

                </div>

                {this.state.staticPages.map((staticPage, index) => (
                    <Card className="has-ckeditor" key={index}>
                        <Card.Body>

                            <Card.Title>
                                <Form.Control type="hidden" defaultValue={staticPage.id}
                                                id={"hidden_" + index}/>
                                <Form.Control type="text" defaultValue={staticPage.header}
                                              className={this.getInvalidFieldClass('header')}
                                              onBlur={(e) => this.onChangeField(index, "header", e.currentTarget.value)}
                                              aria-label="Header"
                                              placeholder={"Header"}
                                              id={"header_" + index}/>
                                
                            </Card.Title>
                            <Card.Title>
                                <Form.Control type="text" defaultValue={staticPage.link}
                                              className={this.getInvalidFieldClass('link_' + index)}
                                              onBlur={(e) => this.onChangeField(index, "link", e.currentTarget.value)}
                                              aria-label="Link"
                                              placeholder={"Link"}
                                              id={"link_" + index}/>
                                <InputError error={this.getError("link_" + index)}/>
                            </Card.Title>
                            <FormCKEditor id={'html_' + index}
                                          name={'html_' + index}
                                          title={'Page html'}
                                          defaultHtml={staticPage.html}
                                          onChange={this.onChangeField.bind(this, index, "html")}/>
                        </Card.Body>
                        <Card.Footer>
                            <div className="action-btn-wrapper">
                                <Button variant='primary' onClick={this.onSave.bind(this, index)}>Save</Button>
                                <Button variant='dark' onClick={this.onDelete.bind(this, index)}>Delete</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                ))}


            </RightContent>
        );
    }
}