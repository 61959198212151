import React, {Component} from 'react';
import {Card, Dropdown, Table} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import RightContent from "../components/RightContent";
import * as StringUtil from "../../common/util/string-util";
import AppLoader from '../../common/component/AppLoader';
import District from "./District";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class DistrictList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: null,
            loading: true,
        }
    }

    componentDidMount() {
        Action.callPostApi("districts", {}, this.populateDistrictsData.bind(this));
    }

    populateDistrictsData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                districts: response.districts
            });
        }
    }

    getHeaderButtons() {
        return [{
            href: "/admin/districts/add",
            icon: "",
            text: "Add District"
        }];
    }

    getDistrict(id) {
        if (!id || isNaN(id) || !this.state.districts) {
            return null;
        }
        for (let i = 0; i < this.state.districts.length; i++) {
            if (this.state.districts[i].id == id) {
                return this.state.districts[i];
            }
        }
        return null;
    }

    onDeleteDistrictClick(id) {
        if (window.confirm("Are you sure you want to delete this District?")) {
            Action.callPostApi("districts/delete",
                {id: id}, this.afterDeleteDistrict.bind(this, id));
        }
    }

    afterDeleteDistrict(id) {
        const districtListAfterDeletion = this.state.districts.filter(function(district) { return district.id !== id; });
        this.setState({districts: districtListAfterDeletion});
    }

    render() {
        const {mode, setSavingStatus} = this.props;
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position="fixed"/>
        }

        if (mode === 'add') {
            return <District mode={mode} setSavingStatus={setSavingStatus}/>;
        }

        if (mode === 'edit') {
            const district = this.getDistrict(StringUtil.getLastPathParam("/edit/"));
            if (district) {
                return <District mode={mode}
                              district={district} setSavingStatus={setSavingStatus}/>;
            }
        }

        return (
            <RightContent headerText={"Districts"} headerButtons={this.getHeaderButtons()}>
                <Card>
                    <Card.Body>
                        <Table className="has-action" striped bordered hover>
                            <thead>
                            <tr>
                                <th width="30%">Name</th>
                                <th width="30%">Code</th>
                                <th width="30%">State</th>
                                <th width="30%">Popularity</th>
                                <th width="30%">Latitude</th>
                                <th width="30%">Longitude</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!this.state.districts || this.state.districts.length == 0 &&
                            <tr><td colSpan={6}>No Districts found.</td></tr>}
                            {this.state.districts && this.state.districts.map((district) => {
                                return (
                                    <tr key={district.id}>
                                        <td>{district.name}</td>
                                        <td>{district.code}</td>
                                        <td>{district.state ? district.state.name : ""}</td>
                                        <td>{district.popularity}</td>
                                        <td>{district.latitude}</td>
                                        <td>{district.longitude}</td>
                                        <td>
                                            <Dropdown align="end">
                                                <Dropdown.Toggle variant="outline-dark" id="more-action">
                                                    <span className="material-icons-sharp">more_horiz</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu renderOnMount="true">
                                                    <Dropdown.Item href={"/admin/districts/edit/" + district.id}>
                                                        <span className="material-icons-sharp">mode_edit</span>
                                                        Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={this.onDeleteDistrictClick.bind(this, district.id)}>
                                                        <span className="material-icons-sharp">delete</span>
                                                        Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        )
    }
}