import React, {Component} from 'react';
import $ from 'jquery';
import {Button} from 'react-bootstrap';
import '../styles/searchresult.scss'
import ContainerMain from '../components/ContainerMain'
import CardComp from '../components/CardComp';
import CardFooter from '../components/CardFooter';
import * as Constants from "../../common/constants";
import {Helmet} from "react-helmet";
import InputRange from 'react-input-range';
import {getFormattedPrice} from "../util/currency-util";
import {getQueryParam} from "../util/request-util";
import LocationDropdown from '../components/LocationDropdown';
import AppLoader from "../../common/component/AppLoader";
import PropertyTypeDropdown from "../components/PropertyTypeDropdown";
import * as Action from "../../common/api-action";
import * as JsonUtil from "../../common/util/json-util";
import * as StringUtil from "../../common/util/string-util";
import * as ArrayUtil from "../../common/util/array-util";
import * as AreaUnit from "../../enums/area_unit";
import {MapComponentView} from "../../common/component/MapComponentView";
import LocalityDropdown from "../components/LocalityDropdown";

export default class SearchResult extends Component {

    constructor(props) {
        super(props);
        const selectedCityOptions = this.getSearchedOptions(getQueryParam("cities"),
            this.getLocations(props.cities), 'id', 'name');
        this.state = {
            loading: true,
            price: {min: +(getQueryParam('min') ? getQueryParam('min') : Constants.MIN_PRICE),
                max: +(getQueryParam('max') ? getQueryParam('max') : Constants.MAX_PRICE)},
            propertyTypes: this.getSearchedOptions(getQueryParam("propertyType"), props.apartmentTypes,
                'id', 'name', Constants.APARTMENT_CONFIG_SUFFIX),
            searchCityOptions: selectedCityOptions,
            searchLocalityOptions: this.getSearchedOptions(getQueryParam("localities"),
                this.getLocalities(props.localities, selectedCityOptions), 'id', 'name'),
            properties: null,
            activeMarkerObj: null,
            activeMarker: null,
            hoveredProperty: null
        }
    }

    getLocations(cities) {
        return cities && cities.map((city) => {
            return {
                id: city.id,
                name: city.name
            }
        });
    }

    getLocalities(localities, selectedCities) {
        let localitiesOptions = [];
        for (let i = 0; i < localities.length; i++) {
            let locality = localities[i];
            if (selectedCities && selectedCities.filter((selectedCity) => selectedCity.value === locality.districtId).length > 0) {
                localitiesOptions.push({
                    id: locality.id,
                    name: locality.name + ", " + locality.district.name
                });
            }
        }
        return localitiesOptions;
    }

    setHoveredProperty(id) {
        this.setState({
            hoveredProperty: id
        })
    }

    getSearchedOptions(searchOptions, list, key, value, suffix) {
        if (!searchOptions || !list) {
            return null;
        }
        searchOptions = searchOptions.split(",");
        let searchedOptions = null;
        for (let i = 0; i < searchOptions.length; i++) {
            for (let j = 0; j < list.length; j++) {
                const elem = list[j];
                if ('' + elem[key] === searchOptions[i]) {
                    if (searchedOptions == null) {
                        searchedOptions = [];
                    }
                    searchedOptions.push({value: elem[key], label: elem[value] + (suffix ? " " + suffix : "")});
                }
            }
        }
        return searchedOptions;
    }

    componentDidMount() {
        Action.callPostApi("search", this.getSearchData(), this.onSuccessPropertyApi.bind(this));
    }

    getSearchData() {
        return {
            organizationId: this.props.organizationId,
            cities: this.getLocation("cities"),
            localities: this.getLocation("localities"),
            propertyTypes: getQueryParam("propertyType"),
            minPrice: +getQueryParam('min'),
            maxPrice: +getQueryParam('max')
        }
    }
    
    getLocation(param) {
        let value = '';
        let locations = getQueryParam(param);
        if (!locations || locations.length == 0) {
            return null;
        }
        locations = locations.split(",");
        locations.map((location) => {
            value += (value != '' ? "," : "") + location;
        })
        return value;
    }

    onSuccessPropertyApi(data) {
        this.setState({
            properties: data.properties,
            loading: false
        })
    }

    listViewAddClass() {
        $('.view-setting-wrapper').next().addClass('list-view');
    };

    listViewRemoveClass() {
        $('.view-setting-wrapper').next().removeClass('list-view');
    };

    getMarkers() {
        let markers = [];
        this.state.properties && this.state.properties.map(property => {
            markers.push({
                id: JsonUtil.getFieldValue(property, "id"),
                slug: JsonUtil.getFieldValue(property, "slug"),
                hidePrice: JsonUtil.getFieldValue(property, "hidePrice"),
                apartments: JsonUtil.getFieldValue(property, "apartments"),
                name: JsonUtil.getFieldValue(property, "name"),
                bannerFileId: JsonUtil.getFieldValue(property, "bannerFileId"),
                latitude: JsonUtil.getFieldValue(property, "address.latitude"),
                longitude: JsonUtil.getFieldValue(property, "address.longitude")
            })
        })
        return markers;
    }

    setActiveMarkerObj(markerObj, marker) {
        this.setState({
            activeMarkerObj: markerObj,
            activeMarker: marker
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.activeMarkerObj !== this.state.activeMarkerObj && this.state.activeMarkerObj) {
            $('html, body').animate({
                scrollTop: $('.card-' + this.state.activeMarkerObj.id).offset().top - 350
            }, 200, 'swing');
        }
    }

    setPrice(price) {
        this.setState({price});
    }

    onUpdateSelectedCities(valueJSON) {
        this.setState({searchCityOptions: valueJSON})
    }

    onUpdateSelectedLocalities(valueJSON) {
        this.setState({searchLocalityOptions: valueJSON})
    }

    onUpdatePropertyTypes(valueJSON) {
        this.setState({propertyTypes: valueJSON})
    }

    getCardFooter(location) {
        return (
            <CardFooter>
                <i className="material-icons-two-tone">pin_drop</i>
                <span>{location}</span>
            </CardFooter>
        )
    }

    getTotalProperties() {
        return 0 + (this.state.properties ? this.state.properties.length : 0)
    }

    getSearchParams() {
        let searchParams = "";
        searchParams = this.appendDropdownParam(searchParams, this.state.searchCityOptions, 'cities', true);
        searchParams = this.appendDropdownParam(searchParams, this.state.searchLocalityOptions, 'localities');
        searchParams = this.appendDropdownParam(searchParams, this.state.propertyTypes, 'propertyType');
        searchParams = this.appendPriceRange(searchParams);
        return searchParams;
    }

    onSearch() {
        document.location.href = "/searchresult?" + this.getSearchParams()
    }

    appendDropdownParam(searchParams, selectedValues, urlParameter, isFirst) {
        for (let i = 0; selectedValues && i < selectedValues.length; i++) {
            if (i === 0) {
                searchParams += !isFirst ? "&" : "";
                searchParams += urlParameter + "=";
                searchParams += selectedValues[i].value;
            } else {
                searchParams += "," + selectedValues[i].value;
            }
        }
        return searchParams;
    }

    appendPriceRange(searchParams) {
        if (searchParams.length) {
            searchParams += "&";
        }
        searchParams += "min=" + this.state.price.min + "&max=" + this.state.price.max;
        return searchParams;
    }

    render() {
        if (this.state.loading) {
            return <AppLoader position={"fixed"}/>
        }
        return (
            <>
                <Helmet>
                    <style type="text/css">{`
                        .main_header {
                            background-color: #000;
                        }
                    `}</style>
                </Helmet>
                <div className="split-container">
                    <div className="map-wrapper">
                        <MapComponentView markers={this.getMarkers()}
                                          hoveredPropertyId={this.state.hoveredProperty}
                                          apartmentTypes={this.props.apartmentTypes}
                                          setActiveMarkerObj={this.setActiveMarkerObj.bind(this)}
                                          organizationName={this.props.organizationName}/>
                    </div>

                    <ContainerMain className="result-wrapper">
                        <div className="advance-search-wrapper">

                            <LocationDropdown cities={this.props.cities} selectedCities={this.state.searchCityOptions}
                                              onUpdateSelectedCities={this.onUpdateSelectedCities.bind(this)}/>
                            <LocalityDropdown localities={this.props.localities} selectedLocalities={this.state.searchLocalityOptions}
                                              selectedCities={this.state.searchCityOptions}
                                              onUpdateSelectedLocalities={this.onUpdateSelectedLocalities.bind(this)}/>
                            <PropertyTypeDropdown apartmentTypes={this.props.apartmentTypes}
                                                  propertyTypes={this.state.propertyTypes}
                                                  setPropertyTypes={this.onUpdatePropertyTypes.bind(this)}/>

                            <div className="search-col has-slider">
                                <div className="slider-value">
                                    <span>Price Range</span>
                                    &nbsp;
                                    From
                                    &nbsp;
                                    <span>&#x20B9; {getFormattedPrice(this.state.price.min)}</span>
                                    &nbsp;
                                    To
                                    &nbsp;
                                    <span>&#x20B9; {getFormattedPrice(this.state.price.max)
                                    + (this.state.price.max >= Constants.MAX_PRICE ? "+" : "")}</span>
                                </div>
                                <InputRange
                                    step={Constants.PRICE_STEP}
                                    draggableTrack={false}
                                    allowSameValues={false}
                                    maxValue={Constants.MAX_PRICE}
                                    minValue={Constants.MIN_PRICE}
                                    value={this.state.price}
                                    onChange={this.setPrice.bind(this)}
                                    onChangeComplete={args => console.log(args)}
                                />
                            </div>

                            <div className="this-footer">
                                <Button variant="primary" onClick={this.onSearch.bind(this)}>Search</Button>
                            </div>
                        </div>
                        <div className="view-setting-wrapper">
                            <div className="this-left">
                                {this.getTotalProperties()} Results Found
                            </div>
                            {this.getTotalProperties() !== 0 && <div className="this-right">
                                <Button size="sm" onClick={this.listViewAddClass}><span
                                    className="material-icons-round">view_list</span></Button>
                                <Button size="sm" onClick={this.listViewRemoveClass}><span
                                    className="material-icons-round active">grid_view</span></Button>
                            </div>}
                        </div>
                        {this.state.loading ? <AppLoader/> :
                            <div className="inner-wrapper">
                                {
                                    this.state.properties && this.state.properties.map((property, index) => {
                                        return (
                                            <CardComp
                                                id={property.id}
                                                key={index}
                                                onMouseover={this.setHoveredProperty.bind(this)}
                                                active={this.state.activeMarkerObj && this.state.activeMarkerObj.id === property.id}
                                                slug={property.slug}
                                                image={Constants.serverUrl + "file/get/" + property.bannerFileId}
                                                title={property.name}
                                                price={!property.hidePrice
                                                    ? getFormattedPrice(ArrayUtil.getMinimum(JsonUtil.getFieldValue(property, "apartments"), "price"))  : "hidden"}
                                                footer={this.getCardFooter(JsonUtil.getFieldValue(property, "address.locality.name")
                                                    + ", " + JsonUtil.getFieldValue(property, "address.district.name"))}>
                                                <span className="card-description">
                                                    {StringUtil.getEllipseValue(JsonUtil.getFieldValue(property, "propertyDetail.description"), 90)}
                                                </span>
                                                <span className="card-items">
                                                    <span>
                                                        <i className="material-icons-two-tone">home_work</i>
                                                        <b>
                                                            {JsonUtil.getFieldValue(property, "propertyDetail.totalArea")}
                                                        </b>
                                                        &nbsp;{ArrayUtil.getLabel(AreaUnit.area_units,
                                                        JsonUtil.getFieldValue(property, "propertyDetail.areaUnit"))}
                                                    </span>
                                                    {JsonUtil.getFieldValue(property, "apartments").length > 0 &&
                                                    <span><b>
                                                        {ArrayUtil.getCommaSeparatedValue(ArrayUtil.sort(JsonUtil.getFieldValue(property,
                                                            "apartments"), "typeId"), "typeId", this.props.apartmentTypes)}
                                                        {" " + Constants.APARTMENT_CONFIG_SUFFIX}
                                                    </b></span>}
                                                </span>
                                            </CardComp>
                                        )
                                    })
                                }
                            </div>
                        }
                    </ContainerMain>

                </div>
            </>
        )
    }
}