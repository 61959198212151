import React, {Component} from "react";
import {GoogleApiWrapper, InfoWindow, Map, Marker,} from 'google-maps-react';
import * as Constants from "../constants";
import * as JsonUtil from "../util/json-util";
import * as ArrayUtil from "../util/array-util";
import {getFormattedPrice} from "../../app/util/currency-util";

export class MapComponentView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: props.markers,
            activeMarker: null,
            showInfo: false
        }
    }

    getMarkerPosition(marker) {
        return {
            lat: marker.latitude,
            lng: marker.longitude
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.hoveredProperty !== this.props.hoveredProperty && this.props.hoveredProperty) {
        }
    }

    displayMarker = () => {
        if (!this.state.markers) {
            return;
        }
        return this.state.markers.map((marker, index) => {
            return <Marker id={"propertyMarker" + index} key={index} position={this.getMarkerPosition(marker)}
                           icon={{url:"/images/location_1_40x40.png"}}
                           draggable={false} name={marker.name} onMouseover={this.onMarkerHover.bind(this, marker)}/>
        })
    }

    onMarkerHover = (markerObj, props, marker, e) => {
        if (!this.state.activeMarkerObj || this.state.activeMarkerObj.id !== markerObj.id) {
            this.setState({
                activeMarker: marker,
                activeMarkerObj: markerObj,
                showInfo: true
            });
            this.props.setActiveMarkerObj(markerObj, marker);
        }
    }

    getInitialCenter = () => {
        return {
            lat: this.props.initialCenter && this.props.initialCenter.lat
                ? this.props.initialCenter.lat : (this.state.markers && this.state.markers[0] ? this.state.markers[0].latitude : 18.5204),
            lng: this.props.initialCenter && this.props.initialCenter.lng
                ? this.props.initialCenter.lng : (this.state.markers && this.state.markers[0] ? this.state.markers[0].longitude : 73.8567)
        };
    }

    onInfoWindowClose() {
        this.setState({
            activeMarker: null,
            activeMarkerObj: null,
            showInfo: false
        });
        this.props.setActiveMarkerObj(null, null);
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={14}
                style={this.props.mapStyle ? this.props.mapStyle : {}}
                initialCenter={this.getInitialCenter()}>
                {this.displayMarker()}
                <InfoWindow marker={this.state.activeMarker}
                    onClose={this.onInfoWindowClose.bind(this)} visible={this.state.showInfo}>
                    {this.state.activeMarker ?
                    <ul className="map-popover-wrapper">
                        <li style={{ backgroundImage: "url('" + Constants.serverUrl
                                + "file/get/" + this.state.activeMarkerObj.bannerFileId + "')" }}></li>
                        <li>
                            <a href={'/property/' + this.state.activeMarkerObj.slug}><h4>{this.state.activeMarkerObj.name}</h4></a>
                            <span>{this.props.organizationName}</span>
                            {JsonUtil.getFieldValue(this.state.activeMarkerObj, "apartments").length > 0 &&
                            <span>{ArrayUtil.getCommaSeparatedValue(ArrayUtil.sort(JsonUtil.getFieldValue(this.state.activeMarkerObj,
                                    "apartments"), "typeId"), "typeId", this.props.apartmentTypes)}
                                {" " + Constants.APARTMENT_CONFIG_SUFFIX}
                            </span>}
                            {!this.state.activeMarkerObj.hidePrice
                            ? <span>&#x20B9; <strong>
                                {getFormattedPrice(ArrayUtil.getMinimum(JsonUtil.getFieldValue(this.state.activeMarkerObj, "apartments"), "price"))}
                            </strong> onwards</span> : ""}
                        </li>
                    </ul> : <ul className="demo"></ul>
                    }
                </InfoWindow>
            </Map>
        );
    }
}

MapComponentView = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
})(MapComponentView);