import React, {Component} from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Form, Table} from 'react-bootstrap';

import AppLoader from "../../common/component/AppLoader";
import * as Action from "../../common/api-action";
import InputError from "../components/InputError";
import * as Session from "../../app/util/session";
import NoAccess from "./NoAccess";

export default class Amenities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            amenities: [],
            errors: {}
        }
    }

    componentDidMount() {
        Action.callPostApi("amenities/property", {}, this.populateAmenitiesData.bind(this));
    }

    populateAmenitiesData(response) {
        if (response.type != 'success') {
            document.location.href = '/admin';
        } else {
            this.setState({
                loading: false,
                amenities: response.amenities
            });
        }
    }

    onSave(index) {
        this.resetErrors();
        this.props.setSavingStatus(1);
        const data = this.state.amenities[index];
        Action.callPostApi("amenities/save", data, this.afterSave.bind(this), this.setError.bind(this));
    }

    onDelete(index) {
        if (window.confirm("Are you sure you want to delete this Amenity? This action cannot be reverted.")) {
            this.props.setSavingStatus(1);
            const data = this.state.amenities[index];
            Action.callPostApi("amenities/delete", {id: data.id}, this.afterDelete.bind(this));
        }
    }

    afterSave() {
        this.props.setSavingStatus(2);
    }

    afterDelete() {
        document.location.href = "/admin/amenities";
    }

    setError(error) {
        let errors = this.state.errors;
        errors[error.fieldName] = error.message;
        this.setState({
            errors: errors
        })
    }

    resetErrors() {
        this.setState({errors: {}});
    }

    getError(fieldName) {
        return this.state.errors[fieldName];
    }

    hasError(fieldName) {
        return this.getError(fieldName) && this.getError(fieldName).length > 0;
    }

    onAddNewAmenityClick() {
        const amenitiesUpdated = [...this.state.amenities];
        amenitiesUpdated.push(this.getNewAmenityJSON());
        this.setState({amenities: amenitiesUpdated});
    }

    getNewAmenityJSON() {
        return {
            id: 0,
            name: "New Amenity",
            icon: "default.svg",
            type: 1
        }
    }

    getInvalidFieldClass(field) {
        return this.hasError(field) ? ' is-invalid' : '';
    }

    onChangeField = (index, id, value) => {
        const amenitiesUpdated = [...this.state.amenities];
        amenitiesUpdated[index][id] = value;
        this.setState({amenities: amenitiesUpdated});
    }

    render() {
        if (!Session.isValidAdminUser()) {
            return <NoAccess/>;
        }
        if (this.state.loading) {
            return <AppLoader position={"fixed"}/>
        }

        return (
            <RightContent>
                <div className="admin-header-title">
                    <h5>Amenity</h5>
                    <Button variant="primary" onClick={this.onAddNewAmenityClick.bind(this)}>
                        <span>Add Amenity</span>
                    </Button>
                </div>


                <Card>
                    <Card.Body>

                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Icon</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.amenities.map((amenity, index) => (
                                    <tr key={index}>
                                        <td width="47%">
                                            <Form.Control type="text" defaultValue={amenity.name}
                                                    className={this.getInvalidFieldClass('name' + index)}
                                                    onBlur={(e) => this.onChangeField(index, "name", e.currentTarget.value)}
                                                    aria-label="Name"
                                                    id={"name_" + index}/>
                                            <InputError error={this.state.errors["name_" + index]}/>
                                        </td>
                                        <td  width="47%">
                                            <Form.Control type="text" defaultValue={amenity.icon}
                                                    className={this.getInvalidFieldClass('icon' + index)}
                                                    onBlur={(e) => this.onChangeField(index, "icon", e.currentTarget.value)}
                                                    aria-label="Icon"
                                                    id={"icon_" + index}/>
                                            <InputError error={this.state.errors["icon_" + index]}/>

                                            <Form.Control type="hidden" defaultValue={amenity.id}
                                          id={"hidden_" + index}/>

                                        </td>
                                        <td>
                                            <div className="action-btn-wrapper">
                                                <Button  size="sm" variant="primary" onClick={this.onSave.bind(this, index)}>Save</Button>
                                                <Button  size="sm" variant="dark" onClick={this.onDelete.bind(this, index)}>Delete</Button>
                                           </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </RightContent>
        );
    }
}