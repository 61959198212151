import React from 'react';
import RightContent from "../components/RightContent";
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import * as Action from "../../common/api-action";
import InputError from "../components/InputError";
import {useForm} from 'react-hook-form';
import * as StringUtil from "../../common/util/string-util";
import FileUpload from "../components/FileUpload";
import * as JsonUtil from "../../common/util/json-util";
import * as FileUtil from "../../common/util/file-util";
import FormCKEditor from "../../common/component/FormCKEditor";

const Blog = (props) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm();

    const blogId = StringUtil.getLastPathParam("/edit/");

    const isAdd = !blogId || isNaN(blogId);

    const onSave = (form) => {
        props.setSavingStatus(1);
        const data = getData(form);
        const blogIdParam = isAdd ? null : +blogId;
        if (!blogIdParam) {
            Action.callPostApi("blog/add", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("blog/update", data, afterSave, onSaveError, null, true);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("thumbnail", form.thumbnail[0]);
        const blogData = {
            "id": isAdd ? null : +blogId,
            "link": form.link,
            "title": form.title,
            "content": form.content,
            "thumbnail": getThumbnailDetails(form)
        };
        formData.append("data", JSON.stringify(blogData));
        return formData;
    }

    const getThumbnailDetails = (form) => {
        if (form.thumbnail && form.thumbnail.length > 0) {
            return {
                id: form.thumbnailId,
                fileName: form.thumbnail[0].name,
                displayName: form.thumbnail[0].name,
                fileType: 2,
                size: form.thumbnail[0].size,
                mimetype: form.thumbnail[0].type,
                format: FileUtil.getFileFormat(form.thumbnail[0].name)
            }
        }
        if (form.thumbnailId > 0) {
            return getFieldValue("thumbnail");
        }
        if (form.thumbnailId == -1) {
            return {
                id: getFieldValue("thumbnail.id")
            }
        }
        return null;
    }

    const afterSave = () => {
        props.setSavingStatus(2);
        document.location.href = isAdd ? '/admin/blogs' : document.location.href;
    }

    const onSaveError = (data) => {
        props.setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        const {blog} = props;
        return JsonUtil.getFieldValue(blog, name);
    }

    return (
        <>
            <RightContent headerText={(blogId ? "Edit" : "Add") + " Blog"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Blog Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Link
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="link" type="text" defaultValue={getFieldValue("link")}
                                                  className={getInvalidFieldClass('link')}
                                                  aria-label="Link"
                                                  {...register('link', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.link}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Title
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="title" type="text" defaultValue={getFieldValue("title")}
                                                  aria-label="Title"
                                                  className={getInvalidFieldClass('title')}
                                                  {...register('title', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.title}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Content
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <FormCKEditor id={'content'}
                                                  name={'content'}
                                                  title={'Content'}
                                                  defaultHtml={getFieldValue("content")}
                                                  onChange={(value) => setValue('content', value)}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="thumbnail" className="mb-3">
                                <Form.Label column sm="3">
                                    Thumbnail
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"thumbnail"}
                                                value={getFieldValue("thumbnailId")}
                                                register={register}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    <div className="form-group action-btn-wrapper">
                        <Button type="submit">Save</Button>
                        <Button href={"/admin/blogs"} variant="dark">Cancel</Button>
                    </div>
                </form>
            </RightContent>
        </>
    )
}

export default Blog;