import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import * as Session from "../../app/util/session";
import VerifyPhone from "./VerifyPhone";
import * as Constants from "../../common/constants";

const FloorPlan = ({show, onHide, floorPlanId, organization}) => {

    const [refresh, setRefresh] = useState(false);
    const onVerificationSuccess = () => {
        setRefresh(!refresh);
    }

    const onVerificationError = () => {

    }

    return (
        Session.isPhoneVerified() ? <Modal show={show}
                                           size="lg"
                                           onHide={onHide}
                                           dialogClassName="floor-plan-modal">
            <div className="container">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Floor Plan
                    </Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <img src={Constants.serverUrl + "file/get/" + floorPlanId}/>
            </Modal.Body>
        </Modal> : <VerifyPhone onVerificationSuccess={onVerificationSuccess.bind(this)}
                                type={4}
                                show={show} organization={organization} onHide={onHide}
                                onVerificationError={onVerificationError.bind(this)}/>)
}
export default FloorPlan;