import React from 'react'
import {Form, Button} from 'react-bootstrap';
import * as Constants from "../../common/constants";

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onChange(e) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value != this.props.value) {
            this.setState({value: this.props.value})
        }
    }

    getAcceptableFileTypes() {
        if (this.props.fileTypes) {
            return this.props.fileTypes;
        }
        return ".jpg,.jpeg,.png";
    }

    onRemoveImage() {
        this.setState({value: null});
        if (this.props.onRemoveClick) {
            this.props.onRemoveClick();
        }
    }

    render() {
        const {id, name, isDisabled, fileTypes} = this.props;
        const {value} = this.state;

        return (
            <>
                <Form.Control
                    type="file"
                    accept={this.getAcceptableFileTypes()}
                    disabled={isDisabled}
                    name={name || id}
                    onChange={(e) => {
                        this.onChange(e)
                    }} {...this.props.register(name || id)}/>
                    
                {value ? <ul className={"ul-img-preview"}>
                    <li>
                        <span><img src={fileTypes && fileTypes.indexOf(".pdf") > -1 ? "/images/pdf.png"
                            : Constants.serverUrl + "file/get/" + value} alt={""}/></span>
                    </li>
                </ul> : null}

                <Form.Control type="hidden" {...this.props.register((name || id) + "Id")}
                              value={value ? +value : 0}
                              name={(name || id) + "Id"}/>
            </>
        );
    }
}