import React, {Component} from "react";
import * as Action from "../../common/api-action";

export default class LeftNavBar extends Component {
    removeFiles() {
        Action.callPostApi("file/delete-unused", {});
    }

    render() {
        const {links} = this.props;
        return (
            <aside className="side-nav-bar">
                <ul>
                    {
                        links.map((link, index) => {
                            return (
                                <li key={index} className={link.liClass ? link.liClass : ''}>
                                    <a href={link.href} onClick={link.onClick} className={link.className}>
                                        <span className="material-icons-outlined">{link.icon}</span> {link.text}
                                    </a>
                                </li>
                            );
                        })
                    }
                    {/*<li><a onClick={this.removeFiles}>Remove Unused Files</a></li>*/}
                </ul>
            </aside>
        );
    }
}