import React, {Component} from "react";
import {Map, GoogleApiWrapper, Marker, InfoWindow,} from 'google-maps-react';
export class MapComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            marker: props.marker,
            activeMarker: null,
            showInfo: false
        }
    }

    getMarkerPosition() {
        return {
            lat: this.state.marker.latitude,
            lng: this.state.marker.longitude
        }
    }

    displayMarker = () => {
        if (!this.state.marker) {
            return;
        }
        return <Marker id="propertyMarker" position={this.getMarkerPosition()}
                       //icon={{url:"https://www.iconpacks.net/icons/1/free-building-icon-1062-thumb.png"}}
                       draggable={!this.props.disabled} name={this.state.marker.name} onDragend={this.moveMarker.bind(this)}
                       onClick={this.onMarkerClick}/>
    }

    onMarkerClick = (props, marker, e) => {
        if (this.props.canShowInfo) {
            this.setState({
                selectedPlace: props,
                activeMarker: marker,
                showInfo: true
            });
        }
    }

    moveMarker = (a, b, c) => {
        this.changeMarkerPosition(c);
    }

    changeMarkerPosition(c) {
        const {onMarkerChange} = this.props;
        if (onMarkerChange) {
            const coords = {longitude: c.latLng.lng(), latitude: c.latLng.lat()};
            this.setState({marker: coords})
            onMarkerChange(coords);
        }
    }

    getInitialCenter = () => {
        return {
            lat: this.state.marker.latitude,
            lng: this.state.marker.longitude
        };
    }

    onClick(a, b, c) {
        this.changeMarkerPosition(c);
    }

    onInfoWindowClose() {
        this.setState({
            activeMarker: null,
            showInfo: false
        });
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={this.props.mapStyle ? this.props.mapStyle : {}}
                onClick={this.onClick.bind(this)}
                initialCenter={this.getInitialCenter()}>
                {this.displayMarker()}
                {this.props.canShowInfo && this.state.showInfo && <InfoWindow marker={this.state.activeMarker}
                    onClose={this.onInfoWindowClose} visible={this.state.showInfo}>
                    <div>
                        <h4>{this.state.selectedPlace.name}</h4>
                    </div>
                </InfoWindow>}
            </Map>
        );
    }
}

MapComponent = GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API,
})(MapComponent);