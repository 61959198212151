import React, {Component} from "react";
import * as Session from "../../app/util/session";
import {Dropdown} from 'react-bootstrap';
import * as Constants from "../../common/constants";
import {Helmet} from "react-helmet";
import $ from 'jquery';

export default class Header extends Component {
    onLogout() {
        Session.logoutUser();
        document.location.href = '/admin';
    }

    render() {
        const {logoFileId, favIconId} = this.props.organization;
        return (
            <>
                <Helmet>
                    <link rel="icon" type="image/png" href={Constants.serverUrl + "file/get/" + favIconId}/>
                </Helmet>
                <header className="admin-header">
                    <div className="container-fluid">
                        <a href="/" className="admin-logo">
                            <img src={Constants.serverUrl + "file/get/" + logoFileId} alt="logo"/>
                        </a>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <span className="material-icons-sharp">person</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/*<Dropdown.Item href="#/action-1">My Account</Dropdown.Item>*/}
                                <Dropdown.Item onClick={this.onLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </header>
            </>
        );
    }
}