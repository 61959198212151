import React from 'react';
import '../styles/banner.scss'
import SearchCompInner from './SearchCompInner';
import * as Constants from "../../common/constants";
import {isEmpty} from "../../common/util/string-util";
import * as Session from "../util/session";
import { Button } from 'react-bootstrap';
import $ from 'jquery';

const HomeBanner = ({organization, cities, apartmentTypes, localities}) => {
  function shwoModalSearch(){
    $('.search-comp-wrapper').toggleClass('active');
  }

  return (
    <div className="banner-wrapper" style={{ backgroundImage: `url(${Constants.serverUrl + "file/get/" + organization.bannerImageId})`}}>
        <div className="container banner-content">
            {organization.bannerText && !isEmpty(organization.bannerText) &&
            <h3><div dangerouslySetInnerHTML={{ __html: organization.bannerText }} /></h3>}
            <Button className="is-rounded btn-search" id="searchMobile" onClick={shwoModalSearch}>
                <i className="material-icons">search</i> Search
            </Button>
            <SearchCompInner cities={cities} apartmentTypes={apartmentTypes}
                             localities={localities} selectedCity={Session.getCurrentCity()}/>
        </div>
    </div>
  );
}

export default HomeBanner;
