export const getFieldValue = (json, name) => {
    const field = name.split(".");
    if (field.length === 1 && json) {
        return json[name];
    } else if (field.length === 2 && json && json[field[0]]) {
        return json[field[0]][field[1]];
    } else if (field.length === 3 && json && json[field[0]] && json[field[0]][field[1]]) {
        return json[field[0]][field[1]][field[2]];
    }
    return json ? json[name] : "";
}

export const getIntFieldValue = (json, name) => {
    return getFieldValue(json, name) === '' ? 0 : getFieldValue(json, name);
}