import React, {useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import * as JsonUtil from "../../common/util/json-util";
import InputError from "../components/InputError";
import * as Action from "../../common/api-action";
import Button from "react-bootstrap/Button";
import RightContent from "../components/RightContent";
import StateDropdown from "../../common/component/StateDropdown";
import {MapComponent} from "../../common/component/MapComponent";

const District = ({district, setSavingStatus}) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const onSave = (form) => {
        setSavingStatus(1);
        const data = getData(form);
        if (isAdd()) {
            Action.callPostApi("districts/add", data, afterSave, onSaveError);
        } else {
            Action.callPostApi("districts/update", data, afterSave, onSaveError);
        }
    }

    const getData = (form) => {
        return {
            "id": isAdd() ? null : +district.id,
            "name": form.name,
            "code": form.code,
            "stateId": form.stateId.value,
            "popularity": form.popularity,
            "latitude": form.latitude,
            "longitude": form.longitude
        };
    }

    const afterSave = () => {
        setSavingStatus(2);
        if (isAdd()) {
            document.location.href = '/admin/districts';
        }
    }

    const onSaveError = (data) => {
        setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        return JsonUtil.getFieldValue(district, name);
    }

    const isAdd = () => {
        return !district || !district.id || district.id == 0;
    }

    const getMarker = () => {
        return {
            latitude: getFieldValue("latitude"),
            longitude: getFieldValue("longitude"),
            name: getFieldValue("name") ? getFieldValue("name") : ""
        }
    }

    const onMarkerChange = ({latitude, longitude}) => {
        setValue('latitude', latitude);
        setValue('longitude', longitude);
        document.getElementById('latitude').value = latitude;
        document.getElementById('longitude').value = longitude;
    }

    const [selectedState, setSelectedState] = useState(getFieldValue("state.id") != ""
        ? {value: getFieldValue("state.id")} : null);

    const onChangeSelectedState = (selectedStateOption) => {
        if (selectedStateOption != selectedState) {
            setSelectedState(selectedStateOption);
            setValue("stateId", selectedStateOption);
        }
    }
    return (
        <>
            <RightContent headerText={(!isAdd() ? "Edit" : "Add") + " District"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                District Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="name" type="text" defaultValue={getFieldValue("name")}
                                                  className={getInvalidFieldClass('name')}
                                                  aria-label="Name"
                                                  {...register('name', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.name}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Code
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="code" type="text" defaultValue={getFieldValue("code")}
                                                  className={getInvalidFieldClass('code')}
                                                  aria-label="Code"
                                                  {...register('code', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.code}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Popularity
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="popularity" type="text" defaultValue={getFieldValue("popularity")}
                                                  className={getInvalidFieldClass('popularity')}
                                                  aria-label="Popularity"
                                                  {...register('popularity', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.popularity}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="stateId" className="mb-3">
                                <Form.Label column sm="3">
                                    State
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <StateDropdown className={getInvalidFieldClass('stateId')}
                                                   selectedState={selectedState}
                                                   selectedCountry={{value: -1}}
                                                   control={control}
                                                   setSelectedState={onChangeSelectedState}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Latitude
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="latitude" type="text" defaultValue={getFieldValue("latitude")}
                                                  className={getInvalidFieldClass('latitude')}
                                                  aria-label="Latitude"
                                                  {...register('latitude', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.latitude}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Longitude
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="longitude" type="text" defaultValue={getFieldValue("longitude")}
                                                  className={getInvalidFieldClass('longitude')}
                                                  aria-label="Longitude"
                                                  {...register('longitude', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.longitude}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Map Location
                                </Form.Label>
                                <Col sm="9" className="form-types has-mapinit">
                                    <MapComponent marker={getMarker()}
                                                  onMarkerChange={onMarkerChange.bind(this)}/>
                                </Col>
                            </Form.Group>

                            <div className="form-group action-btn-wrapper">
                                <Button type="submit">Save</Button>
                                <Button href={"/admin/districts"} variant="dark">Cancel</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </RightContent>
        </>
    )
}

export default District;