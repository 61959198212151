import React, {Component} from 'react'
import ReactSelect from "../../common/component/ReactSelect";

export default class LocationDropdown extends Component {
    getLocationOptions() {
        const {cities} = this.props;
        return cities && cities.map((city) => {
            return {
                value: city.id,
                label: city.name
            }
        });
    }

    render() {
        return (
            <div className="search-col">
                <i className="material-icons-two-tone">location_on</i>
                <ReactSelect id="districtSelect"
                             title="Location"
                             className='select-location'
                             onValueChange={this.props.onUpdateSelectedCities}
                             isMultiple={true}
                             value={this.props.selectedCities}
                             options={this.getLocationOptions()}/>
            </div>
        )
    }
}