import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "./FormReactSelect";

export default class DistrictDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: null,
            title: 'Loading districts...'
        }
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedState !== prevProps.selectedState) {
            this.setState({title: 'Loading states...'});
            this.loadOptions();
        }
    }

    loadOptions() {
        if (this.props.selectedState) {
            Action.callPostApi("districts/get/" + this.props.selectedState.value, {}, this.populateDistrictsData.bind(this));
        } else {
            this.setState({title: 'Please select a state', districts: null});
            this.props.setSelectedDistrict(null);
        }
    }

    populateDistrictsData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedDistrict(null);
        } else {
            this.setState({title: 'District',
                districts: this.getDistrictOptions(response.districts)});
            this.props.setSelectedDistrict(this.getSelectedDistrictsOption(response.districts));
        }
    }

    getDistrictOptions(districts) {
        return districts && districts.map((district) => {
            return {
                value: district.id,
                label: district.name
            }
        })
    }

    getSelectedDistrictsOption(districts) {
        for (let i = 0; districts && i < districts.length; i++) {
            if (this.props.selectedDistrict && districts[i].id === this.props.selectedDistrict.value) {
                return {
                    value: districts[i].id,
                    label: districts[i].name
                }
            }
        }
        return null;
    }

    render() {
        const {setSelectedDistrict, className, control} = this.props;
        return (
            <FormReactSelect id="districtId"
                             name="districtId"
                             title={this.state.title}
                             className={className}
                             valueJSON={this.props.selectedDistrict}
                             onChange={setSelectedDistrict}
                             control={control}
                             options={this.state.districts ? this.state.districts : []}/>
        )
    }
}