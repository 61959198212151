import React from 'react'

const SectionTitle = (props) => {
  return (
    <h3 className="main-title">
        {props.text}
        <span> {props.span}</span>
    </h3>
  )
}

export default SectionTitle