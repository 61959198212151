import React, {Component} from "react";
import {Button} from 'react-bootstrap';

export default class RightContentFooter extends Component {
    render() {
        const {companyName} = this.props;

        return (
            <footer className="admin-footer text-center">
                Copyright © 2022 <a href="/" target={"_blank"}
                                    className="fs-14 text-primary">{companyName}</a>. All rights reserved.
            </footer>
        );
    }
}