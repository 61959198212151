import React from "react";
import {CKEditor} from 'ckeditor4-react';

const FormCKEditor = ({id, title, defaultHtml, name, onChange}) => {

    return (
        <CKEditor
            id={id || name}
            name={name || id}
            config={{extraPlugins: ['colorbutton', 'font']}}
            initData={defaultHtml}
            onReady={ editor => {
                console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event ) => {
                const data = event.editor.getData();
                onChange(data);
                console.log( { event, data } );
            } }
            onBlur={ ( event, editor ) => {
                console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                console.log( 'Focus.', editor );
            } }
        />
    )
}
export default FormCKEditor;