import React, {useState} from "react";
import * as Session from "../../app/util/session";
import VerifyPhone from "./VerifyPhone";
import {Modal} from "react-bootstrap";

const RequestFinalPrice = ({show, onHide, organization}) => {

    const [refresh, setRefresh] = useState(false);
    const onVerificationSuccess = () => {
        setRefresh(!refresh);
    }

    const onVerificationError = () => {

    }

    return (
        Session.isPhoneVerified() ? <Modal show={show}
                                           size="lg"
                                           onHide={onHide}
                                           dialogClassName="floor-plan-modal">
            <div className="container">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Request Final Price
                    </Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <div className="verify-success form-smsg">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10"
                                cx="65.1" cy="65.1" r="62.1"/>
                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round"
                                  strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                    <p>Thanks for showing interest in the property, Our team will contact you soon!</p>
                </div>
            </Modal.Body>
        </Modal> : <VerifyPhone onVerificationSuccess={onVerificationSuccess.bind(this)}
                                show={show} onHide={onHide} organization={organization} type={5}
                                onVerificationError={onVerificationError.bind(this)}/>)
}
export default RequestFinalPrice;