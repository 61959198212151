import React from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import RightContent from "../components/RightContent";
import * as Action from "../../common/api-action";
import {useForm} from 'react-hook-form';
import InputError from "../components/InputError";
import Button from "react-bootstrap/Button";
import * as StringUtil from "../../common/util/string-util";
import FileUpload from "../components/FileUpload";
import * as JsonUtil from "../../common/util/json-util";
import * as FileUtil from "../../common/util/file-util";

const User = (props) => {
    const {register, handleSubmit, formState: {errors}, setError, setValue, control} = useForm();

    const managerId = StringUtil.getLastPathParam("/edit/");

    const isAdd = !managerId || isNaN(managerId);

    const onSave = (form) => {
        props.setSavingStatus(1);
        const data = getData(form);
        const managerIdParam = isAdd ? null : +managerId;
        if (!managerIdParam) {
            Action.callPostApi("user/add-manager", data, afterSave, onSaveError, null, true);
        } else {
            Action.callPostApi("user/update-manager", data, afterSave, onSaveError, null, true);
        }
    }

    const getData = (form) => {
        const formData = new FormData();
        formData.append("photo", form.photo[0]);
        const managerData = {
            "id": isAdd ? null : +managerId,
            "firstName": form.firstName,
            "lastName": form.lastName,
            "middleName": form.middleName,
            //"address": getAddress(form),
            "email": form.email,
            "password": form.password,
            "phone": form.phone,
            "photo": getPhotoDetails(form)
        };
        formData.append("data", JSON.stringify(managerData));
        return formData;
    }

    // const getAddress = (form) => {
    //     return {
    //         id: getFieldValue("address.id"),
    //         address: form.address,
    //         localityId: form.localityId.value,
    //         districtId: form.districtId.value,
    //         stateId: form.stateId.value,
    //         countryId: form.countryId.value,
    //         latitude: form.latitude,
    //         longitude: form.longitude,
    //         pincode: form.pincode
    //     }
    // }

    const getPhotoDetails = (form) => {
        if (form.photo && form.photo.length > 0) {
            return {
                id: form.photoId,
                fileName: form.photo[0].name,
                displayName: form.photo[0].name,
                fileType: 1,
                size: form.photo[0].size,
                mimetype: form.photo[0].type,
                format: FileUtil.getFileFormat(form.photo[0].name)
            }
        }
        if (form.photoId > 0) {
            return getFieldValue("photo");
        }
        if (form.photoId == -1) {
            return {
                id: getFieldValue("photo.id")
            }
        }
        return null;
    }

    const afterSave = () => {
        props.setSavingStatus(2);
        document.location.href = isAdd ? '/admin/users' : document.location.href;
    }

    const onSaveError = (data) => {
        props.setSavingStatus(3);
        setError(data.field, {type: 'custom', message: data.message});
    }

    const getInvalidFieldClass = (field) => {
        return errors[field] ? ' is-invalid' : '';
    }

    const getFieldValue = (name) => {
        const {manager} = props;
        return JsonUtil.getFieldValue(manager, name);
    }

    // const [selectedCountry, setSelectedCountry] = useState(getFieldValue("address.country.id") != ""
    //     ? {value: getFieldValue("address.country.id")} : null);
    // const [selectedState, setSelectedState] = useState(getFieldValue("address.state.id") != ""
    //     ? {value: getFieldValue("address.state.id")} : null);
    // const [selectedDistrict, setSelectedDistrict] = useState(getFieldValue("address.district.id") != ""
    //     ? {value: getFieldValue("address.district.id")} : null);
    // const [selectedLocality, setSelectedLocality] = useState(getFieldValue("address.locality.id") != ""
    //     ? {value: getFieldValue("address.locality.id")} : null);
    //
    // const onChangeSelectedCountry = (selectedCountryOption) => {
    //     if (selectedCountryOption != selectedCountry) {
    //         setSelectedCountry(selectedCountryOption);
    //         setValue("countryId", selectedCountryOption);
    //     }
    // }
    //
    // const onChangeSelectedState = (selectedStateOption) => {
    //     if (selectedStateOption != selectedState) {
    //         setSelectedState(selectedStateOption);
    //         setValue("stateId", selectedStateOption);
    //     }
    // }
    //
    // const onChangeSelectedDistrict = (selectedDistrictOption) => {
    //     if (selectedDistrictOption != selectedDistrict) {
    //         setSelectedDistrict(selectedDistrictOption);
    //         setValue("districtId", selectedDistrictOption);
    //     }
    // }
    //
    // const onChangeSelectedLocality = (selectedLocalityOption) => {
    //     if (selectedLocalityOption != selectedLocality) {
    //         setSelectedLocality(selectedLocalityOption);
    //         setValue("localityId", selectedLocalityOption);
    //     }
    // }

    return (
        <>
            <RightContent headerText={(managerId ? "Edit" : "Add") + " Manager"}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Manager Details
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    First Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="firstName" type="text" defaultValue={getFieldValue("firstName")}
                                                  className={getInvalidFieldClass('firstName')}
                                                  aria-label="First Name"
                                                  {...register('firstName', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.firstName}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Middle Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="middleName" type="text" defaultValue={getFieldValue("middleName")}
                                                  aria-label="Middle Name"
                                                  {...register('middleName')}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Last Name
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="lastName" type="text" defaultValue={getFieldValue("lastName")}
                                                  className={getInvalidFieldClass('lastName')}
                                                  aria-label="Last Name"
                                                  {...register('lastName', {required: 'cannot be empty'})}/>
                                    <InputError error={errors.lastName}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Email
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="email" type="text"
                                                  {...register('email', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('email')}
                                                  aria-label="Email Address"
                                                  defaultValue={getFieldValue("email")}/>
                                    <InputError error={errors.email}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Password
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control name="password" type="password"
                                                  autoComplete={"current-password"}
                                                  defaultValue={(!isAdd ? "**********" : "")}
                                                  {...register('password', {required: 'Cannot be empty'})}
                                                  className={getInvalidFieldClass('password')}
                                                  aria-label="Password"/>
                                    <InputError error={errors.password}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="3">
                                    Contact Number
                                </Form.Label>
                                <Col sm="9" className="form-types">
                                    <Form.Control type="phone" defaultValue={getFieldValue("phone")}
                                                  className={getInvalidFieldClass('phone')}
                                                  aria-label="Contact Number"
                                                  {...register('phone', {required: 'Cannot be empty'})}/>
                                    <InputError error={errors.phone}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="photo" className="mb-3">
                                <Form.Label column sm="3">
                                    Photo
                                </Form.Label>
                                <Col sm="9" className="form-types has-file-upload">
                                    <FileUpload id={"photo"}
                                                value={getFieldValue("photoId")}
                                                register={register}/>
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>

                    {/*<Card>*/}
                        {/*<Card.Header>*/}
                            {/*<Card.Title>*/}
                                {/*Manager Address*/}
                            {/*</Card.Title>*/}
                        {/*</Card.Header>*/}
                        {/*<Card.Body>*/}
                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*Address*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<Form.Control name="address" type="text"*/}
                                                  {/*className={getInvalidFieldClass('address')}*/}
                                                  {/*aria-label="Address" defaultValue={getFieldValue("address.address")}*/}
                                                  {/*{...register('address', {required: 'Address cannot be empty'})}/>*/}
                                    {/*<InputError error={errors.address}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*Country*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<CountryDropdown className={getInvalidFieldClass('countryId')}*/}
                                                     {/*selectedCountry={selectedCountry}*/}
                                                     {/*control={control}*/}
                                                     {/*setSelectedCountry={onChangeSelectedCountry}/>*/}
                                    {/*<InputError error={errors.countryId}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*State*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<StateDropdown className={getInvalidFieldClass('stateId')}*/}
                                                   {/*selectedState={selectedState}*/}
                                                   {/*selectedCountry={selectedCountry}*/}
                                                   {/*control={control}*/}
                                                   {/*setSelectedState={onChangeSelectedState}/>*/}
                                    {/*<InputError error={errors.stateId}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*City*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<DistrictDropdown className={getInvalidFieldClass('districtId')}*/}
                                                      {/*selectedDistrict={selectedDistrict}*/}
                                                      {/*selectedState={selectedState}*/}
                                                      {/*control={control}*/}
                                                      {/*setSelectedDistrict={onChangeSelectedDistrict}/>*/}
                                    {/*<InputError error={errors.districtId}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*Locality*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<LocalityDropdown className={getInvalidFieldClass('localityId')}*/}
                                                      {/*selectedLocality={selectedLocality}*/}
                                                      {/*selectedDistrict={selectedDistrict}*/}
                                                      {/*control={control}*/}
                                                      {/*setSelectedLocality={onChangeSelectedLocality}/>*/}
                                    {/*<InputError error={errors.localityId}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group as={Row} className="mb-3">*/}
                                {/*<Form.Label column sm="3">*/}
                                    {/*Pincode*/}
                                {/*</Form.Label>*/}
                                {/*<Col sm="9" className="form-types">*/}
                                    {/*<Form.Control name="pincode" type="text"*/}
                                                  {/*defaultValue={getFieldValue("address.pincode")}*/}
                                                  {/*className={getInvalidFieldClass('pincode')}*/}
                                                  {/*aria-label="Pincode"*/}
                                                  {/*{...register('pincode', {required: 'Pincode cannot be empty'})}/>*/}
                                    {/*<InputError error={errors.pincode}/>*/}
                                {/*</Col>*/}
                            {/*</Form.Group>*/}
                        {/*</Card.Body>*/}
                    {/*</Card>*/}
                    <div className="form-group action-btn-wrapper">
                        <Button type="submit">Save</Button>
                        <Button href={"/admin/users"} variant="dark">Cancel</Button>
                    </div>
                </form>
            </RightContent>
        </>
    )
}

export default User;