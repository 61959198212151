import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "../../common/component/FormReactSelect";

export default class CountryDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: null,
            title: 'Loading countries...'
        }
    }

    componentDidMount() {
        Action.callPostApi("countries", {}, this.populateCountriesData.bind(this));
    }

    populateCountriesData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedCountry(null);
        } else {
            this.setState({title: 'Country',
                countries: this.getCountriesOptions(response.countries)});
            this.props.setSelectedCountry(this.getSelectedCountriesOption(response.countries));
        }
    }

    getCountriesOptions(countries) {
        return countries && countries.map((country) => {
            return {
                value: country.id,
                label: country.name
            }
        })
    }

    getSelectedCountriesOption(countries) {
        for (let i = 0; countries && i < countries.length; i++) {
            if (this.props.selectedCountry && countries[i].id === this.props.selectedCountry.value) {
                return {
                    value: countries[i].id,
                    label: countries[i].name
                }
            }
        }
        return null;
    }

    render() {
        const {setSelectedCountry, className, control} = this.props;
        return (
            <FormReactSelect id="countryId"
                             name="countryId"
                             title={this.state.title}
                             className={className}
                             valueJSON={this.props.selectedCountry}
                             onChange={setSelectedCountry}
                             control={control}
                             options={this.state.countries ? this.state.countries : []}/>
        )
    }
}