import React from 'react';
import {Alert} from 'react-bootstrap';

const MessageAlerts = (props) => {
  return (
    <Alert className={props.position} variant={props.type}
           dismissible={props.close ? "dismissible" : ""} onClose={props.onClose} >
      <Alert.Heading>
        {props.message}
      </Alert.Heading>
      
      {props.children}
    </Alert>
  )
}

export default MessageAlerts

// Alert Types are as follow
// primary
// secondary
// success
// danger
// warning
// info
// light
// dark