import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {initializeApp} from "firebase/app";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import $ from 'jquery';
import * as Action from "../../common/api-action";
import * as Session from "../../app/util/session";
import InputError from '../../admin/components/InputError';
import AppLoader from "../../common/component/AppLoader";
import * as StringUtil from "../../common/util/string-util";

const firebaseConfig = {
    apiKey: "AIzaSyArteBJZsvK2QkHfUV_R9nQTkCWh0HlC7Y",
    authDomain: "bombay-broking-6c953.firebaseapp.com",
    projectId: "bombay-broking-6c953",
    storageBucket: "bombay-broking-6c953.appspot.com",
    messagingSenderId: "190730582012",
    appId: "1:190730582012:web:794ef5dbff2d8016cb2479",
    measurementId: "G-N6SF8LVG8G"
};
const VerifyPhone = ({onVerificationSuccess, onVerificationError, onHide, show, organization, type}) => {
    const [user, setUser] = useState([]);
    const [viewOtpForm, setViewOtpForm] = useState(false);
    const [error, setError] = useState({});

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const showSpinner = (value) => {
        if (value) {
            $('.otp-button').addClass('has-loader');
        } else {
            $('.otp-button').removeClass('has-loader');
        }
    }

    auth.onAuthStateChanged((updatedUser) => {
        if (updatedUser && updatedUser != user) {
            setUser(updatedUser);
        }
    });

    const onSuccess = (response) => {
        Session.setVerifiedPhone(response.phoneNumber, response.accessToken);
        if (onVerificationSuccess) {
            onVerificationSuccess();
            Action.callPostApi("enquiry/save", getData(), afterSave.bind(this));
            if (organization.additionalScripts && $('body').html().indexOf(organization.additionalScripts) < 0
                && organization.additionalScripts.length > 0) {
                $('body').append(organization.additionalScripts);
            }
        }
    }

    const afterSave = () => {
        Session.setContactFormSubmissionStatus(true);
        showSpinner(false);
        if (organization && organization.additionalScripts
            && $('body').html().indexOf(organization.additionalScripts) < 0
            && organization.additionalScripts.length > 0) {
            $('body').append(organization.additionalScripts);
        }
    }

    const onError = (message) => {
        setError({name: "otp", message: message});
    }

    React.useEffect(() => {
        window.recaptchaVerifier = window.recaptchaVerifier ? window.recaptchaVerifier :
            new RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': (response) => {
                }
            }, auth);
    }, []);

    const isValidForm = () => {
        let isValid = true;
        if (StringUtil.isEmpty($('#name').val())) {
            isValid = false;
            setError({name: "name", message: "Please enter your name"})
        }
        return isValid;
    }

    const onGetOtp = (e) => {
        e.preventDefault();
        if (!isValidForm()) {
            return;
        }
        showSpinner(true);
        let phone_number = '+91' + $('#phone').val();
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phone_number, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setViewOtpForm(true);
                window.confirmationResult = confirmationResult;
                showSpinner(false);
                // ...
            })
            .catch((error) => {
                // Error; SMS not sent
                // ...
                onError(error.message);
                showSpinner(false);
            });

    };

    const otpSubmit = (e) => {
        showSpinner(true);
        e.preventDefault();
        if (!isValidForm()) {
            return;
        }
        let opt_number = $('#otp').val();

        window.confirmationResult
            .confirm(opt_number)
            .then((confirmationResult) => {
                Action.callGetApi("auth/phone/verify",
                    onSuccess, onVerificationError, confirmationResult.user.accessToken);
            })
            .catch((error) => {
                onError(error.message);
                showSpinner(false);
            });
    };

    const getData = () => {
        return {
            name: $('#name').val(),
            type: type,
            phone: $('#phone').val(),
            orgId: organization.id,
            propertyName: getTextOfElement('.project-details .card-header .card-title'),
            apartmentType: getTextOfElement('#apartmentTypeSelect div > div > div'),
            apartmentName: getTextOfElement('#apartmentSizeSelect div > div > div'),
            propertyId: $('.prop-id') ? +$('.prop-id').text() : 0,
            apartmentId: $('.apart-id') ? +$('.apart-id').text() : 0
        };
    }

    const getTextOfElement = (className) => {
        return $(className) ? $(className).text() : "";
    }

    const removeError = () => {
        if (error.name) {
            setError({});
        }
    }

    return (
        <Modal show={show}
               onHide={onHide}
               dialogClassName="phone-verification--modal">
            <div className="container">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Phone Verification
                    </Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <Form.Control
                        className={error.name === 'name' ? ' is-invalid': ''}
                        placeholder="Name"
                        aria-label="Name"
                        onChange={()=> {removeError()}}
                        aria-describedby="name"
                        type="text"
                        id={"name"}/>
                    <InputError error={error.name === 'name' ? error : null}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                    <Form.Control className={error.name === 'otp' ? ' is-invalid': ''}
                                  placeholder="Phone Number"
                                  aria-label="Phone Number"
                                  aria-describedby="phone-number"
                                  type="tel"
                                  onChange={()=> {removeError()}}
                                  id={"phone"}
                                  minLength="10"
                                  maxLength="10"/>


                    <InputError error={error.name === 'otp' ? error : null}/>
                </InputGroup>

                {viewOtpForm && <InputGroup className="mb-3">
                     <Form.Control
                        placeholder="OTP"
                        aria-label="OTP"
                        className={error.name === 'otp' ? ' is-invalid': ''}
                        aria-describedby="otp"
                        type="number"
                        id={"otp"}
                        onChange={()=> {removeError()}}
                        minLength="6"
                        maxLength="6"/>
                </InputGroup>}

                {viewOtpForm && <Button variant="primary" onClick={otpSubmit}
                                        className={"otp-button"}>Submit<AppLoader position={"absolute"}/></Button>}

                {!viewOtpForm && <Button variant="primary" id={"otp-button"} className={"otp-button"}
                                         onClick={onGetOtp}>Get OTP<AppLoader position={"absolute"}/></Button>}


            </Modal.Body>
        </Modal>
    )
}
export default VerifyPhone;