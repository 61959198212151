import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "../../common/component/FormReactSelect";

export default class PropertyDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: null,
            title: 'Loading properties...'
        }
    }

    componentDidMount() {
        Action.callPostApi("property", {}, this.populatePropertiesData.bind(this));
    }

    populatePropertiesData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedProperty(null);
        } else {
            this.setState({title: 'Property',
                properties: this.getPropertiesOptions(response.properties)});
            this.props.setSelectedProperty(this.getSelectedPropertiesOption(response.properties));
        }
    }

    getPropertiesOptions(properties) {
        return properties && properties.map((property) => {
            return {
                value: property.id,
                label: property.name
            }
        })
    }

    getSelectedPropertiesOption(properties) {
        for (let i = 0; properties && i < properties.length; i++) {
            if (this.props.selectedProperty && properties[i].id === this.props.selectedProperty.value) {
                return {
                    value: properties[i].id,
                    label: properties[i].name
                }
            }
        }
        return null;
    }

    render() {
        const {setSelectedProperty, className, control, selectedProperty} = this.props;
        return (
            <FormReactSelect id="propertyId"
                             name="propertyId"
                             title={this.state.title}
                             className={className}
                             valueJSON={selectedProperty}
                             onChange={setSelectedProperty}
                             control={control}
                             options={this.state.properties ? this.state.properties : []}/>
        )
    }
}