import React from 'react'
import {Form, Button} from 'react-bootstrap';
import * as Constants from "../../common/constants";

export default class FileUploadMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values
        }
    }

    onChange(e) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.values != this.props.values) {
            this.setState({values: this.props.values})
        }
    }

    getAcceptableFileTypes() {
        return ".jpg,.jpeg,.png";
    }


    onRemoveImage(id) {
        if (window.confirm("Are you sure you want to delete this image?")) {
            if (this.props.onRemoveClick) {
                this.props.onRemoveClick(id);
            }
        }
    }

    render() {
        const {id, name, isDisabled} = this.props;
        const {values} = this.state;

        return (
            <>
                <Form.Control
                    type="file"
                    accept={this.getAcceptableFileTypes()}
                    disabled={isDisabled}
                    name={name || id}
                    multiple={true}
                    onChange={(e) => {
                        this.onChange(e)
                    }} {...this.props.register(name || id)}/>
                    
                {values ? <ul className={"ul-img-preview"}>
                    {
                        values.map(value => {
                            return (
                                <li key={value.id}>
                                    <span><img src={Constants.serverUrl + "file/get/" + value.id} alt={""}/></span>
                                    <Button onClick={this.onRemoveImage.bind(this, value.id)}>Remove</Button>
                                </li>
                            );
                        })
                    }
                </ul> : null}

                <Form.Control type="hidden" {...this.props.register((name || id) + "Id")}
                              value={values ? values : []}
                              name={(name || id) + "Id"}/>
            </>
        );
    }
}