import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "./FormReactSelect";

export default class DeveloperDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            developers: null,
            title: 'Loading Developers...'
        }
    }

    componentDidMount() {
        Action.callPostApi("developer", {}, this.populateDevelopersData.bind(this));
    }

    populateDevelopersData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedDeveloper(null);
        } else {
            this.setState({title: 'Developer', developers: this.getDeveloperOptions(response.developers)});
            this.props.setSelectedDeveloper(this.getSelectedDeveloperOption(response.developers));
        }
    }

    getSelectedDeveloperOption(developers) {
        for (let i = 0; developers && i < developers.length; i++) {
            if (this.props.selectedDeveloper && developers[i].id === this.props.selectedDeveloper.value) {
                return {
                    value: developers[i].id,
                    label: developers[i].name
                }
            }
        }
        return null;
    }

    getDeveloperOptions(developers) {
        return developers && developers.map((developer) => {
            return {
                value: developer.id,
                label: developer.name
            }
        })
    }

    render() {
        const {setSelectedDeveloper, className, control, defaultValue} = this.props;
        return (
            <FormReactSelect id="developerId"
                             name="developerId"
                             title={this.state.title}
                             className={className}
                             defaultValue={defaultValue}
                             valueJSON={this.props.selectedDeveloper}
                             onChange={setSelectedDeveloper}
                             control={control}
                             options={this.state.developers ? this.state.developers : []}/>
        )
    }
}