import React, {useState} from 'react';
import * as Action from "../../common/api-action";
import ContainerMain from "../components/ContainerMain";
import * as Constants from "../../common/constants";
import {getFormattedPrice} from "../util/currency-util";
import * as ArrayUtil from "../../common/util/array-util";
import * as JsonUtil from "../../common/util/json-util";
import * as StringUtil from "../../common/util/string-util";
import * as AreaUnit from "../../enums/area_unit";
import SectionTitle from "./SectionTitle";
import CardComp from "./CardComp";
import CardFooter from "./CardFooter";
import AppLoader from "../../common/component/AppLoader";


const SimilarProperties = (props) => {
    const [loading, setLoading] = useState(true);
    const [similarProperties, setSimilarProperties] = useState([]);

    React.useEffect(() => {
        Action.callPostApi("property/similar/" + props.propertyId + "/" + props.selectedApartmentType,
            {}, populateSimilarPropertiesData.bind(this));
    }, []);

    const populateSimilarPropertiesData = (response) => {
        if (response.type === 'success') {
            setSimilarProperties(response.similarProperties);
            setLoading(false);
        }
    }

    const getCardFooter = (location) => {
        return (
            <CardFooter>
                <i className="material-icons-two-tone">pin_drop</i>
                <span>{location}</span>
            </CardFooter>
        )
    }

    if (loading) {
        return <AppLoader position={"fixed"}/>
    }

    if (!similarProperties || similarProperties.length == 0) {
        return;
    }

    return (
        <ContainerMain className="simal-project-wrapper" bgColor={true}>
            <SectionTitle text="Similar" span="Projects"/>
            <div className="inner-wrapper">
                <div className="row">
                    {similarProperties.map((property, index) => {
                        return (
                            <CardComp
                                key={index}
                                id={property.id}
                                image={Constants.serverUrl + "file/get/" + property.bannerFileId}
                                price={!property.hidePrice
                                    ? getFormattedPrice(ArrayUtil.getMinimum(JsonUtil.getFieldValue(property, "apartments"), "price")) : "hidden"}
                                footer={getCardFooter(JsonUtil.getFieldValue(property, "address.locality.name")
                                    + ", " + JsonUtil.getFieldValue(property, "address.district.name"))}
                                hover={true}
                                slug={property.slug}
                                title={property.name}>
                                <span className="card-description">
                                    {StringUtil.getEllipseValue(JsonUtil.getFieldValue(property, "propertyDetail.description"), 90)}
                                </span>
                                <span className="card-items">
                                    <span>
                                        <i className="material-icons-two-tone">home_work</i>
                                        <b>
                                            {JsonUtil.getFieldValue(property, "propertyDetail.totalArea")}
                                        </b>
                                        &nbsp;{ArrayUtil.getLabel(AreaUnit.area_units,
                                        JsonUtil.getFieldValue(property, "propertyDetail.areaUnit"))}
                                    </span>
                                    {JsonUtil.getFieldValue(property, "apartments").length > 0 &&
                                    <span><b>
                                        {ArrayUtil.getCommaSeparatedValue(ArrayUtil.sort(JsonUtil.getFieldValue(property,
                                            "apartments"), "typeId"), "typeId", props.apartmentTypes)}
                                        {" " + Constants.APARTMENT_CONFIG_SUFFIX}
                                    </b></span>}
                                </span>
                            </CardComp>
                        )
                    })}
                </div>
            </div>
        </ContainerMain>
    )
}

export default SimilarProperties;
