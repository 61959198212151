import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "./FormReactSelect";

export default class AgentDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agents: null,
            title: 'Loading Agents...'
        }
    }

    componentDidMount() {
        Action.callPostApi("user/agents", {}, this.populateAgentsData.bind(this));
    }

    populateAgentsData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedAgent(null);
        } else {
            this.setState({title: 'Agent', agents: this.getAgentOptions(response.agents)});
            this.props.setSelectedAgent(this.getSelectedAgentOption(response.agents));
        }
    }

    getSelectedAgentOption(agents) {
        for (let i = 0; agents && i < agents.length; i++) {
            if (this.props.selectedAgent && agents[i].id === this.props.selectedAgent.value) {
                return {
                    value: agents[i].id,
                    label: agents[i].name
                }
            }
        }
        return null;
    }

    getAgentOptions(agents) {
        return agents && agents.map((agent) => {
            return {
                value: agent.id,
                label: this.getAgentName(agent)
            }
        })
    }

    getAgentName(agent) {
        return agent.firstName
            + (agent.middleName && agent.middleName.length ? " " + agent.middleName : "")
            + (agent.lastName && agent.lastName.length ? " " + agent.lastName : "");
    }

    render() {
        const {setSelectedAgent, className, control, defaultValue} = this.props;
        return (
            <FormReactSelect id="agentId"
                             name="agentId"
                             title={this.state.title}
                             className={className}
                             defaultValue={defaultValue}
                             valueJSON={this.props.selectedAgent}
                             onChange={setSelectedAgent}
                             control={control}
                             options={this.state.agents ? this.state.agents : []}/>
        )
    }
}