export const enquiry_statuses = [{
    value: 0,
    label: 'New'
},{
    value: 1,
    label: 'Open'
},{
    value: 2,
    label: 'Callback'
},{
    value: 3,
    label: 'Invalid'
},{
    value: 4,
    label: 'Closed'
}];