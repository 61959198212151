import React, {Component} from 'react'
import * as Action from "../api-action";
import FormReactSelect from "./FormReactSelect";

export default class StateDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: null,
            title: 'Loading states...'
        }
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedCountry !== prevProps.selectedCountry) {
            this.setState({title: 'Loading states...'});
            this.loadOptions();
        }
    }

    loadOptions() {
        if (this.props.selectedCountry) {
            Action.callPostApi("states/get/" + this.props.selectedCountry.value, {}, this.populateStatesData.bind(this));
        } else {
            this.setState({title: 'Please select a country', states: null});
            this.props.setSelectedState(null);
        }
    }

    populateStatesData(response) {
        if (response.type !== 'success') {
            this.setState({title: 'Failed to fetch data'});
            this.props.setSelectedState(null);
        } else {
            this.setState({title: 'State',
                states: this.getStateOptions(response.states)});
            this.props.setSelectedState(this.getSelectedStatesOption(response.states));
        }
    }

    getStateOptions(states) {
        return states && states.map((state) => {
            return {
                value: state.id,
                label: state.name
            }
        })
    }

    getSelectedStatesOption(states) {
        for (let i = 0; states && i < states.length; i++) {
            if (this.props.selectedState && states[i].id === this.props.selectedState.value) {
                return {
                    value: states[i].id,
                    label: states[i].name
                }
            }
        }
        return null;
    }

    render() {
        const {setSelectedState, className, control} = this.props;
        return (
            <FormReactSelect id="stateId"
                             name="stateId"
                             title={this.state.title}
                             className={className}
                             valueJSON={this.props.selectedState}
                             onChange={setSelectedState}
                             control={control}
                             options={this.state.states ? this.state.states : []}/>
        )
    }
}